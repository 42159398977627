import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import * as Highcharts from 'highcharts';
import {UIChart} from 'primeng/chart';
import {ConfirmationService, MenuItem, Message, MessageService, SelectItem} from 'primeng/api';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {DialogService} from 'primeng';
import {ActivatedRoute, Router} from '@angular/router';
import {PpapPackageIdentifier} from '../models/ppap-package-identifier';
import {EpswHomepageService} from '../services/epsw-homepage.service';
import {DigitalPpapPackageService} from '../services/digital-ppap-package.service';
import {PpapElementRecords} from '../models/ppap-element-records';
import {SubSupplierPpapPackage} from '../models/sub-supplier-ppap-package';
import {PpapRequiredElement} from '../models/ppap-required-element';
import {PpapPhaseSectionService} from '../services/ppap-phase-section.service';
import {PpapPhaseSection} from '../models/ppap-phase-section';
import {PpapPackageParts} from '../models/ppap-package-parts';
import {DatePipe} from '@angular/common';
import {LdapResponse} from '../models/ldap-response';
import {Tier2SubSupplier} from '../models/Tier2SubSupplier';
import {RoleBasedAccessProviderService} from '../psw-approval-flow/role-based-access-provider.service';
import {DropdownOption} from '../models/dropdown-option';
import {CopyDataList} from '../models/copy-data-list';
import * as _ from 'lodash';
import {EapqpAPIPriority} from '../models/eapqp-priority';
import {NgModel} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {saveAs} from 'file-saver';
import * as jszip from 'jszip';

interface Product {
	id: string;
	code: string;
	name: string;
	description: string;
	subSupliercategory: string;
	updatedbyname: string;
	updatedbydate: string;
	// quantity : number;
	// inventoryStatus : string;
	// category : string;
	// image : string;
	// rating : number;
}

interface ProductDetail {

	name: string;
	description: string;
}

interface SupplierDetail {
	subSupplier: string;
	partDescription: string;
}

interface Ppapdetail {
	ppapcategory: string;
	pgm: string;
	prefix: string;
	base: string;
	suffix: string;
	partdescription: string;
	changeintiming: string;
	rardt: string;
	rcheckout: string;
	qualdt: string;
	qcheckout: string;
	proddt: string;
	pcheckout: string;
	capdt: string;
	// status: string;
	// level: string;

}

@Component({
	selector: 'digital-ppap-package',
	templateUrl: './digital-ppap-package.component.html',
	styleUrls: ['./digital-ppap-package.component.scss'],
	providers: [DialogService]
})
export class DigitalPPAPPackageComponent implements OnInit {
	@ViewChild('container', {static: true}) container: ElementRef;
	@ViewChild('chart') chart: UIChart;
	styles =
		{
			pgm: '100px',
			prefixBaseSuffix: '200px',
			partdescription: '200px',
			rardt: '95px',
			qualdt: '95px',
			proddt: '95px',
			capdt: '95px',
			ppaplevel: '100px'
			// level:'90px'
			// ,
			// 'Last Name' : '10px',
			// 'Address' : '150px'
		};
	// table
	ppapTabDetail: Ppapdetail[];
	cols: any[];
	treeMap: any[];
	tier2: any;
	bailment: any;
	plugin = ChartDataLabels;
	approverCdsidsta: LdapResponse;
	enable: boolean;
	attachmentName: string;
	filteredCDSID: any[];
	approverCdsid: LdapResponse;
	pdApprovalRequiredOption: any;
	//dynamic curd table
	productDialog: boolean;
	products: any[] = [];
	subSupplierPpapPackage: SubSupplierPpapPackage;
	subSupplierPpapPackageData: SubSupplierPpapPackage;
	addSupplier: any = {};
	product: ProductDetail[];
	submitted: boolean;
	//end dynamic

	data: any;
	chartOptions: any;
	uploadedFiles: any[] = [];
	url: any;
	numberOfSupplier: number;
	//table
	supplierDetails: SupplierDetail[];
	supplierHeader: any[];
	//component change
	butIndex: number;
	datasets1: any[];
	datasets2: any[];
	datasets3: any[];
	datasets4: any[];
	//Critical SubSupiler
	subSupliercategoryOptions: SelectItem[];
	display: boolean;
	displayPartData: boolean;
	menuItems: MenuItem[];
	home: MenuItem;
	commodityImage: File;
	ppapPackageIdentifier: any;
	ppapPackageIdentifierDisplay: PpapPackageIdentifier;
	ppapPackageIdentifierNew: PpapPackageIdentifier;
	ppapPackageIdentifierStore: PpapPackageIdentifier;
	ppapPackageIdentifierDisplayOne: PpapPackageIdentifier;
	ppapPackageIdentifierDisplayOld: PpapPackageIdentifier;
	ppapPackageIdentifierDisplayNew: PpapPackageIdentifier;
	supplier: string;
	ppapElementSection: any[];
	ppapElementQuestion: any[];
	phaseType: any;
	displayPpapPhase: any;
	//PPAP Element Table
	ppapElementRecords: PpapElementRecords[] = [];
	statuses: SelectItem[];
	clonedProducts: { [s: string]: PpapElementRecords; } = {};
	question: SelectItem[];
	rejected: SelectItem[];
	proprietary: SelectItem[];
	newAttribute: any = {};
	ppapElementSelectedSectionName: string;
	ppapElementTotalRecords: PpapElementRecords[] = [];
	ppapElementSelectedIndex: number;
	editMode: boolean;
	private clonedData: { [s: number]: SubSupplierPpapPackage; } = {};
	loading: any;
	private oldSubSupplierCategory: any;
	private oldSubSupplierName: any;
	private oldPartDescription: any;
	private oldAttachment: any;
	ppapRequiredElements: PpapRequiredElement[] = [];
	sectionDataList: PpapPhaseSection[];
	ppapRequiredElementsResp: PpapRequiredElement[] = [];
	totalTableData: any;
	//ppap-parts
	displayDialog: boolean;
	displayNotCmmsPartDlg = false;
	addDataForParts: any = {};
	colsForParts: any[];
	ppapParts: PpapPackageParts[];
	dtOrgList: any[];
	ppapPartsArray: PpapPackageParts[];
	ppapPrStatusArray :any[] = [];
	ppapPartsArraybeforesave: PpapPackageParts[];
	saveForParts: boolean;
	ppapPackageIdentifierId: number;
	ppapLevelOptions: SelectItem[];
	disableDelete: boolean;
	addPartsCheck: boolean;
	clonedParts: { [partId: number]: PpapPackageParts; } = {};
	ppapLevelParts: any;
	private ppapElementRequiredStatus: any[] = [];
	msgs: Message[] = [];
	uploadCommodityImage = new FormData();
	selectedCmdyImage: File;
	private retrieveResonse: any;
	private base64Data: any;
	private retrievedImage: string;
	ppapElementSectionbutton: PpapRequiredElement[] = [];
	private ppapElementSavePDF: PpapElementRecords[] = [];
	//ldap
	ldapResponse: LdapResponse[];
	private fordBuyerSuggestions: LdapResponse[];
	private fordDisplayNameSuggestions: LdapResponse[];
	criticalSubSuppliers: Tier2SubSupplier[] = [];
	isTier2DetailsPresent = false;
	private editParts: boolean;
	criticalSubSuppliersSave: Tier2SubSupplier[] = [];
	apsAttributes: any;
	userRole: any;
	mode: any;
	enableOnViewMode: boolean;
	pswAccessControl: Map<string, any> = new Map<string, any>();
	cdsidNotSaved = false;
	cdsidSavedFlag=false;
	tier2Saved = false;
	tier2SavedFlag=false;
	commodityImgSaved = false;
	commodityImgSavedFlag = false;
	recallSubmission = false;
	requiredElementsNotSaved = false;
	requiredElementsSavedFlag=false;
	ppapPartsArrayLength: any;
	pswApproverAccessControl: Map<string, any> = new Map<string, any>();
	needApproverAction = false;
	enableRecallSection = false;
	ppapStaStatus: any;
	ppapPdStatus: any;
	ppapStaComments = '';
	ppapPdComments = '';
	recallComments = '';
	recallDate: any;
	pswStaStatus: string;
	pswPdComments: string;
	ppappackagePartsEntityId: number;
	overallStatus: any;
	commentsEnableCondition: boolean;
	isElementQuestionRejected: boolean;
	ppapAssessmentResponse: PpapElementRecords[] = [];
	isElementNeedsClarification: boolean
	disableAuditFields = false;
	ppapStaStatusBy: any;
	ppapStaStatusOn: any;
	ppapPdStatusBy: any;
	ppapPdStatusOn: any;
	private approverId: string;
	submittedBy: any;
	submittedOn: any;
	partPappLevel: any;
	displayFordApprovalCol = true;
	status: string;
	private pswSupplier: Map<string, any> = new Map<string, any>();
	restrictApproverAccess: boolean;
	sectionNC: any[];
	isSubmitted = false;
	pswStaStatusSupplier: string;
	pswPdStatusSupplier: string;
	partDisableEnable = true;
	userId: any;
	isLoading = false;
	private displayDialogPpapStatus: boolean;
	programCodeOption: SelectItem[];
	programCode: DropdownOption;
	partNumbersOption: SelectItem[];
	partNumbers: DropdownOption;
	private packageIdentifierId: any[];
	copyDataList: CopyDataList;
	copyDataListList: CopyDataList;
	selectedCheckboxValues: any[] = [];
	private dataValue: any;
	private sectionValues: any;
	private dataCheckList: any[] = [];
	private elementValues: any[];
	private copyValues: any[];
	private digitalPpapValueEmpty: any[];
	isAttachVisible: boolean;
	isCopyButtonVisible: any;
	private siteCodeValues: any;
	isCopyOptionButtonVisible: any;
	requiredElementsFlag: boolean;
	chartRequiredElementsFlag: boolean;
	//Alert message - US3738472
	ppapPackagePartsList: any[] = [];
	message: any;
	displayMessage: any;
	private newPartList: any[] = [];
	private currentSiteCodePPAPLevel: string;
	rowGroupMetadata: any;
	onClickingChart = false;
	dataLoadComplete = false;
	questionIdList: any[] = [];
	questionIdListForResp: any[] = [];
	chartClickEvent: any;
	fileName = '';
	pdStaApprovalRequiredL1 = false;
	entityList: any[]=[];
	selectedPriorityValue: boolean;
	priorityPrgmCode: string;
	prioritySiteCode: any;
	priorityPartBase: string;
	eAPQPApiPrioritySave : EapqpAPIPriority;
	eAPQPApiPriority : EapqpAPIPriority;
	priorityListResp: any[] = [];
	enableOnViewModeForStaApprovalRequired = true;
	decMsg: any;
	checkingBlank: any;
	checkingNo: any;
	pStatus: any;
	cmmsApprovalMessage: string;
	cmmsNotApprovedParts: any[] = [];
	chagedPriorityStatus: any[] = [];
	displayChangedPriorityParts: boolean = false;
	displayOveridePriorityStatus: boolean = false;
	apqpPriorityYesStatus =false;
	apqpPriorityNullStatus =false;
	apqpGlobalPriority:string;
	runningChangeProgramCode: any[] = ['000001','000021','000022','000023','000024'];
	runningChangePartsStatus = false;

	// deleteRowForParts(
	private checkingYes: string;
	selectedFile: File;


	constructor(private http: HttpClient,public dialogService: DialogService, public messageService: MessageService, private confirmationService: ConfirmationService,
				private router: Router, public route: ActivatedRoute, private epswHomepageService: EpswHomepageService, private datePipe: DatePipe,
				private digitalPpapPackageService: DigitalPpapPackageService, private phaseSectionService: PpapPhaseSectionService) {
		this.ppapPackageIdentifier = this.route.snapshot.paramMap.get('id');
		this.mode = this.route.snapshot.paramMap.get('mode');
		// this.mode = this.router.getCurrentNavigation().extras.state.mode;
		this.ppapPartsArrayLength = 0;
		//this.phaseType = sessionStorage.getItem('ppapPhase');
		this.displayPpapPhase = sessionStorage.getItem('displayPpapPhase');
		this.ppapPackageIdentifierDisplay = new PpapPackageIdentifier(this.ppapPackageIdentifierDisplay);
		this.copyDataList = new CopyDataList(this.copyDataList);
		this.isAttachVisible = false;
		this.isCopyButtonVisible = false;
		//this.isCopyOptionButtonVisible = false;
		this.approverCdsid = new LdapResponse(this.approverCdsid);
		this.approverCdsidsta = new LdapResponse(this.approverCdsidsta);
		this.epswHomepageService.getDataOfSelectedRow(this.ppapPackageIdentifier).subscribe(resp => {
			this.ppapPackageIdentifierDisplay = resp.ppapPackageIdentifierEntity;
			if(this.runningChangeProgramCode.includes(this.ppapPackageIdentifierDisplay?.ppapPackagePartsEntity[0].programCode)){
				this.runningChangePartsStatus=true;
			}
			console.log('this.ppapPackageIdentifierDisplay : '+JSON.stringify(resp));
			if(this.ppapPackageIdentifierDisplay.pdApprovalRequired !== null) {
				this.ppapPackageIdentifierDisplay.pdApprovalRequired = this.ppapPackageIdentifierDisplay
					.pdApprovalRequired === '1' ? 'true' : 'false';
			}
			if(this.ppapPackageIdentifierDisplay.familyPriorityStatus === 'Yes') {
				this.pdApprovalRequiredOption = 'Yes';
			} else if(this.ppapPackageIdentifierDisplay.familyPriorityStatus === 'No') {
				this.pdApprovalRequiredOption = 'No';
			}
			if(this.ppapPackageIdentifierDisplay.pdApprovalRequired === 'true'){
				this.pdApprovalRequiredOption = 'Yes';
				this.approverCdsid.userId = this.ppapPackageIdentifierDisplay.pdApproverCdsid;
				this.approverCdsidsta.userId = this.ppapPackageIdentifierDisplay.staApproverCdsid;
			} else if(this.ppapPackageIdentifierDisplay.pdApprovalRequired === 'false' && this.ppapPackageIdentifierDisplay.familyPriorityStatus==='No'){
				this.pdApprovalRequiredOption = 'No';
				this.approverCdsidsta.userId = this.ppapPackageIdentifierDisplay.staApproverCdsid;
			}
			this.ppapPackageIdentifierDisplayOld = _.cloneDeep(this.ppapPackageIdentifierDisplay);
			this.ppapPackageIdentifierDisplayNew = _.cloneDeep(resp);
			if(this.ppapPackageIdentifierDisplay.staApproverCdsid != null || this.ppapPackageIdentifierDisplay.staApproverCdsid != null ){
				this.cdsidSavedFlag = true;
			}
			this.phaseType = this.ppapPackageIdentifierDisplay.ppapPhase;
			sessionStorage.setItem('ppapPhase', this.ppapPackageIdentifierDisplay.ppapPhase);
			this.epswHomepageService.getSupplierName(this.ppapPackageIdentifierDisplay.siteCode).subscribe(respSite => {
				this.currentSiteCodePPAPLevel = respSite.simsahProdPpapLevel;
				this.ppapPackageIdentifierDisplay.ppapPackagePartsEntity = this.ppapPartsArray;
				for (const data of this.ppapPackageIdentifierDisplay.ppapPackagePartsEntity) {
					if (this.currentSiteCodePPAPLevel === '1' && this.overallStatus === 'LEVEL 1 SELF APPROVED'
						&& data.status === 'LEVEL 1 SELF APPROVED') {
						this.enableRecallSection = false;
					}
					if(data.priorityStatus ==='Yes'){
						this.apqpGlobalPriority = 'Yes';
						this.apqpPriorityYesStatus=true;
					}else if((data.priorityStatus ==='null' || data.priorityStatus ===null) && !this.apqpPriorityYesStatus){
						this.apqpGlobalPriority ='null';
						this.apqpPriorityNullStatus=true;
					}else if(data.priorityStatus==='No' && !this.apqpPriorityYesStatus && !this.apqpPriorityNullStatus){
						this.apqpGlobalPriority ='No';
					}
				}
			}, error => {
				if (error.error.text === 'Site Code Not Found') {
					this.currentSiteCodePPAPLevel = '5';
				}
			});
			if (this.phaseType === 'Phase0') {
				this.restrictApproverAccess = false;
			} else {
				this.restrictApproverAccess = true;
			}
			// if(this.phaseType === 'Phase3') {
			//   this.requiredElementsSavedFlag=true;
			// }
			if(this.mode === 'edit') {
				this.getStatus();
			}
			if(this.approverCdsidsta.userId === sessionStorage.getItem('userId').toUpperCase() && this.pdApprovalRequiredOption === 'No' ) {
				this.getPrStatus();
			}
			this.getSupplierName();
			this.retrieveCommodityImage();
			this.ppapPartsArray = this.ppapPackageIdentifierDisplay?.ppapPackagePartsEntity;
			this.ppapPackageIdentifierStore = this.ppapPackageIdentifierDisplay;
			this.priorityPrgmCode = this.ppapPartsArray[0].programCode;
			this.prioritySiteCode = this.ppapPackageIdentifierDisplay.siteCode;
			this.priorityPartBase = this.ppapPartsArray[0].noPartBase;
			console.log('this.priorityPrgmCode : '+this.priorityPrgmCode);
			console.log('this.prioritySiteCode : '+this.prioritySiteCode);
			console.log('this.priorityPartBase : '+this.priorityPartBase);
			// if(this.mode !== 'view' && this.mode !== 'edit') {
			// 	this.getPriorityStatus(this.ppapPartsArray, this.ppapPackageIdentifierDisplay.siteCode);
			// }
			if (this.ppapPartsArray.filter(i => i.noPartBase === '1508').length > 0) {
				this.chartRequiredElementsFlag = true;
				this.requiredElementsFlag = true;
			} else {
				this.digitalPpapPackageService.getSeparateProgram().subscribe(programCode => {
						let iterator;
						this.chartRequiredElementsFlag = false;
						for (iterator = 0; iterator < programCode.length; iterator++) {
							const j = this.ppapPartsArray.filter(i => i.programCode === programCode[iterator]);
							if (j !== undefined && j.length > 0) {
								this.requiredElementsFlag = true;
								break;
							} else {
								this.requiredElementsFlag = false;
							}
						}

					}
					, error => {
						console.log(' getSeparateProgram Error Log ' + error);
					}
				);
			}
			this.ppapPartsArrayLength = this.ppapPartsArray.length;
			this.ppapPackageIdentifierId = this.ppapPartsArray[0].ppapPackageIdentifierID;
			this.ppapLevelParts = this.ppapPartsArray[0].ppapLevel;
			this.retrieveTier2SubSupplierDetails();
			this.ppapElementEditModeCancel();
			this.sectionNCValidation();
			// if (this.ppapPackageIdentifierDisplay.pdApprovalRequired === true) {
			// 	this.pdApprovalRequiredOption = 'true';
			// } else {
			// 	this.pdApprovalRequiredOption = 'false';
			// }
			if (sessionStorage.getItem('attributes') !== null) {
				this.apsAttributes = sessionStorage.getItem('attributes');
				this.userId = sessionStorage.getItem('userId').toLocaleUpperCase();
				this.userRole = JSON.parse(this.apsAttributes).userRole;
				this.pswAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForSupplier(this.userRole, this.phaseType);
				this.pswSupplier = RoleBasedAccessProviderService.getAccessForSupplierRole(this.userRole);
				if (!this.pswSupplier.get('isSupplier')) {
					this.butIndex = 0;
				}
				if (this.pdApprovalRequiredOption === 'Yes') {
					if (this.userRole === 'STASiteEngineer' || this.userRole === 'APQPSiteSTAEngineer'
						|| this.userRole === 'STASiteSupervisor' || this.userRole === 'STASiteManager') {
						if (this.userRole === 'STASiteEngineer') {
							if (this.ppapPackageIdentifierDisplay.staApproverCdsid === this.userId) {
								this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('STASiteEngineer', true)
							}
						} else if (this.userRole === 'STASiteSupervisor') {
							if (this.ppapPackageIdentifierDisplay.staApproverCdsid === this.userId) {
								this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('STASiteSupervisor', true)
							}
						} else if (this.userRole === 'STASiteManager') {
							if (this.ppapPackageIdentifierDisplay.staApproverCdsid === this.userId) {
								this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('STASiteManager', true)
							}
						} else {
							if (this.ppapPackageIdentifierDisplay.staApproverCdsid === this.userId) {
								this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('APQPSiteSTAEngineer', true)
							}
						}
					} else if (this.userRole === 'PDEngineer') {
						if (this.ppapPackageIdentifierDisplay.pdApproverCdsid === this.userId) {
							this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('PDEngineer', true)
						}
					}
				} else if (this.pdApprovalRequiredOption === 'No') {
					if (this.userRole === 'STASiteEngineer' || this.userRole === 'APQPSiteSTAEngineer'
						|| this.userRole === 'STASiteSupervisor' || this.userRole === 'STASiteManager' ) {
						if (this.userRole === 'STASiteEngineer') {
							if (this.ppapPackageIdentifierDisplay.staApproverCdsid === this.userId) {
								this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('STASiteEngineer', true)
							}
						} else if (this.userRole === 'STASiteSupervisor') {
							if (this.ppapPackageIdentifierDisplay.staApproverCdsid === this.userId) {
								this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('STASiteSupervisor', true)
							}
						} else if (this.userRole === 'STASiteManager') {
							if (this.ppapPackageIdentifierDisplay.staApproverCdsid === this.userId) {
								this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('STASiteManager', true)
							}
						}else {
							if (this.ppapPackageIdentifierDisplay.staApproverCdsid === this.userId) {
								this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('APQPSiteSTAEngineer', true)
							}
						}
					}
				}
				if (this.mode !== 'create') {
					this.ppappackagePartsEntityId = this.ppapPartsArray[0].ppapPackagePartsEntityID;
					this.ppapStaComments = this.ppapPartsArray[0].ppapStaComments;
					this.ppapPdComments = this.ppapPartsArray[0].ppapPdComments;
					//this.recallComments = this.ppapPartsArray[0].recallComments;
					this.ppapStaStatus = this.ppapPartsArray[0].ppapStaStatus;
					this.ppapStaStatusBy = this.ppapPartsArray[0].ppapStaStatusBy;
					this.ppapStaStatusOn = this.datePipe.transform(this.ppapPartsArray[0].ppapStaStatusOn, 'yyyy-MM-dd');
					this.ppapPdStatus = this.ppapPartsArray[0].ppapPdStatus;
					this.ppapPdStatusBy = this.ppapPartsArray[0].ppapPdStatusBy;
					this.ppapPdStatusOn = this.datePipe.transform(this.ppapPartsArray[0].ppapPdStatusOn, 'yyyy-MM-dd');
					this.overallStatus = this.ppapPartsArray[0].status;
					this.submittedBy = this.ppapPartsArray[0].submittedBy;
					this.submittedOn = this.datePipe.transform(this.ppapPartsArray[0].submittedOn, 'yyyy-MM-dd');
					this.recallDate = this.datePipe.transform(this.ppapPartsArray[0].recallDate, 'yyyy-MM-dd');
					this.pswStaStatusSupplier = this.ppapPartsArray[0].pswStaStatus;
					this.pswPdStatusSupplier = this.ppapPartsArray[0].pswPdStatus;
				} else {
					this.status = this.ppapPartsArray[0].status;
				}
				// Approver editable mode
				if (!this.restrictApproverAccess) {
					if (this.pswApproverAccessControl.get('approverConditionEnabled')) {
						if (this.pswApproverAccessControl.get('staApprovalConditionsEnabled')) {
							if (this.overallStatus === 'AWAITING APPROVAL' || this.overallStatus === 'LEVEL 1 SELF APPROVED') {
								if (this.ppapStaStatus !== '' && this.ppapStaStatus !== null) {
									this.needApproverAction = false;
								} else {
									this.needApproverAction = true;
								}
								this.enableRecallSection = true;
								this.recallComments = '';
							}
						} else {
							if (this.overallStatus === 'AWAITING APPROVAL' || this.overallStatus === 'LEVEL 1 SELF APPROVED') {
								if (this.ppapPdStatus !== '' && this.ppapPdStatus !== null) {
									this.needApproverAction = false;
								} else {
									this.needApproverAction = true;
								}
								this.enableRecallSection = true;
								this.recallComments = '';
							}
						}
					} else{
						this.enableRecallSection = true;
					}
				} else {
					if (this.pswApproverAccessControl.get('approverConditionEnabled')) {
						if (this.pswApproverAccessControl.get('staApprovalConditionsEnabled')) {
							// if (this.overallStatus === 'AWAITING APPROVAL') {
							if (this.overallStatus === 'AWAITING APPROVAL' || this.overallStatus === 'LEVEL 1 SELF APPROVED') {
								if (this.pswStaStatusSupplier !== 'APPROVED') {
									this.needApproverAction = true;
								} else {
									this.needApproverAction = false;
								}
								this.enableRecallSection = true;
							} else {
								this.needApproverAction = false;
								this.enableRecallSection = false;
							}
							// }
						} else {
							// if (this.overallStatus === 'AWAITING APPROVAL') {
							if (this.overallStatus === 'AWAITING APPROVAL' || this.overallStatus === 'LEVEL 1 SELF APPROVED') {
								if (this.pswPdStatusSupplier !== 'APPROVED') {
									this.needApproverAction = true;
								} else {
									this.needApproverAction = false;
								}
								this.enableRecallSection = true;
							} else {
								this.needApproverAction = false;
								this.enableRecallSection = false;
							}
							// }
						}
					}
				}
				if (this.status === 'IN PROGRESS' || this.overallStatus === 'IN PROGRESS') {
					this.displayFordApprovalCol = false;
					this.enableRecallSection = false;
				}
				this.displayPartData = false;
				if (this.mode === 'view') {
					this.enableOnViewMode = false;
					this.ppapPartsArray = this.ppapPackageIdentifierDisplay?.ppapPackagePartsEntity;
					if(this.ppapPartsArray[0].staApprovalRequired === 'true') {
						this.pdStaApprovalRequiredL1 = true;
						this.enableOnViewModeForStaApprovalRequired = false;
					} else{
						this.pdStaApprovalRequiredL1 = false;
					}
				} else {
					this.enableOnViewMode = true
				}

				if(this.mode === 'edit') {
					console.log('553')
					this.ppapPartsArray = this.ppapPackageIdentifierDisplay?.ppapPackagePartsEntity;
					this.ppapPartsArray.forEach(data => {
						this.digitalPpapPackageService.getPartsValueFromCmms(data.noPartPrefix+'-'+data.noPartBase+'-'+data.noPartSuffix).subscribe(response => {
							const da = response.ppapPackageParts;
							if(da.capDt!==null && da.capDt!==undefined) {
								data.capDt = da.capDt;
							}
							if(da.qualDt!==null && da.qualDt!==undefined) {
								data.qualDt = da.qualDt;
							}
							if(da.rarDt!==null && da.rarDt!==undefined) {
								data.rarDt = da.rarDt;
							}
							if(da.prodDt!==null && da.prodDt!==undefined) {
								data.prodDt = da.prodDt;
							}
						});
						console.log(this.ppapPartsArray)
					})
					console.log(this.ppapPartsArray)

				}
				// this.digitalPpapPackageService.getPartsValueFromCmms(data.noPartPrefix+'-'+data.noPartBase+'-'+data.noPartSuffix).subscribe(da => {
							// 	console.log('614',da);
							// 	data.capDt = da.capDt;
							// 	data.qualDt = da.qualDt;
							// 	data.rarDt = da.rarDt;
							// 	data.prodDt = da.prodDt;
							// });


				// editable - If the Package is rejected/needs clarification
				console.log(this.ppapPartsArray);
				if (this.userRole === 'EAPQP_UPDATE') {
					if (this.overallStatus === 'REJECTED' || this.overallStatus === 'NEEDS CLARIFICATION' || this.overallStatus === 'INTERIM ACCEPTED') {
						this.enableOnViewMode = true;
						this.enableRecallSection = false;
						if(this.ppapPartsArray[0].staApprovalRequired === 'true') {
							this.pdStaApprovalRequiredL1 = true;
							this.enableOnViewModeForStaApprovalRequired = false;
						} else{
							this.pdStaApprovalRequiredL1 = false;
						}

					}
				}

				//Enable STA/PD Comments
				if (this.overallStatus === 'REJECTED' || this.overallStatus === 'NEEDS CLARIFICATION' || this.overallStatus === 'APPROVED') {
					this.commentsEnableCondition = true;
					this.enableRecallSection = false;
				} else { //if(this.overallStatus === 'AWAITING APPROVAL')
					if (this.pswApproverAccessControl.get('approverConditionEnabled')) {
						this.commentsEnableCondition = true;
					} else {
						this.commentsEnableCondition = false;
					}
				}
			}
		});
		this.ppapLevelOptions = [
			{label: '1', value: '1'},
			{label: '3', value: '3'},
			{label: '5', value: '5'}
		]
		//this.display = true;
		this.datasets1 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			this.datasets2 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			this.datasets3 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			this.datasets4 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			this.enable = true;

		this.data = {
			labels: this.ppapElementSection,
			datasets: [
				{
					label: 'Need to Answer',
					data: this.datasets1,
					backgroundColor: [
						'rgb(16,43,78,0.5)',
						'rgb(16,43,78,0.5)',
						'rgb(16,43,78,0.5)',
						'rgb(16,43,78,0.5)',
						'rgb(16,43,78,0.5)',
						'rgb(16,43,78,0.5)',
						'rgb(16,43,78,0.5)',
						'rgb(16,43,78,0.5)',
						'rgb(16,43,78,0.5)',
						'rgb(16,43,78,0.5)',
						'rgb(16,43,78,0.5)',
						'rgb(16,43,78,0.5)',
					],
					borderWidth: 0,
				},
				{
					label: 'Okay',
					data: this.datasets2,
					backgroundColor: [
						'rgb(0, 100, 0,0.5)',
						'rgb(0, 100, 0,0.5)',
						'rgb(0, 100, 0,0.5)',
						'rgb(0, 100, 0,0.5)',
						'rgb(0, 100, 0,0.5)',
						'rgb(0, 100, 0,0.5)',
						'rgb(0, 100, 0,0.5)',
						'rgb(0, 100, 0,0.5)',
						'rgb(0, 100, 0,0.5)',
						'rgb(0, 100, 0,0.5)',
						'rgb(0, 100, 0,0.5)',
						'rgb(0, 100, 0,0.5)',
					],
					borderWidth: 0,
				},
				{
					label: 'Not OK',
					data: this.datasets3,
					borderWidth: 0,
					backgroundColor: [
						'rgb(255, 0, 0,0.5)',
						'rgb(255, 0, 0,0.5)',
						'rgb(255, 0, 0,0.5)',
						'rgb(255, 0, 0,0.5)',
						'rgb(255, 0, 0,0.5)',
						'rgb(255, 0, 0,0.5)',
						'rgb(255, 0, 0,0.5)',
						'rgb(255, 0, 0,0.5)',
						'rgb(255, 0, 0,0.5)',
						'rgb(255, 0, 0,0.5)',
						'rgb(255, 0, 0,0.5)',
						'rgb(255, 0, 0,0.5)',
					]
				},
				{
					label: 'N/A',
					data: this.datasets4,
					backgroundColor: [
						'rgb(255,255,0,0.5)',
						'rgb(255,255,0,0.5)',
						'rgb(255,255,0,0.5)',
						'rgb(255,255,0,0.5)',
						'rgb(255,255,0,0.5)',
						'rgb(255,255,0,0.5)',
						'rgb(255,255,0,0.5)',
						'rgb(255,255,0,0.5)',
						'rgb(255,255,0,0.5)',
						'rgb(255,255,0,0.5)',
						'rgb(255,255,0,0.5)',
						'rgb(255,255,0,0.5)',

					],
					borderWidth: 0,
				}
			]
		}

		this.chartOptions = {
			plugins: {
				datalabels: {
					align: 'center',
					//anchor: 'center',
					borderRadius: 4,
					//backgroundColor:'teal',
					color: 'white',
					font: {
						weight: 'bold',
						size: 14
					},
					formatter(value) {
						if (value > 0) {
							value = value.toString();
							value = value.split(/(?=(?:...)*$)/);
							value = value.join(',');
							return value;
						} else {
							value = '';
							return value;
						}
					}
				}
			},
			legend:
				{
					display: false,
					// labels: {
					// 	fontColor: 'black',
					// 	fontSize: 16,
					// }
				},
			title: {
				display: false,
				text: this.phaseType,
				fontColor: 'black',
				fontSize: 16,
				//fontStyle: 'none'
			},

			onClick(evt, activeElements) {
			},
			onHover: (event, chartElement) => {
				event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
			},
			scales: {
				yAxes: [{
					stacked: true,
					ticks: {
						stepSize: 2,
						beginAtZero: true,
						fontColor: 'black',
						fontSize: 14
					},
					scaleLabel: {
						display: true,
						labelString: '# Of Check List Questions',
						fontColor: 'black',
						fontSize: 14
					},
					gridLines: {
						display: true,
						drawBorder: true,
						drawOnChartArea: false,
						lineWidth: 2,
						color: 'black'

					}

				}],
				xAxes: [{
					stacked: true,
					ticks: {
						fontFamily: 'Trebuchet MS, Arial, Helvetica, sans-serif',
						fontSize: 16,
						//fontStyle: 'bold',
						fontColor: 'black',
						autoSkip: false,
						//	maxRotation: 90,
						//minRotation: 90,
						callback(label, index, labels) {
							// if (/\s/.test(label)) {
							// 	return label.split(' ');
							// } else {
							// 	return label;
							// }
							const characterLimit = 20;
							if (label.length >= characterLimit) {
								const labelCheck = label.slice(0, label.length).substring(0, characterLimit - 1).trim() + '...';
								return labelCheck.split(' ');
							} else {
								return label.split(' ');
							}
						}
					},
					scaleLabel: {
						display: true,
						labelString: 'PPAP Required Elements',
						fontColor: 'black',
						fontSize: 18,
						fontStyle: 'bold',
						fontWeight: 400,
						fontFamily: 'Trebuchet MS, Arial, Helvetica, sans-serif'
						},
					gridLines: {
						display: true,
						drawBorder: true,
						drawOnChartArea: false,
						lineWidth: 2,
						color: 'black'

					}
				}]
			}
		}
	}

	private getStatus() {
		const status = this.ppapPackageIdentifierDisplay.ppapPackagePartsEntity[0].status;
		if((status === 'IN PROGRESS' || status === 'REJECTED' || this.ppapPackageIdentifierDisplay.ppapPackagePartsEntity[0].status=== 'NEEDS CLARIFICATION') && this.route.snapshot.paramMap.get('previousFlag')===null)
			this.ppapPackageIdentifierDisplay.addOrUpdateParts=false;
		this.epswHomepageService.saveData(this.ppapPackageIdentifierDisplay).subscribe(resp => {
			this.ppapPackageIdentifierStore.addOrUpdateParts=false;
			console.log('Save Data: ' , resp,this.ppapPackageIdentifierDisplay);
			this.ppapPackageIdentifierNew = resp;
			this.ppapPackageIdentifierDisplay.ppapPackagePartsEntity.forEach(data=> {
				this.ppapPackageIdentifierNew.ppapPackagePartsEntity.forEach(newData => {
					if(data.ppapPackagePartsEntityID === newData.ppapPackagePartsEntityID && data.priorityStatus !== newData.priorityStatus) {
							this.chagedPriorityStatus.push(data.ppapPackagePartsEntityID);
						}
				})
			})

			if(this.ppapPackageIdentifierNew.familyPriorityStatus===null){
				this.pdApprovalRequiredOption = '';
				this.cdsidSavedFlag=false;
			}

			if(this.chagedPriorityStatus.length>=1) {
				this.displayChangedPriorityParts = true;
			}
		});
	}

	ngOnInit(): void {
		//subsuplier name
		this.subSupliercategoryOptions = [{label: '', value: ''}, {
			label: 'Tier 2',
			value: 'Tier 2'
		}, {label: 'Ford DT (Straight)', value: 'Ford DT (Straight)'},
			{label: 'Bailment', value: 'Bailment'}, {label: 'Ford DT (MPA)', value: 'Ford DT (MPA)'}]
		this.editMode = false;
		this.totalTableData = 0;
		this.recallComments = '';
		// this.ppapElementEditModeCancel();
		//tree map
		// this.getPrefixBaseSuffixForParts();
		this.numberOfSupplier = 3;

		this.supplierHeader = [
			{field: 'subSupplier', header: 'SUB SUPPLIER'},
			{field: 'partDescription', header: 'PART DESCRIPTION'}
		];
		this.supplierDetails = [
			{
				subSupplier: 'Supplier A',
				partDescription: 'xxxxxxx'
			},
			{
				subSupplier: 'Supplier B',
				partDescription: 'xxxxxxxx'
			},
			{
				subSupplier: 'Supplier C',
				partDescription: 'xxxx'
			}

		]

		//table
		this.colsForParts = [
			//{field: 'ppapcategory', header: 'PPAP CATEGORY'},
			{field: 'programCode', header: 'PGM'},
			{field: 'prefixBaseSuffix', header: 'PREFIX-BASE-SUFFIX'},
			// {field: 'suffix', header: 'BASE'},
			// {field: 'base', header: 'SUFFIX'},
			{field: 'partDescription', header: 'PART DESCRIPTION'},
			// { field: 'changeintiming', header: 'CHANGE IN TIMING' },
			{field: 'rarDt', header: 'PHASE 0'},
			// { field: 'rcheckout', header: 'CHECKOUT' },
			{field: 'qualDt', header: 'PHASE 1'},
			// { field: 'qcheckout', header: 'CHECKOUT' },
			{field: 'prodDt', header: 'PHASE 2'},
			// { field: 'pcheckout', header: 'CHECKOUT' },
			{field: 'capDt', header: 'PHASE 3'},
			{field: 'ppapLevel', header: 'PPAP LEVEL'}
			//,
			//{field: 'priorityStatus', header: 'PRIORITY STATUS'}

			// // ,
			// {field: 'status', header: 'STATUS'},
			// {field: 'level', header: 'PPAP LEVEL'}
		];
		this.cols = [
			//{field: 'ppapcategory', header: 'PPAP CATEGORY'},
			{field: 'pgm', header: 'PGM'},
			{field: 'prefix', header: 'PREFIX'},
			{field: 'suffix', header: 'BASE'},
			{field: 'base', header: 'SUFFIX'},
			{field: 'partdescription', header: 'PART DESCRIPTION'},
			// { field: 'changeintiming', header: 'CHANGE IN TIMING' },
			{field: 'rardt', header: 'RAR DT'},
			// { field: 'rcheckout', header: 'CHECKOUT' },
			{field: 'qualdt', header: 'QUAL DT'},
			// { field: 'qcheckout', header: 'CHECKOUT' },
			{field: 'proddt', header: 'PROD DT'},
			// { field: 'pcheckout', header: 'CHECKOUT' },
			{field: 'capdt', header: 'CAPDT'}
			// ,
			// {field: 'status', header: 'STATUS'},
			// {field: 'level', header: 'PPAP LEVEL'}
		];

		//table
		this.ppapTabDetail = [
			{
				ppapcategory: 'All PPAP',
				pgm: '3691',
				prefix: 'AB39-',
				base: 'AE',
				suffix: '2122345-',
				partdescription: 'HGA ASY FRT DT HGA ASY FRT DT HGA ASY FRT DT HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18'
				// status: 'PENDING',
				// level: 'Level 3'
			},
			{
				ppapcategory: 'Lunch / Running Change PPAP',
				pgm: '3692',
				prefix: 'AB39-',
				base: 'AE',
				suffix: '2122346-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18'
				// status: 'PENDING',
				// level: 'Level 3'
			},
			{
				ppapcategory: 'Supplier Requested Change',
				pgm: '3693',
				prefix: 'AB39-',
				base: 'AE',
				suffix: '2122347-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18'
				// status: 'NEEDS CLARIFICATION',
				// level: 'Level 3'
			},
			{
				ppapcategory: 'Annual Re-PPAP',
				pgm: '3694',
				prefix: 'AB39-',
				base: 'AE',
				suffix: '2122348-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18'
				// status: 'PENDING',
				// level: 'Level 3'
			},
			{
				ppapcategory: 'All PPAP',
				pgm: '3691',
				prefix: 'AB39-',
				base: 'AE',
				suffix: '2122348-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18'
				// status: 'PENDING',
				// level: 'Level 3'
			},
			{
				ppapcategory: 'Lunch / Running Change PPAP',
				pgm: '3692',
				prefix: 'AB39-',
				base: 'AE',
				suffix: '2122345-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18'
				// status: 'InProgress',
				// level: 'Level 3'
			},
			{
				ppapcategory: 'Supplier Requested Change',
				pgm: '3693',
				prefix: 'AB39-',
				base: 'AE',
				suffix: '2122346-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18'
				// status: 'InProgress',
				// level: 'Level 3'
			},
			{
				ppapcategory: 'Annual Re-PPAP',
				pgm: '3694',
				prefix: 'AB39-',
				base: 'AE',
				suffix: '2122347-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18'
				// status: 'AWAITING APPROVAL',
				// level: 'Level 3'
			},
			{
				ppapcategory: 'All PPAP',
				pgm: '3691',
				prefix: 'AB39-',
				base: 'AE',
				suffix: '2122348-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18'
				// status: 'AWAITING APPROVAL',
				// level: 'Level 3'
			},
			{
				ppapcategory: 'Lunch / Running Change PPAP',
				pgm: '3692',
				prefix: 'AB39-',
				base: 'AE',
				suffix: '2122349-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18',
				// status: 'PENDING',
				// level: 'Level 3'
			},
			{
				ppapcategory: 'Supplier Requested Change',
				pgm: '3693',
				prefix: 'AB39-',
				base: 'AE',
				suffix: '2122345-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18',
				// status: 'PENDING',
				// level: 'Level 3'
			}
		];
		// this.ppapPartsArray =	this.ppapPackageIdentifierDisplay?.ppapPackagePartsEntity;
		// this.ppapPackageIdentifierId = this.ppapPartsArray[0].ppapPackageIdentifierID;
		// this.ppapLevelParts =  this.ppapPartsArray[0].ppapLevel;
		//Breadcrumb
		this.home = {icon: 'fa fa-home'};
		this.menuItems = [
			{
				label: 'PPAP PACKAGE'
				// routerLink: '/DigitalPPAPPackageComponent',
			}
		];

		//ppap element
		this.statuses = [{label: '-/-', value: null}, {label: 'N/A', value: 'N/A'}, {
			label: 'OK',
			value: 'OK'
		}, {label: 'NOK', value: 'NOK'}]
		this.question = [{label: '-/-', value: null}, {label: 'YES', value: 'YES'}, {label: 'NO', value: 'NO'}]
		this.rejected = [{label: '-/-', value: null}, {
			label: 'REJECTED',
			value: 'REJECTED'
		}, {label: 'NEEDS CLARIFICATION', value: 'NEEDS CLARIFICATION'}]
		this.proprietary = [{label: '-/-', value: null}, {label: 'Proprietary', value: 'Proprietary'}]
		//Alert message - US3738472
		if (this.mode === 'edit') {
			this.epswHomepageService.getAllData(this.ppapPackageIdentifier).subscribe(partList => {
				this.ppapPackagePartsList = partList;
				if (this.ppapPackagePartsList.length >= 1) {
					this.message = 'One or more part numbers does not exist in cmms anymore.Please review the part list and delete the hightlighted parts.';
				}
			});
		}
		this.epswHomepageService.getAllData(this.ppapPackageIdentifier).subscribe(partList => {
			this.newPartList = partList;
		});
		this.epswHomepageService.getAllPartsWoCmmsApproval(this.ppapPackageIdentifier).subscribe(partList =>{
			if(this.displayPpapPhase !== 'Phase0'){
				let phaseValue;
				if (this.displayPpapPhase === 'Phase1'){
					phaseValue = 'Phase0';
				}else if(this.displayPpapPhase === 'Phase2'){
					phaseValue = 'Phase1';
				}else{
					phaseValue = 'Phase2';
				}
				this.cmmsApprovalMessage = 'One or more parts are not approved for '+phaseValue+
					' in VPP/MPP, please approve '+phaseValue+' in VPP/MPP';
				this.cmmsNotApprovedParts = partList;
			}
		});
		this.checkFlagPpapRequiredElement().then();
	}

	showNotCmmsApprovedParts(){
		this.displayNotCmmsPartDlg = true;
	}

	onCommodityImageUpload(event) {
		if (event.target.files && event.target.files[0]) {
			const reader = new FileReader();
			console.log(event.target.files[0]);
			console.log(event.target.files[0].name);
			//	this.commodityImage = event.target.files[0];
			reader.readAsDataURL(event.target.files[0]); // read file as data url

			// tslint:disable-next-line:no-shadowed-variable
			reader.onload = (event) => { // called once readAsDataURL is completed
				this.url = event.target.result;
			}
			//	this.retrievedImage =  'data:image/jpeg;base64,' + event.target.files[0];
			this.selectedCmdyImage = event.target.files[0];
			this.commodityImage = event.target.files[0];
			// const uploadCommodityImage = new FormData();
			this.uploadCommodityImage.append('imageFile', this.commodityImage, event.target.files[0].name);
			this.saveCommodityImage();
		}
	}

	onAttachmentFile(event) {
		if (event.target.files && event.target.files[0]) {
			const reader = new FileReader();
			// console.log(event.target.files[0]);
			console.log(event.target.files[0].name);
			this.attachmentName = event.target.files[0].name;
			// reader.readAsDataURL(event.target.files[0]); // read file as data url
			//
			// reader.onload = (event) => { // called once readAsDataURL is completed
			// 	this.url = event.target.result;
			// }
		}
	}

	onTier2ExcelUpload(event) {
		console.log(event.target.files[0].type);
		if (event.target.files && event.target.files[0]) {
			console.log('Upload Excel file called');
			const file = event.target.files[0];
			const uploadTier2Excel = new FormData();
			uploadTier2Excel.append('excelFile', file, file.name);
			uploadTier2Excel.append('ppapPackageIdentifierID', this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID.toString());
			if (event.target.files[0].type === 'application/vnd.ms-excel.sheet.macroEnabled.12' || event.target.files[0].type === 'application/vnd.ms-excel.sheet.macroenabled.12') {
				this.digitalPpapPackageService.uploadTier2Excel(uploadTier2Excel).subscribe(resp => {
					if (resp.toString().length > 0) {
						console.log('Excel upload response');
						this.messageService.add({
							severity: 'success',
							summary: 'success',
							detail: 'Uploaded Tier 2 Excel Successfully.'
						});
						this.tier2SavedFlag=true;
						this.retrieveTier2SubSupplierDetails();
					} else {
						this.messageService.add({
							severity: 'warn',
							summary: 'warning',
							detail: 'Please Upload Excel with Data',
							life: 5000
						});
						this.tier2SavedFlag=false;
					}
				}, error => {
					console.log('Error on upload Tier2Excel');
					this.messageService.add({
						severity: 'error',
						summary: 'Error',
						detail: 'Unable to upload Excel Tier-2 Details. ' +
							'Please fill out all mandatory fields in the excel till you see Green Success after save, then upload again.',
						life: 5000
					});
				});
			} else {
				this.messageService.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Please use sample file type format.',
					life: 5000
				});
			}

		}
	}

	onButClick(index) {
		console.log('buttonclick' + index);
		this.butIndex = index;
		//this.chart.reinit();
	}

	//Start dynamic table
	openNew() {
		this.product = [];
		this.submitted = false;
		this.productDialog = true;
	}

	deleteSelectedProducts() {
		// this.confirmationService.confirm({
		// 	message: 'Are you sure you want to delete the selected products?',
		// 	header: 'Confirm',
		// 	icon: 'pi pi-exclamation-triangle',
		// 	accept: () => {
		// 		this.products = this.products.filter(val => !this.selectedProducts.includes(val));
		// 		this.selectedProducts = null;
		// 		// this.messageService.add({severity:'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
		// 	}
		// });
	}

	editProduct(product: Product) {
		//this.product = [...product];
		this.productDialog = true;
	}

	deleteProduct(product: Product) {
		// this.confirmationService.confirm({
		// 	message: 'Are you sure you want to delete ' + product.name + '?',
		// 	header: 'Confirm',
		// 	icon: 'pi pi-exclamation-triangle',
		// 	accept: () => {
		// 		this.products = this.products.filter(val => val.id !== product.id);
		// 		this.product = {};
		// 		this.messageService.add({severity:'success', summary: 'Successful', detail: 'Product Deleted', life: 3000});
		// 	}
		// });
	}

	hideDialog() {
		this.productDialog = false;
		this.submitted = false;
	}

	saveProduct() {
		this.submitted = true;
		this.products = [...this.products];
		this.productDialog = false;
		this.product = [];
	}

	findIndexById(id: string): number {
		let index = -1;
		for (let i = 0; i < this.products.length; i++) {
			if (this.products[i].id === id) {
				index = i;
				break;
			}
		}

		return index;
	}

	createId(): string {
		let id = '';

		const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		for (let i = 0; i < 5; i++) {
			id += chars.charAt(Math.floor(Math.random() * chars.length));
		}
		return id;
	}

	//dynamic table end

	filterCountry(event) {
		// //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
		// const filtered: any[] = [];
		// const query = event.query;
		// // tslint:disable-next-line:prefer-for-of
		// for (let i = 0; i < this.countries.length; i++) {
		// 	const country = this.countries[i];
		// 	if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
		// 		filtered.push(country);
		// 	}
		// }

		this.filteredCDSID = [
			{name: 'bkrish12@ford.com', code: 'BK'},
			{name: 'tpremku1@ford.com', code: 'TP'}];
		console.log('test');
	}

	onEnable() {
		// tslint:disable-next-line:triple-equals
		if (this.enable != true) {
			this.enable = false;
		}
		// tslint:disable-next-line:triple-equals
		if (this.enable != false) {
			this.enable = true;
		}
		console.log(this.enable);

	}

	async chartClicked(e) {
		this.chartClickEvent = e;
		if (this.pswSupplier.get('isSupplier')) {
			this.onClickingChart = true;
			console.log('bar clicked for index : ' + e.element._index);
			console.log('element : ' + this.ppapElementSection[e.element._index]);
			this.butIndex = 0;
			this.ppapElementSelectedIndex = e.element._index;
			this.ppapElementSelectedSectionName = this.ppapElementSection[e.element._index];
			this.ppapElementRecords = [];
			this.digitalPpapPackageService
				.getPpapElementSavedQuestion(this.ppapElementSection[e.element._index],
					this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID).subscribe(resps => {
				console.log('Retrieve Data for getPpapElementSavedQuestion ' + resps);
				this.ppapElementRecords = resps;
				this.digitalPpapPackageService.findAllPpapAssessmentResponse(this.ppapPackageIdentifierId).subscribe(ppapAssessmentResponse => {
					this.ppapAssessmentResponse = ppapAssessmentResponse;
					//Disable Approve Reject, Needs Clarification
					for (const data of this.ppapAssessmentResponse) {
						if (data.staPdStatus === 'REJECTED') {
							this.isElementQuestionRejected = true;
							break;
						} else {
							this.isElementQuestionRejected = false;
						}
					}
					if (!this.isElementQuestionRejected) {
						for (const data of this.ppapAssessmentResponse) {
							if (data.staPdStatus === 'NEEDS CLARIFICATION') {
								this.isElementNeedsClarification = true;
								break;
							} else {
								this.isElementNeedsClarification = false;
							}
						}
					}
				});
				if (this.ppapElementRecords.length === 0) {
					this.digitalPpapPackageService.getPpapElementQuestion(this.ppapElementSection[e.element._index], this.phaseType).subscribe(resp => {
							console.log('Retrieve Data for Selected Chart: ' + resp);
							this.ppapElementRecords = resp;
							setTimeout(() => this.setRowHeight(this.ppapElementRecords), 0);
							document.getElementById('ppapPackageAssessment').scrollIntoView(true);
						}, error => {
							console.log('Error Log ' + error);
						}
					);
				}
				document.getElementById('ppapPackageAssessment').scrollIntoView(true);
			}, error => {
				console.log('getPpapElementSavedQuestion Error' + error);
			});
		} else {
			this.ppapElementSelectedSectionName = this.ppapElementSection[e.element._index];
			await this.ppapElementEditModeCancel();
		}
	}

	chartChange(section) {
		//data change action
		let count = 0;
		for (const i of this.ppapElementSection) {
			if (i === section) {
				break;
			}
			count++;
		}
		this.ppapElementTotalRecords = [];
		this.digitalPpapPackageService
			.getPpapElementSavedQuestion(section,
				this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID).subscribe(resps => {
			console.log('Retrieve Data for getPpapElementSavedQuestion ' + resps);
			this.ppapElementTotalRecords = resps;
			if (this.ppapElementTotalRecords.length === 0) {
				this.digitalPpapPackageService.getPpapElementQuestion(section, this.phaseType).subscribe(resp => {
						console.log('Retrieve Data for Selected Chart: ' + resp);
						this.ppapElementTotalRecords = resp;
						// document.getElementById('ppapPackageAssessment').scrollIntoView(true);
						const needAns = this.ppapElementTotalRecords.length;
						if (this.chart.data.datasets[0].data[count] === 0) {
							this.chart.data.datasets[0].data[count] = needAns;
						} else {
							this.chart.data.datasets[0].data[count] = 0
						}
						this.ppapElementTotalRecords = [];
						this.chart.refresh();
					}, error => {
						console.log('Error Log ' + error);
					}
				);
			} else {
				// const needAns = this.ppapElementTotalRecords.length;
				let okCount = 0;
				let notOkCount = 0;
				let naCount = 0;
				let needAnswer = 0;
				let ppapElementRecordsBefore: PpapElementRecords;
				if (this.ppapElementTotalRecords !== undefined) {
					for (ppapElementRecordsBefore of this.ppapElementTotalRecords) {
						if (ppapElementRecordsBefore.status === 'OK') {
							okCount++;
						}
						if (ppapElementRecordsBefore.status === 'NOK') {
							notOkCount++;
						}
						if (ppapElementRecordsBefore.status === 'N/A') {
							naCount++;
						}
						if (ppapElementRecordsBefore.status === null) {
							needAnswer++;
						}
					}
					if (this.chart.data.datasets[0].data[count] === 0 &&
						this.chart.data.datasets[1].data[count] === 0 &&
						this.chart.data.datasets[2].data[count] === 0 &&
						this.chart.data.datasets[3].data[count] === 0
					) {
						if (okCount >= 0) {
							this.chart.data.datasets[1].data[count] = okCount;
						}
						if (notOkCount >= 0) {
							this.chart.data.datasets[2].data[count] = notOkCount;
						}
						if (naCount >= 0) {
							this.chart.data.datasets[3].data[count] = naCount;
						}
						if (needAnswer >= 0) {
							this.chart.data.datasets[0].data[count] = needAnswer;
						}
					} else {
						this.chart.data.datasets[0].data[count] = 0
						this.chart.data.datasets[1].data[count] = 0
						this.chart.data.datasets[2].data[count] = 0
						this.chart.data.datasets[3].data[count] = 0
					}
				}

				// if(this.chart.data.datasets[0].data[count]===  0) {
				// 	this.chart.data.datasets[0].data[count] =  needAns;
				// } else{
				// 	this.chart.data.datasets[0].data[count] = 0
				// }
				this.chart.refresh();
				this.ppapElementTotalRecords = [];
			}
			// document.getElementById('ppapPackageAssessment').scrollIntoView(true);
		}, error => {
			console.log('getPpapElementSavedQuestion Error' + error);
		});

	}

	addNewRow() {
		this.subSupplierPpapPackage = new SubSupplierPpapPackage(this.subSupplierPpapPackageData);
		this.products.push(this.subSupplierPpapPackage);
		//this.editMode = true;
	}

	// showPartCountData() {
	// 	this.displayPartData = true;
	// }

	show() {
		this.displayDialog = true;
		this.editParts = true;
		this.getPrefixBaseSuffixForParts();
		if (this.ppapPartsArray.length === 1)
			this.disableDelete = true;
		else
			this.disableDelete = false;

		if (this.ppapPartsArray.filter(
			ppapStatus => ppapStatus.status !== 'IN PROGRESS').length > 0) {
			this.displayDialogPpapStatus = true;
		} else {
			this.displayDialogPpapStatus = false;
		}
		// const ref = this.dialogService.open(PpapPartsComponent, {
		// 	header: 'PART LIST',
		// 	width: '80%',
		// 	//height: '80%',
		// 	contentStyle: {'max-height': '100%', overflow: 'auto','background-color': 'lightgray'}
		// });
		//
		// ref.onClose.subscribe((car: Car) =>{
		// 	if (car) {
		// 		this.messageService.add({severity:'info', summary: 'Car Selected', detail:'Vin:' + car.vin});
		// 	}
		// });
		//Alert message - US3738472
		if (this.mode === 'edit') {
			this.epswHomepageService.getAllData(this.ppapPackageIdentifier).subscribe(partList => {
				this.ppapPackagePartsList = partList;
				if (this.ppapPackagePartsList.length >= 1) {
					this.displayMessage = 'The highlighted parts does not exists in cmms anymore.Please review and delete them.'
				} else {
					this.displayMessage = '';
				}
			});
		}
	}

	previousPage() {
		this.router.navigate(['/home-page/' + this.ppapPackageIdentifierId]);
	}

	// nextPage() {
	// 	if (this.validateData()) {
	// 		//this.ppapPackageIdentifierDisplay.commodityImg = this.selectedFile;
	// 		this.ppapPackageIdentifierDisplay.pdApprovalRequired = this.pdApprovalRequiredOption;
	// 		this.ppapPackageIdentifierDisplay.pdApproverCdsid = this.approverCdsid.userId;
	// 		this.ppapPackageIdentifierDisplay.pdApproverEmailId = this.approverCdsid.mail;
	// 		this.ppapPackageIdentifierDisplay.pdApproverName = this.approverCdsid.fordDisplayName;
	// 		this.ppapPackageIdentifierDisplay.staApproverCdsid = this.approverCdsidsta.userId;
	// 		this.ppapPackageIdentifierDisplay.staApproverEmailId = this.approverCdsidsta.mail;
	// 		this.ppapPackageIdentifierDisplay.staApproverName = this.approverCdsidsta.fordDisplayName;
	// 		this.digitalPpapPackageService.saveData(this.ppapPackageIdentifierDisplay).subscribe(resp => {
	// 			console.log('Save Data: ' + resp);
	// 			this.ppapPackageIdentifierDisplay = resp;
	// 		});
	// 	}
	// 	this.router.navigate(['/supplierView/'+this.ppapPackageIdentifierId]);
	// }

	// onSave() {
	// 	if (this.validateData()) {
	// 		//this.ppapPackageIdentifierDisplay.commodityImg = this.selectedFile;
	// 		this.ppapPackageIdentifierDisplay.pdApprovalRequired = this.pdApprovalRequiredOption;
	// 		this.ppapPackageIdentifierDisplay.pdApproverCdsid = this.approverCdsid.userId;
	// 		this.ppapPackageIdentifierDisplay.pdApproverEmailId = this.approverCdsid.mail;
	// 		this.ppapPackageIdentifierDisplay.pdApproverName = this.approverCdsid.fordDisplayName;
	// 		this.ppapPackageIdentifierDisplay.staApproverCdsid = this.approverCdsidsta.userId;
	// 		this.ppapPackageIdentifierDisplay.staApproverEmailId = this.approverCdsidsta.mail;
	// 		this.ppapPackageIdentifierDisplay.staApproverName = this.approverCdsidsta.fordDisplayName;
	// 		this.digitalPpapPackageService.saveData(this.ppapPackageIdentifierDisplay).subscribe(resp => {
	// 			console.log('Save Data: ' + resp);
	// 			this.ppapPackageIdentifierDisplay = resp;
	// 		});
	// 	}
	// }

	/**
	 * On updating either Priority or PD-CDSID or STA-CDSID, triggering e-mail
	 */
	onApprovalUpdate() {
		console.log(this.ppapPackageIdentifierDisplayOld);
		this.ppapPackageIdentifierDisplay.ppapPackagePartsEntity = this.ppapPartsArray;
		console.log(this.phaseType)
		for (const data of this.ppapPackageIdentifierDisplay.ppapPackagePartsEntity) {
			if (data.status === 'LEVEL 1 SELF APPROVED' || data.status === 'AWAITING APPROVAL') {
				if (this.pdApprovalRequiredOption !== undefined) {
					this.ppapPackageIdentifierDisplayNew.pdApprovalRequired = this.pdApprovalRequiredOption === 'Yes' ? '1' : '0';
				}
				if (this.pdApprovalRequiredOption === 'Yes') {
					this.ppapPackageIdentifierDisplayNew.pdApproverCdsid = this.approverCdsid.userId;
					this.ppapPackageIdentifierDisplayNew.pdApproverEmailId = this.approverCdsid.mail;
					this.ppapPackageIdentifierDisplayNew.pdApproverName = this.approverCdsid.fordDisplayName;
				} else {
					this.ppapPackageIdentifierDisplayNew.pdApproverCdsid = null;
					this.ppapPackageIdentifierDisplayNew.pdApproverEmailId = null;
					this.ppapPackageIdentifierDisplayNew.pdApproverName = null;
				}
				this.ppapPackageIdentifierDisplayNew.staApproverCdsid = this.approverCdsidsta.userId;
				this.ppapPackageIdentifierDisplayNew.staApproverEmailId = this.approverCdsidsta.mail;
				this.ppapPackageIdentifierDisplayNew.staApproverName = this.approverCdsidsta.fordDisplayName;
			}
		}
		if (this.approverCdsidsta !== null && this.approverCdsidsta.userId !== undefined && this.approverCdsidsta.userId.length !== 0) {
			if (this.pdApprovalRequiredOption === 'Yes') {
				if ((this.approverCdsid !== null && this.approverCdsid.userId !== undefined && this.approverCdsid.userId.length !== 0)){
					console.log(this.ppapPackageIdentifierDisplayNew);
					if(this.ppapPackageIdentifierDisplayOld.pdApprovalRequired === 'false'){
						this.ppapPackageIdentifierDisplayOld.pdApprovalRequired = '0';
					} else if(this.ppapPackageIdentifierDisplayOld.pdApprovalRequired === 'true'){
						this.ppapPackageIdentifierDisplayOld.pdApprovalRequired = '1';
					}
					if(this.ppapPackageIdentifierDisplayOld.pdApprovalRequired === '0'){
						this.ppapPackageIdentifierDisplayOld.pdApprovalRequired = 'false';
					} else if(this.ppapPackageIdentifierDisplayOld.pdApprovalRequired === '1'){
						this.ppapPackageIdentifierDisplayOld.pdApprovalRequired = 'true';
					}
					if(this.ppapPackageIdentifierDisplayNew.pdApprovalRequired === 'false'){
						this.ppapPackageIdentifierDisplayNew.pdApprovalRequired = '0';
					} else if(this.ppapPackageIdentifierDisplayNew.pdApprovalRequired === 'true'){
						this.ppapPackageIdentifierDisplayNew.pdApprovalRequired = '1';
					}
					if(this.ppapPackageIdentifierDisplayNew.pdApprovalRequired === '0'){
						this.ppapPackageIdentifierDisplayNew.pdApprovalRequired = 'false';
					} else if(this.ppapPackageIdentifierDisplayNew.pdApprovalRequired === '1'){
						this.ppapPackageIdentifierDisplayNew.pdApprovalRequired = 'true';
					}
					if (this.ppapPackageIdentifierDisplayOld.pdApproverCdsid !== this.ppapPackageIdentifierDisplayNew.pdApproverCdsid ||
						this.ppapPackageIdentifierDisplayOld.staApproverCdsid !== this.ppapPackageIdentifierDisplayNew.staApproverCdsid ||
						this.ppapPackageIdentifierDisplayOld.pdApprovalRequired !== this.ppapPackageIdentifierDisplayNew.pdApprovalRequired) {
						this.entityList = [];
						this.entityList.push(this.ppapPackageIdentifierDisplayOld);
						this.entityList.push(this.ppapPackageIdentifierDisplayNew);
						this.digitalPpapPackageService.updateMailPriorityApprover(this.entityList, true).subscribe(resp => {
							console.log(resp);
							// this.ppapPackageIdentifierDisplayOld=this.ppapPackageIdentifierDisplayNew;
							this.messageService.add({
								severity: 'success',
								detail: 'Email sent Successfully'
							});
							if (this.pdApprovalRequiredOption !== undefined) {
								this.ppapPackageIdentifierDisplayOld.pdApprovalRequired = this.pdApprovalRequiredOption === 'Yes' ? '1' : '0';
							}
							if (this.pdApprovalRequiredOption === 'Yes') {
								this.ppapPackageIdentifierDisplayOld.pdApproverCdsid = this.approverCdsid.userId;
								this.ppapPackageIdentifierDisplayOld.pdApproverEmailId = this.approverCdsid.mail;
								this.ppapPackageIdentifierDisplayOld.pdApproverName = this.approverCdsid.fordDisplayName;
							} else {
								this.ppapPackageIdentifierDisplayOld.pdApproverCdsid = null;
								this.ppapPackageIdentifierDisplayOld.pdApproverEmailId = null;
								this.ppapPackageIdentifierDisplayOld.pdApproverName = null;
							}
							this.ppapPackageIdentifierDisplayOld.staApproverCdsid = this.approverCdsidsta.userId;
							this.ppapPackageIdentifierDisplayOld.staApproverEmailId = this.approverCdsidsta.mail;
							this.ppapPackageIdentifierDisplayOld.staApproverName = this.approverCdsidsta.fordDisplayName;
						});
					}
				}
			} else if(this.pdApprovalRequiredOption === 'No'){
				console.log(this.ppapPackageIdentifierDisplayNew);
				if(this.ppapPackageIdentifierDisplayOld.pdApprovalRequired === 'false'){
					this.ppapPackageIdentifierDisplayOld.pdApprovalRequired = '0';
				} else if(this.ppapPackageIdentifierDisplayOld.pdApprovalRequired === 'true'){
					this.ppapPackageIdentifierDisplayOld.pdApprovalRequired = '1';
				}
				if(this.ppapPackageIdentifierDisplayOld.pdApprovalRequired === '0'){
					this.ppapPackageIdentifierDisplayOld.pdApprovalRequired = 'false';
				} else if(this.ppapPackageIdentifierDisplayOld.pdApprovalRequired === '1'){
					this.ppapPackageIdentifierDisplayOld.pdApprovalRequired = 'true';
				}
				if(this.ppapPackageIdentifierDisplayNew.pdApprovalRequired === 'false'){
					this.ppapPackageIdentifierDisplayNew.pdApprovalRequired = '0';
				} else if(this.ppapPackageIdentifierDisplayNew.pdApprovalRequired === 'true'){
					this.ppapPackageIdentifierDisplayNew.pdApprovalRequired = '1';
				}
				if(this.ppapPackageIdentifierDisplayNew.pdApprovalRequired === '0'){
					this.ppapPackageIdentifierDisplayNew.pdApprovalRequired = 'false';
				} else if(this.ppapPackageIdentifierDisplayNew.pdApprovalRequired === '1'){
					this.ppapPackageIdentifierDisplayNew.pdApprovalRequired = 'true';
				}
				if (this.ppapPackageIdentifierDisplayOld.pdApproverCdsid !== this.ppapPackageIdentifierDisplayNew.pdApproverCdsid ||
					this.ppapPackageIdentifierDisplayOld.staApproverCdsid !== this.ppapPackageIdentifierDisplayNew.staApproverCdsid ||
					this.ppapPackageIdentifierDisplayOld.pdApprovalRequired !== this.ppapPackageIdentifierDisplayNew.pdApprovalRequired) {
					this.entityList = [];
					this.entityList.push(this.ppapPackageIdentifierDisplayOld);
					this.entityList.push(this.ppapPackageIdentifierDisplayNew);
					this.digitalPpapPackageService.updateMailPriorityApprover(this.entityList, true).subscribe(resp => {
						console.log(resp);
						// this.ppapPackageIdentifierDisplayOld=this.ppapPackageIdentifierDisplayNew;
						this.messageService.add({
							severity: 'success',
							detail: 'Email sent Successfully'
						});
						if (this.pdApprovalRequiredOption !== undefined) {
							this.ppapPackageIdentifierDisplayOld.pdApprovalRequired = this.pdApprovalRequiredOption === 'Yes' ? '1' : '0';
						}
						if (this.pdApprovalRequiredOption === 'Yes') {
							this.ppapPackageIdentifierDisplayOld.pdApproverCdsid = this.approverCdsid.userId;
							this.ppapPackageIdentifierDisplayOld.pdApproverEmailId = this.approverCdsid.mail;
							this.ppapPackageIdentifierDisplayOld.pdApproverName = this.approverCdsid.fordDisplayName;
						} else {
							this.ppapPackageIdentifierDisplayOld.pdApproverCdsid = null;
							this.ppapPackageIdentifierDisplayOld.pdApproverEmailId = null;
							this.ppapPackageIdentifierDisplayOld.pdApproverName = null;
						}
						this.ppapPackageIdentifierDisplayOld.staApproverCdsid = this.approverCdsidsta.userId;
						this.ppapPackageIdentifierDisplayOld.staApproverEmailId = this.approverCdsidsta.mail;
						this.ppapPackageIdentifierDisplayOld.staApproverName = this.approverCdsidsta.fordDisplayName;
					});
				}
			}
		}
	}

	onApprovalSave() {
		this.ppapPackageIdentifierDisplay.ppapPackagePartsEntity = this.ppapPartsArray;
		if(this.pdApprovalRequiredOption === 'Yes') {
			if (this.approverCdsid !== undefined) {
				if (this.approverCdsid === null || this.approverCdsid.userId === undefined ||
					this.approverCdsid.userId === null || this.approverCdsid.userId.length === 0) {
					this.messageService.add({
						severity: 'info',
						summary: 'Please fill the Parallel Approver PD CDSID',
						life: 8000
					});
				}
			}
		}
		if (this.approverCdsidsta !== undefined) {
			if (this.approverCdsidsta === null || this.approverCdsidsta.userId === undefined ||
				this.approverCdsidsta.userId === null || this.approverCdsidsta.userId.length === 0) {
				this.messageService.add({
					severity: 'info',
					summary: 'Please fill the Parallel Approver STA CDSID',
					life: 8000
				});
			}
		}
		if (this.phaseType !== 'Phase0') {
			for (const data of this.ppapPackageIdentifierDisplay.ppapPackagePartsEntity) {
				if(data.status === 'LEVEL 1 SELF APPROVED' || data.status === 'AWAITING APPROVAL') {
					if (this.pdApprovalRequiredOption === 'No') {
						if (this.ppapLevelParts === '1') {
							console.log('test'+this.pdStaApprovalRequiredL1);
							if (this.pdStaApprovalRequiredL1) {
								data.overallPpapStatus = 'AWAITING APPROVAL';
								data.overallPswStatus = 'AWAITING APPROVAL';
								data.status = 'AWAITING APPROVAL';
							} else {
								data.overallPpapStatus = 'LEVEL 1 SELF APPROVED';
								data.overallPswStatus = 'LEVEL 1 SELF APPROVED';
								data.status = 'LEVEL 1 SELF APPROVED';
							}
						} else {
							data.overallPpapStatus = 'AWAITING APPROVAL';
							data.overallPswStatus = 'AWAITING APPROVAL';
							data.status = 'AWAITING APPROVAL';
						}
					} else {
						data.overallPpapStatus = 'AWAITING APPROVAL';
						data.overallPswStatus = 'AWAITING APPROVAL';
						data.status = 'AWAITING APPROVAL';
					}
				}
			}
		}
		if (this.validateData()) {
			console.log('Inside this.validateData')
			if (this.pswSupplier.get('supplierAccess')) {
				// Add alert to ppap level
				if (this.pdApprovalRequiredOption === 'Yes') {
					// this.confirmationService.confirm({
					// 	message: 'setting the priority status to "YES" will automatically update the ppap
					// 	level to "5" for all the parts in the PPAP Package.',
					// 	header: 'Confirm',
					// 	icon: 'pi pi-exclamation-triangle',
					// 	accept: () => {
					// 	}
					// });

					let i;
					for (i = 0; i < this.ppapPackageIdentifierDisplay.ppapPackagePartsEntity.length; i++) {
						this.ppapPackageIdentifierDisplay.ppapPackagePartsEntity[i].ppapLevel = this.currentSiteCodePPAPLevel;
					}
					if (this.pdApprovalRequiredOption === 'Yes') {
						console.log(this.approverCdsid + ',' + this.approverCdsid.userId);
						console.log(this.approverCdsidsta + ',' + this.approverCdsidsta.userId);
						if ((this.approverCdsid !== null && this.approverCdsid.userId !== undefined && this.approverCdsid.userId.length !== 0) &&
							(this.approverCdsidsta !== null && this.approverCdsidsta.userId !== undefined && this.approverCdsidsta.userId.length !== 0)) {
							this.ppapPackageIdentifierDisplay.pdApprovalRequired = this.pdApprovalRequiredOption === 'Yes' ? '1' : '0';
							this.ppapPackageIdentifierDisplay.pdApproverCdsid = this.approverCdsid.userId;
							this.ppapPackageIdentifierDisplay.pdApproverEmailId = this.approverCdsid.mail;
							this.ppapPackageIdentifierDisplay.pdApproverName = this.approverCdsid.fordDisplayName;
							this.ppapPackageIdentifierDisplay.staApproverCdsid = this.approverCdsidsta.userId;
							this.ppapPackageIdentifierDisplay.staApproverEmailId = this.approverCdsidsta.mail;
							this.ppapPackageIdentifierDisplay.staApproverName = this.approverCdsidsta.fordDisplayName;
							this.ppapPackageIdentifierDisplay.familyPriorityStatus = this.pdApprovalRequiredOption;
							this.digitalPpapPackageService.updateData(this.ppapPackageIdentifierDisplay).subscribe(resp => {
								this.ppapPackageIdentifierDisplay = resp;
								this.messageService.add({
									severity: 'success',
									detail: 'Parallel Approver ID Saved Successfully'
								});
							});
							this.cdsidSavedFlag=true;
						}
					} else {
						if (this.approverCdsidsta.userId !== '') {
							this.ppapPackageIdentifierDisplay.pdApprovalRequired = this.pdApprovalRequiredOption === 'Yes' ? '1' : '0';
							// this.ppapPackageIdentifierDisplay.staApproverCdsid = this.approverCdsidsta;
							this.ppapPackageIdentifierDisplay.staApproverCdsid = this.approverCdsidsta.userId;
							this.ppapPackageIdentifierDisplay.staApproverEmailId = this.approverCdsidsta.mail;
							this.ppapPackageIdentifierDisplay.staApproverName = this.approverCdsidsta.fordDisplayName;
							this.ppapPackageIdentifierDisplay.pdApproverCdsid = null;
							this.ppapPackageIdentifierDisplay.pdApproverName = null;
							this.ppapPackageIdentifierDisplay.pdApproverEmailId = null;
							this.approverCdsid = null;
							this.ppapPackageIdentifierDisplay.familyPriorityStatus = this.pdApprovalRequiredOption;
							this.digitalPpapPackageService.updateData(this.ppapPackageIdentifierDisplay).subscribe(resp => {
								this.ppapPackageIdentifierDisplay = resp;
								this.messageService.add({
									severity: 'success',
									detail: 'Parallel Approver ID Saved Successfully'
								});
							});
							this.cdsidSavedFlag=true;
						}
					}
				}
				if (this.pdApprovalRequiredOption !== undefined && this.pdApprovalRequiredOption !== 'Yes') {
					// this.confirmationService.confirm({
					// 	message: 'setting the priority status to "NO" will automatically
					// 	reset the PPAP level to the default value from the
					// 	sim for all the parts in the PPAP Package.',
					// 	header: 'Confirm',
					// 	icon: 'pi pi-exclamation-triangle',
					// 	accept: () => {
					//
					//
					// 	}
					// });
					let c;
					for (c = 0; c < this.ppapPackageIdentifierDisplay.ppapPackagePartsEntity.length; c++) {
						this.ppapPackageIdentifierDisplay.ppapPackagePartsEntity[c].ppapLevel = this.currentSiteCodePPAPLevel;
					}
					if (this.pdApprovalRequiredOption === 'Yes') {
						console.log(this.approverCdsid + ',' + this.approverCdsid.userId);
						console.log(this.approverCdsidsta + ',' + this.approverCdsidsta.userId);
						if ((this.approverCdsid !== null && this.approverCdsid.userId !== undefined && this.approverCdsid.userId.length !== 0) &&
							(this.approverCdsidsta !== null && this.approverCdsidsta.userId !== undefined && this.approverCdsidsta.userId.length !== 0)) {
							this.ppapPackageIdentifierDisplay.pdApprovalRequired = this.pdApprovalRequiredOption === 'Yes' ? '1' : '0';
							this.ppapPackageIdentifierDisplay.pdApproverCdsid = this.approverCdsid.userId;
							this.ppapPackageIdentifierDisplay.pdApproverEmailId = this.approverCdsid.mail;
							this.ppapPackageIdentifierDisplay.pdApproverName = this.approverCdsid.fordDisplayName;
							this.ppapPackageIdentifierDisplay.staApproverCdsid = this.approverCdsidsta.userId;
							this.ppapPackageIdentifierDisplay.staApproverEmailId = this.approverCdsidsta.mail;
							this.ppapPackageIdentifierDisplay.staApproverName = this.approverCdsidsta.fordDisplayName;
							this.ppapPackageIdentifierDisplay.familyPriorityStatus = this.pdApprovalRequiredOption;
							this.digitalPpapPackageService.updateData(this.ppapPackageIdentifierDisplay).subscribe(resp => {
								this.ppapPackageIdentifierDisplay = resp;
								this.messageService.add({
									severity: 'success',
									detail: 'Parallel Approver ID Saved Successfully'
								});
							});
							this.cdsidSavedFlag=true;
						}
					} else {
						if (this.approverCdsidsta.userId !== '') {
							this.ppapPackageIdentifierDisplay.pdApprovalRequired = this.pdApprovalRequiredOption === 'Yes' ? '1' : '0';
							// this.ppapPackageIdentifierDisplay.staApproverCdsid = this.approverCdsidsta;
							this.ppapPackageIdentifierDisplay.staApproverCdsid = this.approverCdsidsta.userId;
							this.ppapPackageIdentifierDisplay.staApproverEmailId = this.approverCdsidsta.mail;
							this.ppapPackageIdentifierDisplay.staApproverName = this.approverCdsidsta.fordDisplayName;
							this.ppapPackageIdentifierDisplay.pdApproverCdsid = null;
							this.ppapPackageIdentifierDisplay.pdApproverName = null;
							this.ppapPackageIdentifierDisplay.pdApproverEmailId = null;
							this.approverCdsid = null;
							this.ppapPackageIdentifierDisplay.familyPriorityStatus = this.pdApprovalRequiredOption;
							this.digitalPpapPackageService.updateData(this.ppapPackageIdentifierDisplay).subscribe(resp => {
								this.ppapPackageIdentifierDisplay = resp;
								this.messageService.add({
									severity: 'success',
									detail: 'Parallel Approver ID Saved Successfully'
								});
							});
							this.cdsidSavedFlag=true;
						}
					}
				}
			}
			if (this.pdApprovalRequiredOption === 'Yes') {
				if (this.pswApproverAccessControl.get('pdApprovalConditionsEnabled')) {
					if ((this.approverCdsid !== null && this.approverCdsid.userId !== undefined && this.approverCdsid.userId.length !== 0) &&
						(this.approverCdsidsta !== null && this.approverCdsidsta.userId !== undefined && this.approverCdsidsta.userId.length !== 0)) {
						this.ppapPackageIdentifierDisplay.pdApprovalRequired = this.pdApprovalRequiredOption === 'Yes' ? '1' : '0';
						this.ppapPackageIdentifierDisplay.pdApproverCdsid = this.approverCdsid.userId;
						this.ppapPackageIdentifierDisplay.pdApproverEmailId = this.approverCdsid.mail;
						this.ppapPackageIdentifierDisplay.pdApproverName = this.approverCdsid.fordDisplayName;
						this.ppapPackageIdentifierDisplay.staApproverCdsid = this.approverCdsidsta.userId;
						this.ppapPackageIdentifierDisplay.staApproverEmailId = this.approverCdsidsta.mail;
						this.ppapPackageIdentifierDisplay.staApproverName = this.approverCdsidsta.fordDisplayName;
						this.ppapPackageIdentifierDisplay.familyPriorityStatus = this.pdApprovalRequiredOption;
						this.digitalPpapPackageService.updateData(this.ppapPackageIdentifierDisplay).subscribe(resp => {
							this.ppapPackageIdentifierDisplay = resp;
							this.messageService.add({
								severity: 'success',
								detail: 'Parallel Approver ID Saved Successfully'
							});
							this.cdsidSavedFlag = true;
							if (this.approverCdsid.userId !== this.userId) {
								this.router.navigate(['/home-page']);
							}
						});
					}
				} else if (this.pswApproverAccessControl.get('staApprovalConditionsEnabled')) {
					if ((this.approverCdsid !== null && this.approverCdsid.userId !== undefined && this.approverCdsid.userId.length !== 0) &&
						(this.approverCdsidsta !== null && this.approverCdsidsta.userId !== undefined && this.approverCdsidsta.userId.length !== 0)) {
						this.ppapPackageIdentifierDisplay.pdApprovalRequired = this.pdApprovalRequiredOption === 'Yes' ? '1' : '0';
						this.ppapPackageIdentifierDisplay.pdApproverCdsid = this.approverCdsid.userId;
						this.ppapPackageIdentifierDisplay.pdApproverEmailId = this.approverCdsid.mail;
						this.ppapPackageIdentifierDisplay.pdApproverName = this.approverCdsid.fordDisplayName;
						this.ppapPackageIdentifierDisplay.staApproverCdsid = this.approverCdsidsta.userId;
						this.ppapPackageIdentifierDisplay.staApproverEmailId = this.approverCdsidsta.mail;
						this.ppapPackageIdentifierDisplay.staApproverName = this.approverCdsidsta.fordDisplayName;
						this.ppapPackageIdentifierDisplay.familyPriorityStatus = this.pdApprovalRequiredOption;
						this.digitalPpapPackageService.updateData(this.ppapPackageIdentifierDisplay).subscribe(resp => {
							this.ppapPackageIdentifierDisplay = resp;
							this.messageService.add({
								severity: 'success',
								detail: 'Parallel Approver ID Saved Successfully'
							});
							this.cdsidSavedFlag = true;
							if (this.approverCdsidsta.userId !== this.userId) {
								this.router.navigate(['/home-page']);
							}
						});
					}

				}

			} else {
				if (this.pswApproverAccessControl.get('staApprovalConditionsEnabled') || this.pswApproverAccessControl.get('pdApprovalConditionsEnabled')) {
					if ((this.approverCdsidsta !== null && this.approverCdsidsta.userId !== undefined && this.approverCdsidsta.userId.length !== 0)) {
						this.ppapPackageIdentifierDisplay.pdApprovalRequired = this.pdApprovalRequiredOption === 'Yes' ? '1' : '0';
						// this.ppapPackageIdentifierDisplay.staApproverCdsid = this.approverCdsidsta;
						this.ppapPackageIdentifierDisplay.staApproverCdsid = this.approverCdsidsta.userId;
						this.ppapPackageIdentifierDisplay.staApproverEmailId = this.approverCdsidsta.mail;
						this.ppapPackageIdentifierDisplay.staApproverName = this.approverCdsidsta.fordDisplayName;
						this.ppapPackageIdentifierDisplay.pdApproverCdsid = null;
						this.ppapPackageIdentifierDisplay.pdApproverName = null;
						this.ppapPackageIdentifierDisplay.pdApproverEmailId = null;
						this.approverCdsid = null;
						this.ppapPackageIdentifierDisplay.familyPriorityStatus = this.pdApprovalRequiredOption;
						this.digitalPpapPackageService.updateData(this.ppapPackageIdentifierDisplay).subscribe(resp => {
							this.ppapPackageIdentifierDisplay = resp;
							this.messageService.add({
								severity: 'success',
								detail: 'Parallel Approver ID Saved Successfully'
							});
							this.cdsidSavedFlag = true;
							if (this.approverCdsidsta.userId !== this.userId) {
								this.router.navigate(['/home-page']);
							}
						});
					}
				}
			}
		}
	}

	fetchAndUpdateCDSIDInfo(approverCdsidstaUserId: string, type: string = 'STA') {
		console.log('fetchAndUpdateCDSIDInfo start ' + approverCdsidstaUserId);
		this.digitalPpapPackageService.searchFordBuyer(approverCdsidstaUserId).subscribe(ldapResponse => {
			console.log('ldapResponse');
			this.ldapResponse = ldapResponse;
			for (const data of this.ldapResponse) {
				console.log('data.userId  : ' + data.userId);
				console.log('approverCdsidsta.userId  : ' + approverCdsidstaUserId);
				if (data.userId === approverCdsidstaUserId.toLowerCase()) {
					this.approverCdsidsta.mail = data.mail;
					this.approverCdsidsta.fordDisplayName = data.fordDisplayName;
				}

			}
			this.fordBuyerSuggestions = this.buildDisplayName(ldapResponse);
		}, (err) => {
			// this.approverCdsidsta = null;
			// this.showLdapFailureWarningMessage(approverCdsidstaUserId, type)

		});
		console.log('fetchAndUpdateCDSIDInfo end');
	}

	/**
	 *
	 * @param approverCdsidstaUserId - CDSID to searched in ldap lookup
	 * @param type - Field name
	 * Based on the field name and user type
	 * For supplier different type of messages shown.
	 */
	showLdapFailureWarningMessage(approverCdsidstaUserId: string, type: string) {
		let message = approverCdsidstaUserId + ' may have left the Ford Organization. Please update the ' + type + ' CDSID in Priority Selection.'
		const apsAttributes: any = sessionStorage.getItem('attributes');
		const userRole: string = JSON.parse(apsAttributes).userRole;
		let pswAccessControl: Map<string, any> = new Map<string, any>();
		pswAccessControl = RoleBasedAccessProviderService.getAccessForSupplierRole(userRole);
		if (!pswAccessControl.get('isSupplier')) {
			message = approverCdsidstaUserId + ' may have left the Ford Organization. Please contact supplier to update the CDSID.'
		}
		this.messageService.add({
			severity: 'warn',
			summary: '',
			detail: message,
		});
	}

	private validateData() {
		let isValid = true;
		// if (!this.pdApprovalRequiredOption) {
		// 	isValid = this.showPdApprovalRequiredInvalidErrorMsg();
		// }
		if (this.pdApprovalRequiredOption === undefined || this.pdApprovalRequiredOption ==='') {
			isValid = this.showSelectedPriorityInvalidErrorMsg();
		}
		if (this.approverCdsid === undefined && this.pdApprovalRequiredOption === 'Yes') {
			isValid = this.showSelectedCDSIDInvalidErrorMsg();
		}
		if (this.approverCdsidsta === undefined && this.pdApprovalRequiredOption === 'Yes') {
			isValid = this.showSelectedCDSIDSTAInvalidErrorMsg();
		}
		if (this.approverCdsidsta === undefined && this.pdApprovalRequiredOption === 'No') {
			isValid = this.showSelectedCDSIDSTAInvalidErrorMsg();
		}
		return isValid;
	}

	// private showPdApprovalRequiredInvalidErrorMsg(): boolean  {
	// 	this.messageService.add({key: 'ppapPackageToast',severityetch: 'error',summary: 'Error',
	// 		detail: 'Please Fill the Required Fields!', life: 5000});
	// 	// this.pushErrorMsg('Please select yes or no Option for PD Approval Required');
	// 	return false;
	// }
	private showSelectedCDSIDInvalidErrorMsg(): boolean {
		this.messageService.add({
			key: 'ppapPackageToast', severity: 'error', summary: 'Error',
			detail: 'Please input your CDSID', life: 5000
		});
		return false;
	}

	private showSelectedCDSIDSTAInvalidErrorMsg(): boolean {
		this.messageService.add({
			key: 'ppapPackageToast', severity: 'error', summary: 'Error',
			detail: 'Please input your CDSIDSTA', life: 5000
		});
		return false;
	}

	getColor(mycolor: string): string {
		switch (mycolor) {
			case '-/-':
				return 'white';
			case 'OK':
				return 'lightgreen';
			case 'N/A':
				return 'yellow';
			case 'NOK':
				return 'red';

		}
	}

	ppapElementQuestionSave() {
		let commentNotFilled: boolean;
		for (const data of this.ppapElementRecords) {
			if (data.staPdStatus === 'REJECTED' || data.staPdStatus === 'NEEDS CLARIFICATION') {
				if (data.staPdRemark !== null) {
					if (data.staPdRemark.trim().length !== 0) {
						commentNotFilled = false;
					} else {
						commentNotFilled = true;
						break;
					}
				} else {
					commentNotFilled = true;
					break;
				}
			}
		}
		if (!commentNotFilled) {
			this.ppapElementSaveCommentCheck();
		} else {
			this.messageService.add({severity: 'error', detail: 'Please provide STA/PD Remarks'});
		}
	}

	ppapElementSaveCommentCheck() {
		this.savePpapElementComment();
	}

	savePpapElementComment() {
		this.questionIdListForResp = [];
		console.log('PpapElementRecords :' + this.ppapElementRecords);
		let okCount = 0;
		let notOkCount = 0;
		let naCount = 0;
		let needAnswer = 0;
		let ppapElementRecordsBefore: PpapElementRecords;
		if (this.pswApproverAccessControl.get('approverConditionEnabled')) {
			for (const data of this.ppapElementRecords) {
				if (data.staPdStatus === 'REJECTED' || data.staPdStatus === 'NEEDS CLARIFICATION'
					|| (data.staPdRemark !== null && data.staPdRemark.length !== 0)) {
					data.staPdStatusBy = sessionStorage.getItem('userId');
					data.staPdStatusOn = new Date();
				} else if (data.staPdRemark === null || data.staPdRemark === '') {
					data.staPdRemark = null;
					data.staPdStatusBy = null;
					data.staPdStatusOn = null;
				}
			}
		}
		if (this.ppapElementRecords !== undefined && this.ppapElementRecords.length > 0) {
			for (ppapElementRecordsBefore of this.ppapElementRecords) {
				if (this.pswSupplier.get('supplierAccess')) {
					if (ppapElementRecordsBefore.lastUpdatedBy !== sessionStorage.getItem('userId')) {
						ppapElementRecordsBefore.lastUpdatedBy = sessionStorage.getItem('userId');
						ppapElementRecordsBefore.lastUpdatedOn = new Date();
					} else {
						ppapElementRecordsBefore.lastUpdatedBy = ppapElementRecordsBefore.lastUpdatedBy;
						ppapElementRecordsBefore.lastUpdatedOn = new Date();
					}
				}
				this.ppapElementTotalRecords.push({
					ppapPackageAssessmentResponseEntityID: ppapElementRecordsBefore.ppapPackageAssessmentResponseEntityID,
					ppapPackageIdentifierID: this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID,
					sectionId: ppapElementRecordsBefore.sectionId,
					questionId: ppapElementRecordsBefore.questionId,
					sectionName: ppapElementRecordsBefore.sectionName,
					sectionSortSequence: ppapElementRecordsBefore.sectionSortSequence,
					questionName: ppapElementRecordsBefore.questionName,
					questionSortSequence: ppapElementRecordsBefore.questionSortSequence,
					status: ppapElementRecordsBefore.status,
					document: ppapElementRecordsBefore.document,
					proprietary: ppapElementRecordsBefore.proprietary,
					supplierRemark: ppapElementRecordsBefore.supplierRemark,
					lastUpdatedBy: ppapElementRecordsBefore.lastUpdatedBy,
					lastUpdatedOn: ppapElementRecordsBefore.lastUpdatedOn,
					staPdStatus: ppapElementRecordsBefore.staPdStatus,
					staPdRemark: ppapElementRecordsBefore.staPdRemark,
					staPdStatusBy: ppapElementRecordsBefore.staPdStatusBy,
					staPdStatusOn: ppapElementRecordsBefore.staPdStatusOn,
					createdBy: ppapElementRecordsBefore.createdBy,
					createdOn: ppapElementRecordsBefore.createdOn
				});
				console.log('Document input : ' + ppapElementRecordsBefore.document);
				if (ppapElementRecordsBefore.status === 'OK') {
					okCount++;
				}
				if (ppapElementRecordsBefore.status === 'NOK') {
					notOkCount++;
				}
				if (ppapElementRecordsBefore.status === 'N/A') {
					naCount++;
				}
				if (ppapElementRecordsBefore.status === null) {
					needAnswer++;
				}
			}
			console.log('Ok Count : ' + okCount);
			if (okCount >= 0) {
				this.chart.data.datasets[1].data[this.ppapElementSelectedIndex] = okCount;
			}
			if (notOkCount >= 0) {
				this.chart.data.datasets[2].data[this.ppapElementSelectedIndex] = notOkCount;
			}
			if (naCount >= 0) {
				this.chart.data.datasets[3].data[this.ppapElementSelectedIndex] = naCount;
			}
			if (needAnswer >= 0) {
				this.chart.data.datasets[0].data[this.ppapElementSelectedIndex] = needAnswer;
			}
			this.digitalPpapPackageService.savePpapElementRecords(this.ppapElementTotalRecords).subscribe(resp => {
				console.log('Save Data: ' + resp);
				// this.ppapElementRecords = resp;
				this.ppapElementTotalRecords = [];
				this.ppapElementRecords = []
				//need clarification and reject status check
				this.digitalPpapPackageService.sectionNCValidation(this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID).subscribe(response => {
					console.log(response);
					this.sectionNC = response;
				});
				// added selected and unselected ppap required while saving ppap questions
				// save only selected and unselected ppap element
				this.digitalPpapPackageService.getPpapElementRequired(this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID).subscribe(repo => {
						console.log('Save Data: ' + repo);
						// if(repo.length>0){
						// 	this.ppapElementSectionbutton =repo
						// }
						// this.ppapElementRecords = resp;
						this.ppapRequiredElements = [];
						this.ppapRequiredElementsResp = repo;
						if (this.ppapRequiredElementsResp.length > 0) {
							this.phaseSectionService.getAllData().subscribe(respe => {
								this.sectionDataList = respe;
								console.log('get PPAP required element via ID');
								// this.requiredElementsSavedFlag = true;
								this.ppapRequiredElements = [];
								for (const section of this.ppapRequiredElementsResp) {
									let requiredFlagPre;
									let sectionName;
									let sectionsList: PpapPhaseSection;
									for (sectionsList of this.sectionDataList) {
										if (sectionsList.sectionId === section.sectionId) {
											sectionName = sectionsList.sectionName;
										}
									}
									if (this.chart.data.datasets[0].data[this.ppapElementSection.indexOf(sectionName)] === 0 &&
										this.chart.data.datasets[1].data[this.ppapElementSection.indexOf(sectionName)] === 0 &&
										this.chart.data.datasets[2].data[this.ppapElementSection.indexOf(sectionName)] === 0 &&
										this.chart.data.datasets[3].data[this.ppapElementSection.indexOf(sectionName)] === 0) {
										requiredFlagPre = 'N';
									} else {
										requiredFlagPre = 'Y';
									}
									this.ppapRequiredElements.push({
										ppapRequiredElementID: section.ppapRequiredElementID,
										ppapPackageIdentifierID: section.ppapPackageIdentifierID,
										sectionId: section.sectionId,
										sectionName: section.sectionName,
										requiredFlag: requiredFlagPre,
										lastUpdatedBy: '',
										lastUpdatedOn: null,
										createdBy: '',
										createdOn: null
									});
								}
								this.digitalPpapPackageService.savePpapElementRequired(this.ppapRequiredElements).subscribe(response => {
									console.log('Save Data: ' + response);
									// this.ppapElementRecords = resp;
									this.ppapRequiredElements = [];
									this.ppapRequiredElementsResp = response;
									// alert('Save completed for PPAP required element')
									this.enableApproverConditions();
									this.messageService.add({
										severity: 'success',
										detail: 'Saved Successfully',
										life: 3000
									});
									/*this.requiredElementsSavedFlag=true;*/
									if (!this.pswSupplier.get('isSupplier')) {
										this.ppapElementEditModeCancel();
									} else {
										this.butIndex = 1;
										document.getElementById('ppapElementChart').scrollIntoView(true);
									}
								}, error => {
									console.log('Error Log ' + error);
								});
							}, error => {
								console.log('Error Log ' + error);
							});
						} else {
							this.phaseSectionService.getAllData().subscribe(respon => {
								this.sectionDataList = respon;
								// let count =0;
								for (const section of this.ppapElementSection) {
									console.log('section Name:' + section);
									let sectionIDPre;
									let sectionNamePre;
									let requiredFlagPre;
									let sectionsList: PpapPhaseSection;
									let phase;
									if (this.phaseType === 'Phase0') {
										phase = 'Phase 0';
									} else if (this.phaseType === 'Phase1') {
										phase = 'Phase 1';
									} else if (this.phaseType === 'Phase2') {
										phase = 'Phase 2';
									} else if (this.phaseType === 'Phase3') {
										phase = 'Phase 3';
									}
									for (sectionsList of this.sectionDataList) {
										if (sectionsList.sectionName === section && sectionsList.phase === phase) {
											sectionIDPre = sectionsList.sectionId;
											sectionNamePre = sectionsList.sectionName;
										}
									}
									if (this.chart.data.datasets[0].data[this.ppapElementSection.indexOf(section)] === 0 &&
										this.chart.data.datasets[1].data[this.ppapElementSection.indexOf(section)] === 0 &&
										this.chart.data.datasets[2].data[this.ppapElementSection.indexOf(section)] === 0 &&
										this.chart.data.datasets[3].data[this.ppapElementSection.indexOf(section)] === 0) {
										requiredFlagPre = 'N';
									} else {
										requiredFlagPre = 'Y';
									}
									this.ppapRequiredElements.push({
										ppapRequiredElementID: null,
										ppapPackageIdentifierID: this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID,
										sectionId: sectionIDPre,
										sectionName: sectionNamePre,
										requiredFlag: requiredFlagPre,
										lastUpdatedBy: '',
										lastUpdatedOn: null,
										createdBy: '',
										createdOn: null
									});
									// count++;
								}
								this.digitalPpapPackageService.savePpapElementRequired(this.ppapRequiredElements).subscribe(resps => {
										console.log('Save Data: ' + resps);
										// this.ppapElementRecords = resp;
										this.ppapRequiredElements = [];
										this.ppapRequiredElementsResp = resps;
										// alert('Save completed for PPAP required element')
										this.messageService.add({
											severity: 'success',
											detail: 'Saved Successfully',
											life: 3000
										});
										/*this.requiredElementsSavedFlag=true;*/
										// this.butIndex = 1;
										document.getElementById('ppapElementChart').scrollIntoView(true);
									}, error => {
										console.log('Error Log ' + error);
									}
								);
							}, error => {
								console.log('Error Log ' + error);
							});
						}
					}, error => {
						console.log('Error Log ' + error);
					}
				);
				this.ppapRequiredElements = [];

				// added selected and unselected ppap required while saving ppap questions
				// 	this.butIndex = 1;
				this.chart.refresh();
			}, error => {
				console.log('Error Log ' + error);
			});
			this.checkFlagPpapRequiredElement().then();
		} else {
			// save only selected and unselected ppap element
			this.digitalPpapPackageService.getPpapElementRequired(this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID).subscribe(repo => {
					console.log('Save Data: ' + repo);
					// if(repo.length>0){
					// 	this.ppapElementSectionbutton =repo
					// }
					// this.ppapElementRecords = resp;
					this.ppapRequiredElements = [];
					this.ppapRequiredElementsResp = repo;
					// alert('Save completed for PPAP required element')
					if (this.ppapRequiredElementsResp.length > 0) {

						this.phaseSectionService.getAllData().subscribe(resp => {
								this.sectionDataList = resp;

								console.log('get PPAP required element via ID');
								this.ppapRequiredElements = [];
								for (const section of this.ppapRequiredElementsResp) {
									let requiredFlagPre;
									let sectionName;
									let sectionsList: PpapPhaseSection;
									for (sectionsList of this.sectionDataList) {
										if (sectionsList.sectionId === section.sectionId) {
											sectionName = sectionsList.sectionName;
										}
									}
									if (this.chart.data.datasets[0].data[this.ppapElementSection.indexOf(sectionName)] === 0 &&
										this.chart.data.datasets[1].data[this.ppapElementSection.indexOf(sectionName)] === 0 &&
										this.chart.data.datasets[2].data[this.ppapElementSection.indexOf(sectionName)] === 0 &&
										this.chart.data.datasets[3].data[this.ppapElementSection.indexOf(sectionName)] === 0) {
										requiredFlagPre = 'N';
									} else {
										requiredFlagPre = 'Y';
									}
									this.ppapRequiredElements.push({
										ppapRequiredElementID: section.ppapRequiredElementID,
										ppapPackageIdentifierID: section.ppapPackageIdentifierID,
										sectionId: section.sectionId,
										sectionName: section.sectionName,
										requiredFlag: requiredFlagPre,
										lastUpdatedBy: '',
										lastUpdatedOn: null,
										createdBy: '',
										createdOn: null
									});
								}
								this.digitalPpapPackageService.savePpapElementRequired(this.ppapRequiredElements).subscribe(response => {
									console.log('Save Data: ' + response);
									// this.ppapElementRecords = resp;
									this.ppapRequiredElements = [];
									this.ppapRequiredElementsResp = response;
									// alert('Save completed for PPAP required element')
									this.messageService.add({severity: 'success', detail: 'Saved Successfully', life: 3000});
									/* this.requiredElementsSavedFlag=true;*/
								}, error => {
									console.log('Error Log ' + error);
								});
							}, error => {
								console.log('Error Log ' + error);
							}
						);

					} else {
						this.phaseSectionService.getAllData().subscribe(resp => {
							this.sectionDataList = resp;
							// let count =0;
							for (const section of this.ppapElementSection) {
								console.log('section Name:' + section);
								let sectionIDPre;
								let sectionNamePre;
								let requiredFlagPre;
								let sectionsList: PpapPhaseSection;
								let phase;
								if (this.phaseType === 'Phase0') {
									phase = 'Phase 0';
								} else if (this.phaseType === 'Phase1') {
									phase = 'Phase 1';
								} else if (this.phaseType === 'Phase2') {
									phase = 'Phase 2';
								} else if (this.phaseType === 'Phase3') {
									phase = 'Phase 3';
								}
								for (sectionsList of this.sectionDataList) {
									if (sectionsList.sectionName === section && sectionsList.phase === phase) {
										sectionIDPre = sectionsList.sectionId;
										sectionNamePre = sectionsList.sectionName;
									}
								}
								if (this.chart.data.datasets[0].data[this.ppapElementSection.indexOf(section)] === 0 &&
									this.chart.data.datasets[1].data[this.ppapElementSection.indexOf(section)] === 0 &&
									this.chart.data.datasets[2].data[this.ppapElementSection.indexOf(section)] === 0 &&
									this.chart.data.datasets[3].data[this.ppapElementSection.indexOf(section)] === 0) {
									requiredFlagPre = 'N';
								} else {
									requiredFlagPre = 'Y';
								}
								this.ppapRequiredElements.push({
									ppapRequiredElementID: null,
									ppapPackageIdentifierID: this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID,
									sectionId: sectionIDPre,
									sectionName: sectionNamePre,
									requiredFlag: requiredFlagPre,
									lastUpdatedBy: '',
									lastUpdatedOn: null,
									createdBy: '',
									createdOn: null
								});
								// count++;
							}
							this.digitalPpapPackageService.savePpapElementRequired(this.ppapRequiredElements).subscribe(resps => {
									console.log('Save Data: ' + resps);
									// this.ppapElementRecords = resp;
									this.ppapRequiredElements = [];
									this.ppapRequiredElementsResp = resps;
									// alert('Save completed for PPAP required element')
									this.messageService.add({severity: 'success', detail: 'Saved Successfully', life: 3000});
									/*this.requiredElementsSavedFlag=true;*/
								}, error => {
									console.log('Error Log ' + error);
								}
							);
						}, error => {
							console.log('Error Log ' + error);
						});
					}
				}, error => {
					console.log('Error Log ' + error);
				}
			);
			this.ppapRequiredElements = [];
			this.checkFlagPpapRequiredElement().then();
		}
	}

	async checkFlagPpapRequiredElement() {
		this.requiredElementsNotSaved = false;
		await this.epswHomepageService.getDataOfSelectedRow(this.ppapPackageIdentifier).subscribe(async resp => {
			this.ppapPackageIdentifierDisplayOne = resp.ppapPackageIdentifierEntity;
			this.ppapPartsArray = this.ppapPackageIdentifierDisplayOne?.ppapPackagePartsEntity;
			console.log(this.ppapPartsArray);
			if (!(this.mode === 'view')) {
				this.ppapPartsArray.forEach(data => {
					this.digitalPpapPackageService.getPartsValueFromCmms(data.noPartPrefix + '-' + data.noPartBase + '-' + data.noPartSuffix).subscribe(response => {
						const da = response.ppapPackageParts;
						if(da.capDt!==null && da.capDt!==undefined) {
							data.capDt = da.capDt;
						}
						if(da.qualDt!==null && da.qualDt!==undefined) {
							data.qualDt = da.qualDt;
						}
						if(da.rarDt!==null && da.rarDt!==undefined) {
							data.rarDt = da.rarDt;
						}
						if(da.prodDt!==null && da.prodDt!==undefined) {
							data.prodDt = da.prodDt;
						}					});
				});
			}
			if (this.ppapPartsArray[0].noPartBase === '1508') {
				this.requiredElementsSavedFlag = true;
			} else {
				await this.digitalPpapPackageService.getPpapElementRequired(this.ppapPackageIdentifier).subscribe(async repo => {
					console.log('Ppap Required Check Data for Sumbit: ' + repo);
					if (repo.length > 0) {
						await this.digitalPpapPackageService.getPackagePpapElementSubmitCheck(this.ppapPackageIdentifier).subscribe(repos => {
								console.log('Ppap Required Response Check Data for Submit: ' + repos);
								if (repos.length >= 1) {
									this.requiredElementsSavedFlag = false;
								} else {
									this.requiredElementsSavedFlag = true;
								}
							}, error => {
								console.log('Error Log ' + error);
							}
						);
					} else if (repo.length <= 0) {
						this.requiredElementsSavedFlag = false;
					}
				}, error => {
					console.log('Error Log ' + error);
				});
			}
		});
	}

	enableApproverConditions() {
		this.digitalPpapPackageService.findAllPpapAssessmentResponse(this.ppapPackageIdentifierId).subscribe(ppapAssessmentResponse => {
			this.ppapAssessmentResponse = ppapAssessmentResponse;
			//Disable Approve Reject, Needs Clarification
			for (const data of this.ppapAssessmentResponse) {
				if (data.staPdStatus === 'REJECTED') {
					this.isElementQuestionRejected = true;
					break;
				} else {
					this.isElementQuestionRejected = false;
				}
			}
			if (!this.isElementQuestionRejected) {
				for (const data of this.ppapAssessmentResponse) {
					if (data.staPdStatus === 'NEEDS CLARIFICATION') {
						this.isElementNeedsClarification = true;
						break;
					} else {
						this.isElementNeedsClarification = false;
					}
				}
			}
		});
	}

	onRowEditInit(products: SubSupplierPpapPackage) {
		(document.getElementById('addRow') as HTMLInputElement).disabled = true;
		this.clonedData[products.subSupplierPpapPackageID] = {...products};
		this.oldSubSupplierCategory = products.subSupplierCategory;
		this.oldSubSupplierName = products.subSupplierName;
		this.oldPartDescription = products.partDescription;
		this.oldAttachment = products.attachment;
		this.editMode = true;
		this.editParts = false;
	}

	addFieldValueForParts() {
		// this.saveForParts = true;
		this.addDataForParts = {
			ppapPackageIdentifierID: '',
			programCode: '',
			noPartPrefix: '',
			noPartBase: '',
			noPartSuffix: '',
			programDescription: '',
			// changeintiming: string;
			rarDt: '',
			// rcheckout: string;
			qualDt: '',
			// qcheckout: string;
			prodDt: '',
			// pcheckout: string;
			capDt: '',
			status: '',
			overallPpapStatus:'',
			ppapLevel: ''
		};
		let packagelist;
		packagelist = this.ppapPartsArray.filter(parts => parts.ppapPackagePartsEntityID === undefined)
		if (packagelist.length === 0) {
			this.ppapPartsArray.unshift(this.addDataForParts);
			this.ppapPartsArray = this.ppapPartsArray;
		} else
			this.ppapPartsArray = this.ppapPartsArray;
	}
	onRowDeleteForParts(ppapParts: PpapPackageParts) {
		this.isLoading = true;
		// @ts-ignore
		this.ppapPartsArray = this.ppapPartsArray.filter(e => e.ppapPackageIdentifierID !== '');
		console.log('PPAP Parts Array Length: ' + this.ppapPartsArray.length);
		if (this.ppapPartsArray.length === 1) {
			if (ppapParts.ppapPackagePartsEntityID !== null && ppapParts.ppapPackagePartsEntityID !== undefined) {
				this.confirmationService.confirm({
					message: 'Deleting this part will remove the PPAP package and PSW details associated with this part ' +
						'and the status will be reset to "Pending" in HomePage.Are you sure you want to delete ???',
					header: 'Confirm',
					icon: 'pi pi-exclamation-triangle',
					accept: () => {
						this.digitalPpapPackageService.deletePackage(ppapParts.ppapPackageIdentifierID).subscribe(resp => {
							// this.ppapPartsArray = this.ppapPartsArray.filter(i => i.ppapPackagePartsEntityID !== resp);
							// this.getAllDataForParts();
							// this.getPrefixBaseSuffixForParts();
							// // this.ppapPartsArrayLength = this.ppapPartsArray.length;
							// (document.getElementById('addRow') as HTMLInputElement).disabled = false;
							this.isLoading = false;
							this.router.navigate(['/home-page/']);
							// if (this.ppapPartsArray.length === 1)
							// 	this.disableDelete = true;
							// else
							// 	this.disableDelete = false;
							// const msg = 'PPAP part '+ppapParts.noPartPrefix+'-'+ppapParts.noPartBase+'-'+ppapParts.noPartSuffix+' is deleted successfully';
							const msg = 'PPAP part is deleted successfully';
							this.messageService.add({
								severity: 'info',
								summary: 'Info',
								detail: msg,
								life: 3000
							});
						});
					},
					reject: () => {
						this.getAllDataForParts();
						this.getPrefixBaseSuffixForParts();
						this.ppapPartsArrayLength = this.ppapPartsArray.length;
						(document.getElementById('addRow') as HTMLInputElement).disabled = false;
						this.isLoading = false;
						this.messageService.add({
							severity: 'info',
							summary: 'Info',
							detail: 'Part list restore into previous status',
							life: 3000
						});
					}
				});
			} else {
				(document.getElementById('addRow') as HTMLInputElement).disabled = false;
				this.isLoading = false;
				this.ppapPartsArray = this.ppapPartsArray.filter(i => i.ppapPackagePartsEntityID !== undefined);
			}
		} else if (this.ppapPartsArray.length !== 1) {
			if (ppapParts.ppapPackagePartsEntityID !== null && ppapParts.ppapPackagePartsEntityID !== undefined) {
				this.confirmationService.confirm({
					message: 'Do you want to delete the selected PPAP part and PSW Details as well (if applicable) ?',
					header: 'Confirm',
					icon: 'pi pi-exclamation-triangle',
					accept: () => {
						this.digitalPpapPackageService.deletePart(ppapParts.ppapPackagePartsEntityID).subscribe(resp => {
							this.ppapPartsArray = this.ppapPartsArray.filter(i => i.ppapPackagePartsEntityID !== resp);
							this.getAllDataForParts();
							this.getPrefixBaseSuffixForParts();
							this.ppapPartsArrayLength = this.ppapPartsArray.length;
							(document.getElementById('addRow') as HTMLInputElement).disabled = false;
							this.isLoading = false;
							// if (this.ppapPartsArray.length === 1)
							// 	this.disableDelete = true;
							// else
							// 	this.disableDelete = false;
							// const msg = 'PPAP part '+ppapParts.noPartPrefix+'-'+ppapParts.noPartBase+'-'+ppapParts.noPartSuffix+' is deleted successfully';
							const msg = 'PPAP part is deleted successfully';
							this.messageService.add({
								severity: 'info',
								summary: 'Info',
								detail: msg,
								life: 3000
							});
							//Alert message - US3738472
							if (this.mode === 'edit') {
								this.epswHomepageService.getAllData(this.ppapPackageIdentifier).subscribe(partList => {
									this.ppapPackagePartsList = partList;
									if (this.ppapPackagePartsList.length >= 1) {
										this.displayMessage = 'The highlighted parts does not exists in cmms anymore.Please review and delete them.'
									} else {
										this.displayMessage = '';
									}
								});
							}
						});
					},
					reject: () => {
						this.getAllDataForParts();
						this.getPrefixBaseSuffixForParts();
						this.ppapPartsArrayLength = this.ppapPartsArray.length;
						(document.getElementById('addRow') as HTMLInputElement).disabled = false;
						this.isLoading = false;
						this.messageService.add({
							severity: 'info',
							summary: 'Info',
							detail: 'Part list restore into previous status',
							life: 3000
						});
					}
				});
			} else {
				(document.getElementById('addRow') as HTMLInputElement).disabled = false;
				this.isLoading = false;
				this.ppapPartsArray = this.ppapPartsArray.filter(i => i.ppapPackagePartsEntityID !== undefined);
			}
		} else {
			this.ppapPartsArrayLength = this.ppapPartsArray.length;
			(document.getElementById('addRow') as HTMLInputElement).disabled = false;
			this.isLoading = false;
			this.messageService.add({
				severity: 'info',
				summary: 'Info',
				detail: 'PPAP Package Should contain at least one Part. '
			});
		}
		this.ppapPartsArrayLength = this.ppapPartsArray.length;
	}

	newRow() {
		return {
			ppapPackageIdentifierID: '',
			ppapPackagePartsEntityID: '',
			programCode: '',
			noPartPrefix: '', noPartBase: '',
			noPartSuffix: '', programDescription: '',
			rarDt: '', qualDt: '',
			prodDt: '', capDt: '', status: '', ppapLevel: '',priorityStatus: '',
			select: ''
		};
	}

	onRowEditInitForParts(ppap: PpapPackageParts) {
		this.clonedParts[ppap.ppapPackagePartsEntityID] = {...ppap};
	}

	onRowEditSaveForParts(prefixBaseSuffix, ppap: PpapPackageParts, partNumber: any, ppapLevels: string) {
		(document.getElementById('addRow') as HTMLInputElement).disabled = false;
		this.isLoading = true;
		// @ts-ignore
		this.ppapPartsArray = this.ppapPartsArray.filter(e => e.ppapPackageIdentifierID !== '');
		// alert('Row papp Level'+ prefixBaseSuffix);
		// @ts-ignore
		if (prefixBaseSuffix !== undefined || (ppap.ppapPackagePartsEntityID !== '' && ppap.ppapPackagePartsEntityID !== null)) {
			// to remove the edit for ppap level so ppap level always undefined
			if (ppapLevels === undefined) {
				//ppap level
				this.ppapLevelParts = this.ppapPartsArray[0].ppapLevel;
				ppapLevels = this.ppapLevelParts;
				if (ppapLevels !== this.ppapLevelParts) {
					this.confirmationService.confirm({
						message: 'Do you want to change the PPAP Level for all parts?',
						header: 'Confirm',
						icon: 'pi pi-exclamation-triangle',
						accept: () => {
							this.ppapPartsArray.forEach(value => value.ppapLevel = ppapLevels);
							this.ppapLevelParts = ppapLevels;
							if (prefixBaseSuffix !== undefined) {
								this.digitalPpapPackageService.getPartsValueFromCmms(prefixBaseSuffix).subscribe(
									resp => {
										this.addPartsCheck = true;
										this.addDataForParts = [];


											this.addDataForParts = resp.ppapPackageParts;

										this.addDataForParts.ppapPackageIdentifierID = this.ppapPackageIdentifierId;
										this.addDataForParts.ppapLevel = this.ppapLevelParts;
										delete this.clonedParts[ppap.ppapPackagePartsEntityID];
										this.digitalPpapPackageService.savePart(this.addDataForParts).subscribe(resps => {
											// console.log(resp);
											this.digitalPpapPackageService.saveParts(this.ppapPartsArray).subscribe(respt => {
												this.ppapPartsArray.shift();
												this.ppapPartsArray.unshift(resps);
												console.log('2735');
												this.getAllDataForParts();
												this.getPrefixBaseSuffixForParts();
												this.partNumbers = undefined;
												this.partPappLevel = undefined;
												this.partDisableEnable = true;
												this.isLoading = false;
												const msg = 'PPAP part is saved successfully.';
												this.messageService.add({
													severity: 'info',
													summary: 'Info',
													detail: msg,
													life: 3000
												});
											});
										});
										// }
									});
							} else {

								this.digitalPpapPackageService.saveParts(this.ppapPartsArray).subscribe(respt => {
									this.ppapPartsArray.shift();
									// this.ppapPartsArray.unshift(resps);
									console.log('2758');
									this.getAllDataForParts();
									console.log('enter pref')
									this.getPrefixBaseSuffixForParts();
									this.partNumbers = undefined;
									this.partPappLevel = undefined;
									this.partDisableEnable = true;
									this.isLoading = false;
									const msg = 'PPAP part is saved successfully.';
									this.messageService.add({
										severity: 'info',
										summary: 'Info',
										detail: msg,
										life: 3000
									});
								});
							}

							this.editParts = true;
							this.ppapPartsArrayLength = this.ppapPartsArray.length;
						},
						reject: () => {
							this.getAllDataForParts();
							this.getPrefixBaseSuffixForParts();
							this.ppapPartsArrayLength = this.ppapPartsArray.length;
							this.partNumbers = undefined;
							this.partPappLevel = undefined;
							this.partDisableEnable = true;
							this.isLoading = false;
							this.messageService.add({
								severity: 'info',
								summary: 'Info',
								detail: 'Part list restore into previous status',
								life: 3000
							});
						}
					});
				} else {
					ppapLevels = this.ppapLevelParts;
					this.ppapPartsArray.forEach(value => value.ppapLevel = this.ppapLevelParts);
					if (prefixBaseSuffix !== undefined) {
						this.digitalPpapPackageService.getPartsValueFromCmms(prefixBaseSuffix).subscribe(
							resp => {
								this.addPartsCheck = true;
								this.addDataForParts = [];

									this.addDataForParts = resp.ppapPackageParts;

								//this.getFlagValue()
								this.ppapPartsArray.forEach(status => {
									this.ppapPrStatusArray.push(status.priorityStatus)
								});
								this.digitalPpapPackageService.getPriorityStatus(this.addDataForParts.programCode, this.ppapPackageIdentifierDisplay.siteCode, this.addDataForParts.noPartBase).subscribe(data => {
									console.log(data.status);
									//data.status = 'Y';
									if (data.status === 'Y') {
										this.addDataForParts.priorityStatus = 'Yes';
									} else if (data.status === 'N') {
										this.addDataForParts.priorityStatus = 'No';
									} else {
										this.addDataForParts.priorityStatus = '';
									}
									this.pStatus = data.status;
									this.addDataForParts.ppapPackageIdentifierID = this.ppapPackageIdentifierId;
									this.addDataForParts.ppapLevel = this.ppapLevelParts;
									delete this.clonedParts[ppap.ppapPackagePartsEntityID];
									console.log('2762', this.ppapPartsArray)
									this.ppapPartsArray.forEach(value => {
										if (value.status === 'REJECTED' || value.status === 'NEEDS CLARIFICATION') {
											this.addDataForParts.status = value.status;
											this.addDataForParts.overallPpapStatus = value.status;
										}
									});

									this.getAllDataForParts();
									// this.ppapPrStatusArray.forEach(st => {
									// 	if(st === 'No' && this.pStatus === null) {
									// 		this.checkingBlank = 'true';
									// 	}
									// 	if(st === 'No' && this.pStatus === 'Y') {
									// 		this.checkingYes = 'true';
									// 	}
									// 	if(st=== 'null' && this.pStatus === 'Y') {
									// 		this.checkingBlank = 'false';
									// 		this.checkingYes = 'true';
									// 	}
									//this.checkingBlank='false';
									//this.checkingYes='false';
									//})
									//this.checkingYes = 'true';
									//this.addDataForParts.priorityStatus = 'Yes';
									//this.ppapPartsArray[0].priorityStatus = 'Yes';
									this.ppapPrStatusArray.forEach(st => {
											if (st === 'Yes') {
												this.checkingNo = 'false';
												this.checkingYes = 'false';
											} else if ((st === null || st === '') && this.pStatus === 'N') {
												this.checkingNo = 'true';

											} else if (st === 'No' && this.pStatus === 'Y') {
												this.checkingYes = 'true';
											} else if ((st === null || st === '') && this.pStatus === 'Y') {
												this.checkingNo = 'false';
												this.checkingYes = 'true';
											} else {
												this.checkingNo = 'false';
												this.checkingYes = 'false';
											}
										})
									if(this.ppapPackageIdentifierDisplay.familyPrioritySavedFlag !== '1') {
										if (this.checkingYes === 'true') {
											this.ppapPackageIdentifierStore.familyPriorityStatus = 'Yes';
											this.ppapPackageIdentifierStore.ppapPackagePartsEntity = this.ppapPackageIdentifierStore.ppapPackagePartsEntity.filter(data => data.ppapPackageIdentifierID >= 1);
											this.ppapPackageIdentifierStore.addOrUpdateParts = false;
											this.epswHomepageService.saveData(this.ppapPackageIdentifierStore).subscribe(resp => {
												this.apqpGlobalPriority = resp.familyPriorityStatus;
												this.ppapPackageIdentifierStore.addOrUpdateParts = false;
												this.pdApprovalRequiredOption = resp.familyPriorityStatus;
												console.log(resp);
											});
											//this.pdApprovalRequiredOption = 'Yes';
											this.decMsg = 'Adding this Part will update the overall Priority Status of the package to Yes ?'
										}

										if (this.checkingNo === 'true') {
											this.ppapPackageIdentifierStore.familyPriorityStatus = 'No';
											console.log(this.ppapPackageIdentifierDisplay);
											this.ppapPackageIdentifierStore.ppapPackagePartsEntity = this.ppapPackageIdentifierStore.ppapPackagePartsEntity.filter(data => data.ppapPackageIdentifierID >= 1);
											console.log(this.ppapPackageIdentifierStore);
											this.ppapPackageIdentifierStore.addOrUpdateParts = false;
											this.epswHomepageService.saveData(this.ppapPackageIdentifierStore).subscribe(resp => {
												this.apqpGlobalPriority = resp.familyPriorityStatus;
												console.log(resp);
												this.ppapPackageIdentifierStore.addOrUpdateParts = false;
												this.pdApprovalRequiredOption = resp.familyPriorityStatus;
											});
											//this.pdApprovalRequiredOption = '';
											this.decMsg = 'Adding this Part will update the overall Priority Status of the package to  No'
										}
										console.log(this.checkingBlank);
									}
									if (this.checkingYes !== undefined || this.checkingNo !== undefined) {
										if ((this.checkingYes === 'true' || this.checkingNo === 'true') && this.ppapPackageIdentifierDisplay.familyPrioritySavedFlag !== '1') {
											this.confirmationService.confirm({
												message: this.decMsg,
												header: 'Confirm',
												icon: 'pi pi-exclamation-triangle',
												accept: () => {
													this.digitalPpapPackageService.savePart(this.addDataForParts).subscribe(resps => {
														this.digitalPpapPackageService.saveParts(this.ppapPartsArray).subscribe(res => {
															console.log('2873');
															this.getAllDataForParts();
															console.log('enter pref')
															this.ppapPartsArray.shift();
															this.ppapPartsArray.unshift(resps);
															this.getPrefixBaseSuffixForParts();
															this.partNumbers = undefined;
															this.partPappLevel = undefined;
															this.partDisableEnable = true;
															this.isLoading = false;
															const msg = 'PPAP part is saved successfully.';
															this.messageService.add({
																severity: 'info',
																summary: 'Info',
																detail: msg,
																life: 3000
															});
														});
													});
												}, reject: () => {
													(document.getElementById('addRow') as HTMLInputElement).disabled = false;
													this.isLoading = false;
												}
											});
										} else {
											this.digitalPpapPackageService.savePart(this.addDataForParts).subscribe(resps => {
												this.digitalPpapPackageService.saveParts(this.ppapPartsArray).subscribe(res => {
													console.log('2900');
													this.getAllDataForParts();
													console.log('enter pref')
													this.ppapPartsArray.shift();
													this.ppapPartsArray.unshift(resps);
													this.getPrefixBaseSuffixForParts();
													this.partNumbers = undefined;
													this.partPappLevel = undefined;
													this.partDisableEnable = true;
													this.isLoading = false;
													const msg = 'PPAP part is saved successfully.';
													this.messageService.add({
														severity: 'info',
														summary: 'Info',
														detail: msg,
														life: 3000
													});
												});
											});
										}
									}
							});});
					} else {
						this.digitalPpapPackageService.saveParts(this.ppapPartsArray).subscribe(respt => {
							console.log('2938');
							this.getAllDataForParts();
							this.ppapPartsArray.shift();
							// this.ppapPartsArray.unshift(resps);
							console.log('enter pref')
							this.getPrefixBaseSuffixForParts();
							this.partNumbers = undefined;
							this.partPappLevel = undefined;
							this.partDisableEnable = true;
							this.isLoading = false;
							const msg = 'PPAP part is saved successfully.';
							this.messageService.add({
								severity: 'info',
								summary: 'Info',
								detail: msg,
								life: 3000
							});
						});

						// const msg = 'PPAP part '+this.addDataForParts.noPartPrefix+'-'+this.addDataForParts.noPartBase+'-'+
						// this.addDataForParts.noPartSuffix + ' is saved successfully.';
						// const msg = 'PPAP part is saved successfully.';
						// this.messageService.add({
						// 	severity: 'info',
						// 	summary: 'Info',
						// 	detail: msg,
						// 	life: 3000
						// });
					}
					this.editParts = true;
					this.ppapPartsArrayLength = this.ppapPartsArray.length;
				}
			} else {
				this.getAllDataForParts();
				this.getPrefixBaseSuffixForParts();
				this.partNumbers = undefined;
				this.partPappLevel = undefined;
				this.partDisableEnable = true;
				this.isLoading = false;
				this.messageService.add({
					severity: 'info',
					summary: 'Info',
					detail: 'Please select PPAP Level.',
					life: 3000
				});
			}
		} else {
			this.getAllDataForParts();
			this.getPrefixBaseSuffixForParts();
			this.partNumbers = undefined;
			this.partPappLevel = undefined;
			this.partDisableEnable = true;
			this.isLoading = false;
			this.messageService.add({
				severity: 'info',
				summary: 'Info',
				detail: 'Please select the PREFIX-BASE-SUFFIX.',
				life: 3000
			});
		}

	}

	getFlagValue() {
		console.log('3028')
		this.digitalPpapPackageService.getPriorityStatus(this.addDataForParts.programCode, this.ppapPackageIdentifierDisplay.siteCode, this.addDataForParts.noPartBase).subscribe(data => {
			console.log(data.status);
			//data.status = 'Y';
			this.addDataForParts.priorityStatus = data.status;
			this.pStatus = data.status;
		});
		this.ppapPartsArray.forEach(data => {
			this.ppapPrStatusArray.push(data.priorityStatus);
		})
		console.log(this.ppapPrStatusArray);
		console.log(this.addDataForParts.siteCode);
		this.getValue(this.ppapPrStatusArray, this.addDataForParts.priorityStatus);
		console.log('end', this.checkingBlank)
	}
	private
	getValue(ppapPrStatusArray: any[], status) {
		console.log('2960')
		//status = 'Y';
		//this.addDataForParts.priorityStatus = 'Yes';
		this.ppapPrStatusArray.forEach(st => {
			if(st === 'No' && status === null) {
				this.checkingBlank = 'true';

			}
			else if(st === 'No' && status === 'Y') {
				this.checkingYes = 'true';
			}
			else if(st=== null && status === 'Y') {
				this.checkingBlank = 'false';
				this.checkingYes = 'true';
			} else {
				this.checkingBlank='false';
				this.checkingYes='false';
			}
		})
		console.log('end getvalue',this.checkingBlank)
	}

	getPrefixBaseSuffixForParts() {
		const basePartList: any[] = [];
		const prgmCodeList: any[] = [];
		this.partNumbersOption = [];
		let runningChange = 'Forward';
		for (const ppapPart of this.ppapPartsArray) {
			if (this.runningChangeProgramCode.includes(ppapPart.programCode)) {
				runningChange = 'Running';
			}
		}
		console.log(runningChange);
		this.digitalPpapPackageService.getPrefixBaseSuffix(this.ppapPackageIdentifierDisplay.siteCode, this.phaseType,
			this.ppapPackageIdentifierDisplay.ppapPhasePlan,
			this.ppapPackageIdentifierDisplay.ppapPhaseCode, sessionStorage.getItem('ppapOption'), runningChange).subscribe(
			resp => {
				let i;
				this.dtOrgList = resp;
				this.partNumbersOption = [];
				for (const ppapPart of this.ppapPartsArray) {
					basePartList.push(ppapPart.noPartBase);
				}
				if (basePartList.includes('1508')) {
					for (const dtOrgList of this.dtOrgList) {
						if (!dtOrgList.includes('1508')) {
							this.dtOrgList = this.dtOrgList.filter(e => e.includes('1508'));
						}
					}
				} else {
					for (const dtOrgList of this.dtOrgList) {
						if (dtOrgList.includes('1508')) {
							this.dtOrgList = this.dtOrgList.filter(e => !e.includes('1508'));
						}
					}
				}
				for (i = 0; i < this.dtOrgList.length; i++) {
					this.partNumbersOption.push({label: this.dtOrgList[i], value: this.dtOrgList[i]});
				}
				if (this.partNumbersOption.length === 0) {
					(document.getElementById('addRow') as HTMLInputElement).disabled = true;
				}
			});
	}

	// getCmmsPartsValue(prefixBaseSuffix) {
	// 	this.digitalPpapPackageService.getPartsValueFromCmms(prefixBaseSuffix).subscribe(
	// 		resp => {
	// 			this.addPartsCheck = true;
	// 			this.addDataForParts = resp;
	// 			this.addDataForParts.ppapPackageIdentifierID = this.ppapPackageIdentifierId;
	// 			this.addDataForParts.ppapLevel = this.ppapLevelParts;
	// 			this.ppapPartsArray.shift();
	// 			this.ppapPartsArray.unshift(this.addDataForParts);
	// 		});
	// }

	checkIfPresent(packageId) {
		let packagelist;
		packagelist = this.ppapPartsArray.filter(parts => parts.ppapPackagePartsEntityID);
		if (packagelist.includes(packageId))
			return true;
		else
			return false;
	}

	getAllDataForParts() {
		this.digitalPpapPackageService.getAllData(this.ppapPackageIdentifierId).subscribe(resp => {
			this.ppapPartsArray = resp.listOfPpapPackageParts;
			//this.getPrStatus(this.ppapPartsArray,this.ppapPackageIdentifier.siteCode);
			// for(const item of this.ppapPartsArray) {
			// 	if(item.priorityStatus === 'Yes') {
			// 		this.pdApprovalRequiredOption = 'Yes';
			//         this.digitalPpapPackageService.updateData(item.priorityStatus,this.ppapPackageIdentifierId);
			// 	}
			// 	else if(item.priorityStatus === ' ') {
			// 		this.pdApprovalRequiredOption = '';
			// 		this.digitalPpapPackageService.updateData(item.priorityStatus,this.ppapPackageIdentifierId);
			// 	}
			// 	else {
			// 		this.digitalPpapPackageService.updateData(item.priorityStatus,this.ppapPackageIdentifierId);
			// 	}
			// }

			console.log(this.ppapPartsArray);
			// this.ppapPartsArray.sort();
			this.ppapPartsArray.sort((a, b) => (a.noPartBase < b.noPartBase ? -1 : 1));
			this.ppapPartsArrayLength = this.ppapPartsArray.length;
		});
		if (this.ppapPartsArray.length === 1)
			this.disableDelete = true;
		else
			this.disableDelete = false;
	}

	getPrStatus(){
		this.ppapPackageIdentifierDisplay.ppapPackagePartsEntity.forEach(data => {
			if(this.ppapPackageIdentifierDisplay.familyPriorityStatus === 'No' && data.priorityStatus === 'Yes') {
							this.displayOveridePriorityStatus = true;
			}
		})
	}

	async ppapElementEditModeCancel() {
		this.butIndex = 1;
		// this.phaseType = this.ppapPackageIdentifierDisplay.ppapPhase;
		document.getElementById('ppapElementChart')?.scrollIntoView(true);
		// Added for US3667076
		let phase ;
		if (this.phaseType === 'Phase2' && this.ppapPartsArray.filter(i => i.phase1Phase2 === 'true' && (i.status ==='APPROVED' || i.status === 'LEVEL 1 SELF APPROVED')).length > 0){
			phase = 'Phase1';
		} else {
			phase = this.phaseType;
		}
		this.epswHomepageService.getPpapElementSection(phase).subscribe(res => {
			console.log('Response for ChartPpapElement : ' + res);
			this.ppapElementSection = res;
			console.log('PPAP Element :' + this.ppapElementSection);
			// this.chart.data.label =this.ppapElementSection;
			// this.ppapElementSectionbutton =[];
			const sectionButton = [];
			for (let i = 0; i < this.ppapElementSection.length; i++) {
				if(this.chart !== undefined && this.chart !== null) {
					this.chart.data.labels[i] = this.ppapElementSection[i];
					sectionButton.push({
						ppapRequiredElementID: null,
						ppapPackageIdentifierID: null,
						sectionId: null,
						sectionName: this.ppapElementSection[i],
						requiredFlag: 'Y',
						lastUpdatedBy: '',
						lastUpdatedOn: null
					});
				}
			}
			this.digitalPpapPackageService.getPpapElementRequired(this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID).subscribe(repo => {
				console.log('get Required Element: ' + repo);
				if (repo.length > 0) {
					this.ppapElementSectionbutton = repo;
				} else {
					this.ppapElementSectionbutton = sectionButton;
				}
			}, error => {
				console.log('Error Log ' + error);
			});
			this.epswHomepageService.getPpapElementQuestion(phase).subscribe(resp => {
				console.log('Response for ChartPpapElement Question: ' + resp);
				this.ppapElementQuestion = resp;
				console.log('PPAP Element Question :' + this.ppapElementQuestion);
				for (let i = 0; i < this.ppapElementQuestion.length; i++) {
					if(this.chart !== undefined && this.chart !== null) {
						this.chart.data.datasets[0].data[i] = this.ppapElementQuestion[i];
					}
				}

				this.digitalPpapPackageService.savePpapElementRequiredStatus(this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID)
					.subscribe(resps => {
							console.log('get Data: ' + resps);
							if(resps != null && resps.length > 0){
								for(const respData of resps){
									console.log('Data : '+ respData);
									if(resps != null && resps.length > 0){
										// if(!respData.includes('N/A') || !respData.includes('OK') || !respData.includes('NOK')){
										// 		// this.requiredElementsSavedFlag=true;
										// 		// break;
										// }
										if(respData.includes(null)){
											this.requiredElementsSavedFlag = false;
											console.log('resp daata has null value')
											break;
										}else{
											this.requiredElementsSavedFlag=true;
										}
									} else {
										// this.requiredElementsSavedFlag=true;
										console.log('insideifElse');
									}
								}
							}

							this.digitalPpapPackageService.sectionNCValidation(this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID).subscribe(response => {
								console.log(response);
								this.sectionNC = response;
								for (const data of this.sectionNC) {
									if (data === 'REJECTED') {
										this.isElementQuestionRejected = true;
										break;
									} else {
										this.isElementQuestionRejected = false;
									}
								}
								if (!this.isElementQuestionRejected) {
									for (const data of this.sectionNC) {
										if (data === 'NEEDS CLARIFICATION') {
											this.isElementNeedsClarification = true;
											break;
										} else {
											this.isElementNeedsClarification = false;
										}
									}
								}
								// });
								this.ppapElementRequiredStatus = resps;
								if (this.ppapElementRequiredStatus.length > 0) {
									//Disable Approve Reject, Needs Clarification
									// for (let data of this.ppapElementRequiredStatus) {
									// 	if (data[3] === 'REJECTED') {
									// 		this.isElementQuestionRejected = true;
									// 		break;
									// 	} else {
									// 		this.isElementQuestionRejected = false;
									// 	}
									// }
									// if(!this.isElementQuestionRejected) {
									// 	for (let data of this.sectionNC) {
									// 		if (data === 'NEEDS CLARIFICATION') {
									// 			this.isElementNeedsClarification = true;
									// 			break;
									// 		} else {
									// 			this.isElementNeedsClarification = false;
									// 		}
									// 	}
									// }
									for (const sectionsList of this.ppapElementSection) {
										for (const status of this.ppapElementRequiredStatus) {
											if (status[0] === sectionsList) {
												this.chart.data.datasets[0].data[this.ppapElementSection.indexOf(sectionsList)] = 0;
											}
										}
										for (const status of this.ppapElementRequiredStatus) {
											if (status[0] === sectionsList) {
												if (status[1] === null) {
													this.chart.data.datasets[0].data[this.ppapElementSection.indexOf(sectionsList)] = status[2];
												}
												if (status[1] === 'OK') {
													this.chart.data.datasets[1].data[this.ppapElementSection.indexOf(sectionsList)] = status[2];
												}
												if (status[1] === 'NOK') {
													this.chart.data.datasets[2].data[this.ppapElementSection.indexOf(sectionsList)] = status[2];
												}
												if (status[1] === 'N/A') {
													this.chart.data.datasets[3].data[this.ppapElementSection.indexOf(sectionsList)] = status[2];
												}
											}
										}

									}
								}
								for (const requiredElement of this.ppapElementSectionbutton) {

									if (requiredElement.requiredFlag === 'N') {
										this.chart.data.datasets[0].data[this.ppapElementSection.indexOf(requiredElement.sectionName)] = 0;
										this.chart.data.datasets[1].data[this.ppapElementSection.indexOf(requiredElement.sectionName)] = 0;
										this.chart.data.datasets[2].data[this.ppapElementSection.indexOf(requiredElement.sectionName)] = 0;
										this.chart.data.datasets[3].data[this.ppapElementSection.indexOf(requiredElement.sectionName)] = 0;
									}
								}
								if (!this.pswSupplier.get('isSupplier')) {
									this.butIndex = 0;
									this.ppapElementSelectedSectionName = 'PPAP Required Elements';
									this.loadPpapElementTable();
								}
								this.chart?.refresh();
							}, error => {
								console.log('Error Log ' + error);
							});
						}, error => {
							console.log('Error Log ' + error);
						}
					);
			});
			this.chart?.refresh();
		});
	}

	async loadPpapElementTable() {
		await this.getInitialData();
		this.digitalPpapPackageService.findAllPpapAssessmentResponse(this.ppapPackageIdentifierId).subscribe(ppapAssessmentResponse => {
			this.ppapAssessmentResponse = ppapAssessmentResponse;
			setTimeout(() => this.setRowHeight(this.ppapElementRecords), 0);
			//Disable Approve Reject, Needs Clarification
			for (const data of this.ppapAssessmentResponse) {
				if (data.staPdStatus === 'REJECTED') {
					this.isElementQuestionRejected = true;
					break;
				} else {
					this.isElementQuestionRejected = false;
				}
			}
			if (!this.isElementQuestionRejected) {
				for (const data of this.ppapAssessmentResponse) {
					if (data.staPdStatus === 'NEEDS CLARIFICATION') {
						this.isElementNeedsClarification = true;
						break;
					} else {
						this.isElementNeedsClarification = false;
					}
				}
			}
		});
	}

	setRowHeight(tableData) {
		let rowHeights: any[] = [], rowIteration, maxHeight;
		for (rowIteration = 0; rowIteration < tableData.length; rowIteration++) {
			//Since height vary due to question size, getting maximum row height from frozen rows onorder to set it equally to all rows
			if (document.getElementsByClassName('ui-table-scrollable-view ui-table-frozen-view')[0].getElementsByClassName('ui-table-scrollable-body')[0].getElementsByTagName('tr')[rowIteration] !== undefined) {
				rowHeights.push(document.getElementsByClassName('ui-table-scrollable-view ui-table-frozen-view')[0].getElementsByClassName('ui-table-scrollable-body')[0].getElementsByTagName('tr')[rowIteration].clientHeight);
			}
		}
		maxHeight = Math.max(...rowHeights);
		for (rowIteration = 0; rowIteration < tableData.length; rowIteration++) {
			if (document.getElementsByClassName('ui-table-scrollable-view ui-table-frozen-view')[0].getElementsByClassName('ui-table-scrollable-body')[0].getElementsByTagName('tr')[rowIteration] !== undefined) {
				//setting row height equally for both frozen and unfrozen div's
				if (document.getElementsByClassName('ui-table')[0].getElementsByClassName('ui-table-unfrozen-view')[0].getElementsByClassName('ui-table-tbody')[1].getElementsByTagName('tr')[rowIteration].clientHeight !==
					document.getElementsByClassName('ui-table')[0].getElementsByClassName('ui-table-frozen-view')[0].getElementsByClassName('ui-table-tbody')[1].getElementsByTagName('tr')[rowIteration].clientHeight) {
					document.getElementsByClassName('ui-table')[0].getElementsByClassName('ui-table-unfrozen-view')[0].getElementsByClassName('ui-table-tbody')[1].getElementsByTagName('tr')[rowIteration].setAttribute('height', maxHeight);
					document.getElementsByClassName('ui-table')[0].getElementsByClassName('ui-table-frozen-view')[0].getElementsByClassName('ui-table-tbody')[1].getElementsByTagName('tr')[rowIteration].setAttribute('height', maxHeight);
				}
			}
		}
	}

	async getInitialData() {
		let value: any[] = [];
		for (const requiredElement of this.ppapElementSectionbutton) {
			if (this.ppapElementSection.includes(requiredElement.sectionName) && requiredElement.requiredFlag === 'Y') {
				this.digitalPpapPackageService
					.getPpapElementSavedQuestion(requiredElement.sectionName,
						this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID).subscribe(async resps => {
					console.log('Retrieve Data for getPpapElementSavedQuestion getInitialData ' + resps);
					for (const data of resps) {
						if (!this.questionIdListForResp.includes(data.questionId)) {
							this.questionIdListForResp.push(data.questionId);
							this.ppapElementRecords = this.ppapElementRecords.concat(data);
						}
					}
					for (const data of this.ppapElementRecords) {
						if (!this.questionIdList.includes(data.questionId)) {
							this.questionIdList.push(data.questionId);
						}
					}
					if (resps.length === 0) {
						value = await this.getCheckedData();
					}
					setTimeout(() => this.setRowHeight(this.ppapElementRecords), 0);
				});
			}
		}
		await this.scrollForChartClick();
		this.updateRowGroupMetaData();
		this.dataLoadComplete = true;
	}

	async scrollForChartClick() {
		if (this.chartClickEvent !== undefined) {
			this.scrollToSelectedRow(this.chartClickEvent);
		}
	}

	async getCheckedData() {
		for (const requiredElement of this.ppapElementSectionbutton) {
			if (this.ppapElementSection.includes(requiredElement.sectionName) && requiredElement.requiredFlag === 'Y') {
				this.digitalPpapPackageService.getPpapElementQuestion(requiredElement.sectionName, this.phaseType).subscribe(resp => {
					console.log('Retrieve Data for Selected Chart: ' + resp);
					for (const data of resp) {
						if (!this.questionIdList.includes(data.questionId)) {
							this.questionIdList.push(data.questionId);
							this.ppapElementRecords = this.ppapElementRecords.concat(data);
						}
					}
				}, error => {
					console.log('Error Log ' + error);
				});
			}
			this.updateRowGroupMetaData();
		}
		return this.ppapElementRecords;
	}

	scrollToSelectedRow(e) {
		this.butIndex = 0;
		let unfrozenDiv, frozenDiv;
		let tableData: PpapElementRecords[];
		tableData = this.ppapElementRecords;
		let rowIteration;
		for (rowIteration = 0; rowIteration < tableData.length; rowIteration++) {
			if (tableData[rowIteration].sectionName === this.ppapElementSection[e.element._index]) {
				//Scrolling to the corresponding section in PPAP Element Table on clicking the chart.
				unfrozenDiv = document.getElementsByClassName('ui-table-scrollable-view ui-table-unfrozen-view')[0].getElementsByClassName('ui-table-scrollable-body')[0].getElementsByTagName('tr')[rowIteration];
				unfrozenDiv.scrollIntoView();
				frozenDiv = document.getElementsByClassName('ui-table-scrollable-view ui-table-frozen-view')[0].getElementsByClassName('ui-table-scrollable-body')[0].getElementsByTagName('tr')[rowIteration];
				frozenDiv.scrollIntoView();
				document.getElementById('ppapPackageAssessment').scrollIntoView(true);
				break;
			}
		}
	}

	updateRowGroupMetaData() {
		//For Row Grouping, refer primeng table/Rowspan.
		this.rowGroupMetadata = {};
		if (this.ppapElementRecords) {
			for (let i = 0; i < this.ppapElementRecords.length; i++) {
				const rowData = this.ppapElementRecords[i];
				const sectionName = rowData.sectionName;
				if (i === 0) {
					this.rowGroupMetadata[sectionName] = {index: 0, size: 1};
				} else {
					const previousRowData = this.ppapElementRecords[i - 1];
					const previousRowGroup = previousRowData.sectionName;
					if (sectionName === previousRowGroup)
						this.rowGroupMetadata[sectionName].size++;
					else
						this.rowGroupMetadata[sectionName] = {index: i, size: 1};
				}
			}
			if (this.ppapElementRecords.length > 0) {
				setTimeout(() => this.setRowHeight(this.ppapElementRecords), 0);
			}
		}
	}

	retrieveCommodityImage() {
		//retrieve commodity image
		console.log('Calling retrieve Commodity image');
		console.log(this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID);
		this.epswHomepageService.retrieveCommodityImage(this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID).subscribe(retrieveResp => {
			console.log('retrieve commodity Imge  Response');
			this.retrieveResonse = retrieveResp;
			if (this.retrieveResonse !== null && this.retrieveResonse.commodityImg !== null || undefined) {
				this.base64Data = this.retrieveResonse.commodityImg;
				this.retrievedImage = 'data:image/jpeg;base64,' + this.base64Data;
				this.commodityImgSavedFlag =true;
			}
		}, error => {
			console.log('retrieve commodity Imge Error Response');
			console.log(error);
			this.commodityImgSavedFlag = false;
			this.messageService.add({
				severity: 'info',
				summary: 'Info',
				detail: 'Unable to Retrieve Commodity image, Please try to save after some time.',
				life: 5000
			});
		});
	}

	private saveCommodityImage() {
		console.log('Saving Commodity Image - Identifier ID' + this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID);
		const uploadSelectedImage = new FormData();
		uploadSelectedImage.append('imageFile', this.selectedCmdyImage, this.selectedCmdyImage.name);
		uploadSelectedImage.append('ppapPackageIdentifierID', this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID.toString());
		this.epswHomepageService.saveCommodityImage(uploadSelectedImage).subscribe(resp => {
			this.commodityImgSavedFlag = true;
			this.messageService.add({
				severity: 'success',
				summary: 'Success',
				detail: 'Commodity image uploaded Successfully.'
			});
			this.retrieveCommodityImage();
		}, error => {
			console.log(error);
			this.commodityImgSavedFlag = false;
			this.messageService.add({
				severity: 'error',
				summary: 'Error',
				detail: 'Unable to Retrieve Commodity image, Please try to save after some time.'
			});
		});
	}

	// submitPage() {
	// 	console.log('Submitting Digital Ppaa for Phase 0');
	// 	this.digitalPpapPackageService.getPpapElementRequired(this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID)
	// 		.subscribe(repo => {
	// 				console.log('Ppap Required Check Data for Sumbit: ' + repo);
	// 				if (repo.length>0){
	// 					this.digitalPpapPackageService.getPackagePpapElementSubmitCheck(this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID)
	// 						.subscribe(repos => {
	// 								console.log('Ppap Required Response Check Data for Submit: ' + repos);
	// 								// alert(repos);
	// 								const msg = repos.toString().split(',').join('\r\n');
	// 								// alert(msg);
	// 								this.messageService.add({severity:'info', summary: msg,  life: 2000});
	// 							},error => {
	// 								console.log('Error Log '+error);
	// 							}
	// 						);
	//
	// 				}else {
	// 					this.messageService.add({severity:'info', summary: 'Please Complete PPAP Required Element and Response',  life: 2000});
	// 				}
	//
	// 			},error => {
	// 				console.log('Error Log '+error);
	// 			}
	// 		);
	// }

	// confirmMessage(){
	// 	const notSaved = this.ppapPartsArray.filter(parts => parts.ppapPackagePartsEntityID === null)
	// 	if (notSaved.length !== 0) {
	// 		this.confirmationService.confirm({
	// 			message: 'Do you want to save unsaved record?',
	// 			header: 'Close',
	// 			icon: 'pi pi-exclamation-triangle',
	// 			accept: () => {
	// 				this.digitalPpapPackageService.savePart(notSaved[0]).subscribe(resp => {
	// 					console.log(resp);
	// 					this.getAllDataForParts();
	// 				})
	// 				this.messageService.add({severity: 'success', summary: 'Successful', detail: 'Saved', life: 3000});
	// 			}
	// 		});
	// 	}
	// 	this.getAllDataForParts();
	// }

	ppapLevelChange(ppapLevel) {
		if (ppapLevel !== this.ppapLevelParts) {
			this.confirmationService.confirm({
				message: 'Do you want to change the PPAP Level for all parts?',
				header: 'Confirm',
				icon: 'pi pi-exclamation-triangle',
				accept: () => {
					this.ppapPartsArray.forEach(value => value.ppapLevel = ppapLevel);
					this.ppapLevelParts = ppapLevel;
					this.digitalPpapPackageService.saveParts(this.ppapPartsArray).subscribe(resp => {
						this.getAllDataForParts();
					});
					this.messageService.add({
						severity: 'info',
						summary: 'Info',
						detail: 'PPAP level saved for all parts.',
						life: 3000
					});
					// this.msgs.push({severity: 'info', detail: 'PPAP level saved for all parts'});
				}
			});
		} else {
			ppapLevel = this.ppapLevelParts;
		}
	}

	//S3Storage file upload
	ppapElementResponseFiles(event, sectionName: string, sectionId: any, questionId: any) {
		const files = [].slice.call(event.target.files);
		const fileNames = [];
		const date = new Date();
		const currentDate = this.datePipe.transform(date, 'MM-dd-yyyy h:mm:ss a');
		let attachmentName = files.map(f => this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID + '_' +
			sectionId + '_' + questionId + '_' + currentDate + '__' + f.name).join(', ');
		const currentFile = attachmentName;
		for (const ppapElement of this.ppapElementRecords) {
			if (ppapElement.sectionName === sectionName && ppapElement.sectionId === sectionId && ppapElement.questionId === questionId && ppapElement.document !== null) {
				fileNames.push(ppapElement.document.split(','));
			}
		}
		if (fileNames[0] !== undefined) {
			for (let data = 0; data < fileNames[0].length; data++) {
				if (attachmentName !== null) {
					attachmentName = attachmentName.concat(',');
				}
				attachmentName = attachmentName.concat(fileNames[0][data]);
				console.log('AttachmentName: '+attachmentName);
				// attachmentName = attachmentName.concat(fileNames[0][data].split('__').pop());
			}
		}
		const formdata = new FormData();
		/*for  (var i =  0; i <  files.length; i++)  {
			formdata.append('file[]',  JSON.stringify(files[i]));
			formdata.append('fileName', JSON.stringify(files.map(f => f.name)));
			/!*formdata.append('file', JSON.stringify(files));
			formdata.append('fileName', JSON.stringify(files.map(f => f.name)));*!/
		}*/
		for (let i = 0; i < files.length; i++) {
			formdata.append('file', event.target.files[i]);
			this.selectedFile= event.target.files[i];
			// formdata.append('fileName', event.target.files[i]);
		}
		const regexPattern = new RegExp('^[a-zA-Z0-9!@$^&*(). _-]+$');
		let fileSize = 0;
		for (const file of files) {
			fileSize = fileSize + file.size;
		}
		if(currentFile.indexOf(',') < 0 && currentFile.indexOf('%') < 0 && currentFile.indexOf('#') < 0 && currentFile.indexOf(';') < 0){
			if (fileSize / 1024 / 1024 < 20) {
				if (regexPattern.test(files.map(f => f.name))) {
					this.digitalPpapPackageService.getSignedUploadUrl(currentFile).subscribe(res1 => {
						this.digitalPpapPackageService.uploadAdhocFile(res1,this.selectedFile)
							.subscribe(msg=>{
								console.log(this.ppapElementRecords);
								for (const data of this.ppapElementRecords) {
									if (data.sectionName === sectionName && data.sectionId === sectionId && data.questionId === questionId) {
										data.document = attachmentName;
										this.getFileName(data.document);
									}
								}
								// added for saving current ppap element while adding pdf
								let ppapElementRecordsBefore: PpapElementRecords;
								if (this.ppapElementRecords !== undefined && this.ppapElementRecords.length > 0) {
									for (ppapElementRecordsBefore of this.ppapElementRecords) {
										if (this.pswSupplier.get('supplierAccess')) {
											if (ppapElementRecordsBefore.lastUpdatedBy !== sessionStorage.getItem('userId')) {
												ppapElementRecordsBefore.lastUpdatedBy = sessionStorage.getItem('userId');
												ppapElementRecordsBefore.lastUpdatedOn = new Date();
											} else {
												ppapElementRecordsBefore.lastUpdatedBy = ppapElementRecordsBefore.lastUpdatedBy;
												ppapElementRecordsBefore.lastUpdatedOn = new Date();
											}
										}
										this.ppapElementSavePDF.push({
											ppapPackageAssessmentResponseEntityID: ppapElementRecordsBefore.ppapPackageAssessmentResponseEntityID,
											ppapPackageIdentifierID: this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID,
											sectionId: ppapElementRecordsBefore.sectionId,
											questionId: ppapElementRecordsBefore.questionId,
											sectionName: ppapElementRecordsBefore.sectionName,
											sectionSortSequence: ppapElementRecordsBefore.sectionSortSequence,
											questionName: ppapElementRecordsBefore.questionName,
											questionSortSequence: ppapElementRecordsBefore.questionSortSequence,
											status: ppapElementRecordsBefore.status,
											document: ppapElementRecordsBefore.document,
											proprietary: ppapElementRecordsBefore.proprietary,
											supplierRemark: ppapElementRecordsBefore.supplierRemark,
											lastUpdatedBy: ppapElementRecordsBefore.lastUpdatedBy,
											lastUpdatedOn: ppapElementRecordsBefore.lastUpdatedOn,
											staPdStatus: ppapElementRecordsBefore.staPdStatus,
											staPdRemark: ppapElementRecordsBefore.staPdRemark,
											staPdStatusBy: ppapElementRecordsBefore.staPdStatusBy,
											staPdStatusOn: ppapElementRecordsBefore.staPdStatusOn,
											createdBy: ppapElementRecordsBefore.createdBy,
											createdOn: ppapElementRecordsBefore.createdOn
										});
									}
								}
								this.digitalPpapPackageService.savePpapElementRecords(this.ppapElementSavePDF).subscribe(resp => {
										console.log(' savePpapElementRecords Save Data: ' + resp);
										this.ppapElementRecords = resp;
										this.ppapElementSavePDF = [];
										// this.ppapElementRecords = []
										this.messageService.add({
											severity: 'success',
											detail: 'File Uploaded Successfully Completed',
											life: 3000
										});
									}, error => {
										console.log('Error Log ' + error);
									}
								);
							} , error=>{
								console.log(error);
							});

						// console.log('Response for postPpapElementResponseFile : ' + res);

						// end for saving current ppap element while adding pdf
					}, error => {
						console.log('Error Log for postPpapElementResponseFile ' + error);
					});
				} else {
					this.messageService.add({
						severity: 'error',
						summary: 'error',
						detail: 'File Name should be in English.\n' +
							'Please rename and upload again.',
						life: 5000
					});
				}
			} else {
				/*if (event.target.files[0].type !== 'application/pdf'){
					this.messageService.add({
					severity: 'error',
					summary: 'error',
					detail: 'File Type Should be PDF.',
					life: 5000
				});
				}else{*/
				this.messageService.add({
					severity: 'error',
					summary: 'error',
					detail: 'File Size Should be less than 20MB.',
					life: 5000
				});
				// }
			}
		}else{
			let specialChars='';
			if(currentFile.indexOf(',') > 0){
				if(specialChars.length > 0){
					specialChars = specialChars+', comma';
				}else{
					specialChars = 'comma';
				}
			}
			if(currentFile.indexOf('%') > 0){
				if(specialChars.length > 0) {
					specialChars = specialChars+', %';
				}else{
					specialChars = '%';
				}
			}
			if(currentFile.indexOf('#') > 0){
				if(specialChars.length > 0) {
					specialChars = specialChars+', #';
				}else{
					specialChars = '#';
				}
			}
			if(currentFile.indexOf(';') > 0){
				if(specialChars.length > 0) {
					specialChars = specialChars+', ;';
				}else{
					specialChars = ';';
				}
			}
			this.messageService.add({
				severity: 'error',
				summary: 'error',
				detail:  'File Name should not contain "'+specialChars+' " symbols.\n' +
					'Please rename and upload again.',
				life: 5000
			});
		}
	}

	//S3Storage file upload
	/*ppapElementResponseFile(event, sectionName: string, sectionId: any, questionId: any) {
		if (event.target.files && event.target.files[0]) {
			const reader = new FileReader();
			console.log(event.target.files[0].name);
			const date = new Date();
			const currentDate = this.datePipe.transform(date, 'MM-dd-yyyy h:mm:ss a');
			const attachmentName = this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID + '_' +
				sectionId + '_' + questionId + '_' + currentDate + '__' + event.target.files[0].name;
			const formdata: FormData = new FormData();
			formdata.append('file', event.target.files[0]);
			formdata.append('fileName', event.target.files[0]);
			// event.target.files[0].name = attachmentName;
			console.log(event.target.files[0].size / 1024 / 1024 + ' MB');
			console.log('File type :', event.target.files[0].type);
			//Added for US3809059 (Different language check while upload)
			const regexPattern = new RegExp('^[a-zA-Z0-9!@#$%^&*(). -_]+$');
			// && event.target.files[0].type === 'application/pdf'
			if (event.target.files[0].size / 1024 / 1024 < 20) {
				if(regexPattern.test(event.target.files[0].name)) {
					console.log('file is less than 5MB');
					this.digitalPpapPackageService.putPpapElementResponseFile(formdata, attachmentName).subscribe(res => {
						console.log('Response for postPpapElementResponseFile : ' + res);
						console.log(this.ppapElementRecords);
						for (const data of this.ppapElementRecords) {
							if (data.sectionName === sectionName && data.sectionId === sectionId && data.questionId === questionId) {
								data.document = res;
							}
						}
						// added for saving current ppap element while adding pdf
						let ppapElementRecordsBefore: PpapElementRecords;
						if (this.ppapElementRecords !== undefined && this.ppapElementRecords.length > 0) {
							for (ppapElementRecordsBefore of this.ppapElementRecords) {
								if (this.pswSupplier.get('supplierAccess')) {
									if (ppapElementRecordsBefore.lastUpdatedBy !== sessionStorage.getItem('userId')) {
										ppapElementRecordsBefore.lastUpdatedBy = sessionStorage.getItem('userId');
										ppapElementRecordsBefore.lastUpdatedOn = new Date();
									} else {
										ppapElementRecordsBefore.lastUpdatedBy = ppapElementRecordsBefore.lastUpdatedBy;
										ppapElementRecordsBefore.lastUpdatedOn = new Date();
									}
								}
								this.ppapElementSavePDF.push({
									ppapPackageAssessmentResponseEntityID: ppapElementRecordsBefore.ppapPackageAssessmentResponseEntityID,
									ppapPackageIdentifierID: this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID,
									sectionId: ppapElementRecordsBefore.sectionId,
									questionId: ppapElementRecordsBefore.questionId,
									sectionName: ppapElementRecordsBefore.sectionName,
									sectionSortSequence: ppapElementRecordsBefore.sectionSortSequence,
									questionName: ppapElementRecordsBefore.questionName,
									questionSortSequence: ppapElementRecordsBefore.questionSortSequence,
									status: ppapElementRecordsBefore.status,
									document: ppapElementRecordsBefore.document,
									proprietary: ppapElementRecordsBefore.proprietary,
									supplierRemark: ppapElementRecordsBefore.supplierRemark,
									lastUpdatedBy: ppapElementRecordsBefore.lastUpdatedBy,
									lastUpdatedOn: ppapElementRecordsBefore.lastUpdatedOn,
									staPdStatus: ppapElementRecordsBefore.staPdStatus,
									staPdRemark: ppapElementRecordsBefore.staPdRemark,
									staPdStatusBy: ppapElementRecordsBefore.staPdStatusBy,
									staPdStatusOn: ppapElementRecordsBefore.staPdStatusOn,
									createdBy: ppapElementRecordsBefore.createdBy,
									createdOn: ppapElementRecordsBefore.createdOn
								});
							}
						}
						this.digitalPpapPackageService.savePpapElementRecords(this.ppapElementSavePDF).subscribe(resp => {
								console.log(' savePpapElementRecords Save Data: ' + resp);
								this.ppapElementRecords = resp;
								this.ppapElementSavePDF = [];
								// this.ppapElementRecords = []
								this.messageService.add({
									severity: 'success',
									detail: 'File Uploaded Successfully Completed',
									life: 3000
								});
							}, error => {
								console.log('Error Log ' + error);
							}
						);
						// end for saving current ppap element while adding pdf
					}, error => {
						console.log('Error Log for postPpapElementResponseFile ' + error);
					});
				} else {
					this.messageService.add({
						severity: 'error',
						summary: 'error',
						detail: 'File Name should be in English.\n' +
								'Please rename and upload again.',
						life: 5000
					});
				}
			} else {
				/!*if (event.target.files[0].type !== 'application/pdf'){
					this.messageService.add({
					severity: 'error',
					summary: 'error',
					detail: 'File Type Should be PDF.',
					life: 5000
				});
				}else{*!/
					this.messageService.add({
						severity: 'error',
						summary: 'error',
						detail: 'File Size Should be less than 20MB.',
						life: 5000
					});
				// }
			}
		}
	}*/

	//S3Storage file delete
	removeS3Storage(fileName: string, sectionName: string, sectionId: any, questionId: any) {
		this.confirmationService.confirm({
			message: 'Are you sure you want to delete the uploaded file?',
			header: 'Delete Confirmation',
			icon: 'fa fa-info-circle',
			accept: () => {
				this.digitalPpapPackageService.deletePpapElementResponseFile(fileName).subscribe(res => {
					console.log('Response for fileDelete : ' + res);
					if (res === 'Success') {
						console.log(this.ppapElementRecords);
						for (const data of this.ppapElementRecords) {
							if (data.sectionName === sectionName && data.sectionId === sectionId && data.questionId === questionId) {
								data.document = null;
							}
						}
						// added for saving current ppap element while adding pdf
						let ppapElementRecordsBefore: PpapElementRecords;
						if (this.ppapElementRecords !== undefined && this.ppapElementRecords.length > 0) {
							for (ppapElementRecordsBefore of this.ppapElementRecords) {
								// if (JSON.parse(sessionStorage.getItem('attributes')).userRole==='EAPQP_UPDATE'){
								if (this.pswSupplier.get('supplierAccess')) {
									if (ppapElementRecordsBefore.lastUpdatedBy !== sessionStorage.getItem('userId')) {
										ppapElementRecordsBefore.lastUpdatedBy = sessionStorage.getItem('userId');
										ppapElementRecordsBefore.lastUpdatedOn = new Date();
									} else {
										ppapElementRecordsBefore.lastUpdatedBy = ppapElementRecordsBefore.lastUpdatedBy;
										ppapElementRecordsBefore.lastUpdatedOn = new Date();
									}
								}
								this.ppapElementSavePDF.push({
									ppapPackageAssessmentResponseEntityID: ppapElementRecordsBefore.ppapPackageAssessmentResponseEntityID,
									ppapPackageIdentifierID: this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID,
									sectionId: ppapElementRecordsBefore.sectionId,
									questionId: ppapElementRecordsBefore.questionId,
									sectionName: ppapElementRecordsBefore.sectionName,
									sectionSortSequence: ppapElementRecordsBefore.sectionSortSequence,
									questionName: ppapElementRecordsBefore.questionName,
									questionSortSequence: ppapElementRecordsBefore.questionSortSequence,
									status: ppapElementRecordsBefore.status,
									document: ppapElementRecordsBefore.document,
									proprietary: ppapElementRecordsBefore.proprietary,
									supplierRemark: ppapElementRecordsBefore.supplierRemark,
									lastUpdatedBy: ppapElementRecordsBefore.lastUpdatedBy,
									lastUpdatedOn: ppapElementRecordsBefore.lastUpdatedOn,
									staPdStatus: ppapElementRecordsBefore.staPdStatus,
									staPdRemark: ppapElementRecordsBefore.staPdRemark,
									staPdStatusBy: ppapElementRecordsBefore.staPdStatusBy,
									staPdStatusOn: ppapElementRecordsBefore.staPdStatusOn,
									createdBy: ppapElementRecordsBefore.createdBy,
									createdOn: ppapElementRecordsBefore.createdOn
								});
							}
						}
						this.digitalPpapPackageService.savePpapElementRecords(this.ppapElementSavePDF).subscribe(resp => {
								console.log(' savePpapElementRecords Save Data: ' + resp);
								this.ppapElementRecords = resp;
								this.ppapElementSavePDF = [];
								// this.ppapElementRecords = []
								this.messageService.add({
									severity: 'success',
									detail: 'File Deleted Successfully Completed',
									life: 3000
								});
							}, error => {
								console.log('Error Log ' + error);
							}
						);
						// end for saving current ppap element while adding pdf

						// this.messageService.add({ severity:'success',  detail: 'Delete Successfully Completed',life: 2000});
					} else if (res === 'Failed') {
						this.messageService.add({severity: 'Error', detail: 'Unable to Delete the File', life: 3000});
					}
				}, error => {
					console.log('Error Response for fileDelete : ' + error);
				});
			},
			reject: () => {
			}
		});
	}

	removeS3StorageFiles(key: string, sectionName: string, sectionId: any, questionId: any, fileName) {
		this.confirmationService.confirm({
			message: 'Are you sure you want to delete the uploaded file?',
			header: 'Delete Confirmation',
			icon: 'fa fa-info-circle',
			accept: () => {
				this.digitalPpapPackageService.deleteFile(fileName).subscribe(res2 => {
					const res1 = res2.genericString;
					console.log('Response for fileDelete : ' + res1);
					let res='';
					if(key.includes(fileName+','))
						res = key.replace(fileName+',','');
					else if (key.includes(','+fileName))
						res = key.replace(','+fileName,'');
					else
						res = key.replace(fileName,'');
					if (res !== null || res.length > 0) {
						console.log(this.ppapElementRecords);
						for (const data of this.ppapElementRecords) {
							if (data.sectionName === sectionName && data.sectionId === sectionId && data.questionId === questionId) {
								if (res !== null && res !== undefined && res.length > 0) {
									data.document = res;
								} else {
									data.document = null;
								}
								this.getFileName(data.document);
							}
						}
						// added for saving current ppap element while adding pdf
						let ppapElementRecordsBefore: PpapElementRecords;
						if (this.ppapElementRecords !== undefined && this.ppapElementRecords.length > 0) {
							for (ppapElementRecordsBefore of this.ppapElementRecords) {
								// if (JSON.parse(sessionStorage.getItem('attributes')).userRole==='EAPQP_UPDATE'){
								if (this.pswSupplier.get('supplierAccess')) {
									if (ppapElementRecordsBefore.lastUpdatedBy !== sessionStorage.getItem('userId')) {
										ppapElementRecordsBefore.lastUpdatedBy = sessionStorage.getItem('userId');
										ppapElementRecordsBefore.lastUpdatedOn = new Date();
									} else {
										ppapElementRecordsBefore.lastUpdatedBy = ppapElementRecordsBefore.lastUpdatedBy;
										ppapElementRecordsBefore.lastUpdatedOn = new Date();
									}
								}
								this.ppapElementSavePDF.push({
									ppapPackageAssessmentResponseEntityID: ppapElementRecordsBefore.ppapPackageAssessmentResponseEntityID,
									ppapPackageIdentifierID: this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID,
									sectionId: ppapElementRecordsBefore.sectionId,
									questionId: ppapElementRecordsBefore.questionId,
									sectionName: ppapElementRecordsBefore.sectionName,
									sectionSortSequence: ppapElementRecordsBefore.sectionSortSequence,
									questionName: ppapElementRecordsBefore.questionName,
									questionSortSequence: ppapElementRecordsBefore.questionSortSequence,
									status: ppapElementRecordsBefore.status,
									document: ppapElementRecordsBefore.document,
									proprietary: ppapElementRecordsBefore.proprietary,
									supplierRemark: ppapElementRecordsBefore.supplierRemark,
									lastUpdatedBy: ppapElementRecordsBefore.lastUpdatedBy,
									lastUpdatedOn: ppapElementRecordsBefore.lastUpdatedOn,
									staPdStatus: ppapElementRecordsBefore.staPdStatus,
									staPdRemark: ppapElementRecordsBefore.staPdRemark,
									staPdStatusBy: ppapElementRecordsBefore.staPdStatusBy,
									staPdStatusOn: ppapElementRecordsBefore.staPdStatusOn,
									createdBy: ppapElementRecordsBefore.createdBy,
									createdOn: ppapElementRecordsBefore.createdOn
								});
							}
						}
						this.digitalPpapPackageService.savePpapElementRecords(this.ppapElementSavePDF).subscribe(resp => {
								console.log(' savePpapElementRecords Save Data: ' + resp);
								this.ppapElementRecords = resp;
								this.ppapElementSavePDF = [];
								// this.ppapElementRecords = []
								this.messageService.add({
									severity: 'success',
									detail: 'File Deleted Successfully Completed',
									life: 3000
								});
							}, error => {
								console.log('Error Log ' + error);
							}
						);
						// end for saving current ppap element while adding pdf

						// this.messageService.add({ severity:'success',  detail: 'Delete Successfully Completed',life: 2000});
					} else if (res === 'Failed') {
						this.messageService.add({severity: 'Error', detail: 'Unable to Delete the File', life: 3000});
					}
				}, error => {
					console.log('Error Response for fileDelete : ' + error);
				});
			},
			reject: () => {
			}
		});
	}

	//S3Storage file download
	fileDownloadsForPPAPElement(document: string, ppapElem) {
		this.digitalPpapPackageService.getSignedDownloadUrl(ppapElem).subscribe(data =>{
			this.http.get(data, {responseType: 'blob'})
				.subscribe(blob => {
					saveAs(blob, ppapElem);
				});
		});
		// this.digitalPpapPackageService.getPpapElementResponseFile(ppapElem).subscribe(res => {
		// 	console.log('Response for FileOpen in new tab : ' + res);
		// 	let mimeType;
		// 	const byteArray = new Uint8Array(res);
		// 	const fileExt = ppapElem.split('.').pop().toLowerCase();
		// 	console.log('Response for openfile..... : ' + fileExt);
		// 	if(fileExt === 'pdf'){
		// 		mimeType = 'application/pdf';
		// 	} else if (fileExt === 'txt'){
		// 		mimeType = 'text/plain';
		// 	} else if (fileExt === 'png'){
		// 		mimeType = 'image/png';
		// 	} else if(fileExt === 'jfif')
		// 	{
		// 		mimeType = 'image/jpeg';
		// 	} else if(fileExt === 'jpeg')
		// 	{
		// 		mimeType = 'image/jpeg';
		// 	} else if(fileExt === 'jpg')
		// 	{
		// 		mimeType = 'image/jpeg';
		// 	} else if(fileExt === 'doc')
		// 	{
		// 		mimeType = 'application/msword';
		// 	}  else if (fileExt === 'docx'){
		// 		mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
		// 	} else if(fileExt === 'ppt')
		// 	{
		// 		mimeType = 'application/vnd.ms-powerpoint';
		// 	} else if (fileExt === 'xls'){
		// 		mimeType = 'application/vnd.ms-excel';
		// 	} else if (fileExt === 'csv'){
		// 		mimeType = 'text/csv';
		// 	} else if(fileExt === 'pptx')
		// 	{
		// 		mimeType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
		// 	} else if(fileExt === 'xlsx')
		// 	{
		// 		mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		// 	} else{
		// 		console.log('Error Response for fileOpen : File format not supporting..');
		// 	}
		// 	const blob = new Blob([byteArray],{type:mimeType});
		// 	const link = window.document.createElement('a')
		// 	/*for (let data of  document.split(',')){
		// 		link.href = window.URL.createObjectURL(blob);
		// 		link.download = data.split('__').pop().trim();
		// 		link.click();
		// 	}*/
		// 	link.href = window.URL.createObjectURL(blob);
		// 	//link.download = ppapElem.split('__').pop().trim();
		// 	//link.click();
		// 	window.open(link.href, '_blank');
		// }, error => {
		// 	console.log('Error Response for fileDownload : ' + error);
		// });
	}

	fileDownload(document: string) {
		this.digitalPpapPackageService.getSignedDownloadUrl(document).subscribe(data =>{
			this.http.get(data, {responseType: 'blob'})
				.subscribe(blob => {
					saveAs(blob, document?.split('__')?.pop()?.trim());
				});
		});
		// this.digitalPpapPackageService.getPpapElementResponseFile(document).subscribe(res => {
		// 	console.log('Response for fileOpen : ' + res);
		// 	let mimeType;
		// 	const byteArray = new Uint8Array(res);
		// 	const fileExt = document.split('.').pop().toLowerCase();
		// 	const fileName = document.split('__').pop().trim();
		// 	console.log('Response for openfile..... : ' + fileExt);
		// 	if(fileExt === 'pdf'){
		// 		mimeType = 'application/pdf';
		// 	} else if (fileExt === 'txt'){
		// 		mimeType = 'text/plain';
		// 	} else if (fileExt === 'png'){
		// 		mimeType = 'image/png';
		// 	} else if(fileExt === 'jfif')
		// 	{
		// 		mimeType = 'image/jpeg';
		// 	} else if(fileExt === 'jpeg')
		// 	{
		// 		mimeType = 'image/jpeg';
		// 	} else if(fileExt === 'jpg')
		// 	{
		// 		mimeType = 'image/jpeg';
		// 	} else if(fileExt === 'doc')
		// 	{
		// 		mimeType = 'application/msword';
		// 	}  else if (fileExt === 'docx'){
		// 		mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
		// 	} else if(fileExt === 'ppt')
		// 	{
		// 		mimeType = 'application/vnd.ms-powerpoint';
		// 	} else if (fileExt === 'xls'){
		// 		mimeType = 'application/vnd.ms-excel';
		// 	} else if (fileExt === 'csv'){
		// 		mimeType = 'text/csv';
		// 	} else if(fileExt === 'pptx')
		// 	{
		// 		mimeType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
		// 	} else if(fileExt === 'xlsx')
		// 	{
		// 		mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		// 	} else if (fileExt === 'zip'){
		// 		mimeType = 'application/zip';
		// 	} else if (fileExt === 'xlsm'){
		// 		mimeType = 'application/vnd.ms-excel.sheet.macroEnabled.12';
		// 	} else{
		// 		console.log('Error Response for fileOpen : File format "',fileExt,'" not supporting..');
		// 	}
		// 	this.openAdhocDocs(byteArray,mimeType,fileName);
		// }, error => {
		// 	console.log('Error Response for fileOpen : ' + error);
		// });
	}

	openAdhocDocs(byteArray: any,mimeType: any,fileName: string){
		const blob = new Blob([byteArray], {type: mimeType});
		const link = window.document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = fileName;
		link.click();
		//window.open(link.href, '_blank');
	}

	//ldap
	searchKeywordInLdap(event, value: boolean, type: string = 'STA') {
		this.ldapResponse = [];
		const cdsid = event.query;
		if (cdsid && cdsid.length > 2) {
			console.log('calling service...');
			this.digitalPpapPackageService.searchFordBuyer(cdsid).subscribe(ldapResponse => {
				console.log('ldapResponse');
				this.ldapResponse = ldapResponse;
				console.log(this.ldapResponse)
				if (value === true)
					this.fordBuyerSuggestions = this.buildDisplayName(ldapResponse);

				else
					this.fordDisplayNameSuggestions = this.buildDisplayNameWithDesignation(ldapResponse);
			}, (err) => {
				// this.showLdapFailureWarningMessage(cdsid, type)
			});
		}
	}

	private buildDisplayName(ldapResponse: LdapResponse[]) {
		for (const data of ldapResponse) {
			data.userId = data.userId.toLocaleUpperCase();
			data.fordDisplayName = data.fordDisplayName.toLocaleUpperCase();
			if(data.fordDisplayName === null) {
				data.fordDisplayName = ' ';
			} else {
				data.fordDisplayName = data.fordDisplayName?.toLocaleUpperCase();
			}
			if(data.departmentName === null) {
				data.departmentName = ' ';
			} else {
				data.departmentName = data.departmentName?.toString().toLocaleUpperCase();
			}
			data.userId = data.userId+'-'+data.fordDisplayName+'-'+data.departmentName;
		}

		return ldapResponse;

	}

	private buildDisplayNameWithDesignation(ldapResponse: LdapResponse[]) {
		for (const data of ldapResponse) {
			data.userId = data.userId.toLocaleUpperCase();
			data.fordDisplayName = data.fordDisplayName.toLocaleUpperCase();
		}
		return ldapResponse;
	}

	confirmMessage() {
		this.partNumbers = undefined;
		this.partPappLevel = undefined;
		this.partDisableEnable = true;
		(document.getElementById('addRow') as HTMLInputElement).disabled = false;
		// @ts-ignore
		this.ppapPartsArray = this.ppapPartsArray.filter(e => e.ppapPackageIdentifierID !== '');
		const notSaved = this.ppapPartsArray.filter(parts => parts.ppapPackagePartsEntityID === null)
		if (notSaved.length !== 0) {
			this.confirmationService.confirm({
				message: 'Are you sure do you want to discard the changes?',
				header: 'Close',
				icon: 'pi pi-exclamation-triangle',
				accept: () => {
					this.getAllDataForParts();
					this.getPrefixBaseSuffixForParts();
					this.ppapPartsArrayLength = this.ppapPartsArray.length;
					this.displayDialog = false;
					//Alert message - US3738472
					if (this.mode === 'edit') {
						this.epswHomepageService.getAllData(this.ppapPackageIdentifier).subscribe(partList => {
							this.ppapPackagePartsList = partList;
							if (this.ppapPackagePartsList.length >= 1) {
								this.message = 'One or more part numbers does not exist in cmms anymore.Please review the part list and delete the hightlighted parts.';
							} else {
								this.message = '';
							}
						});
					}
				},
				reject: () => {
					this.digitalPpapPackageService.savePart(notSaved[0]).subscribe(resp => {
						console.log(resp);
						this.getAllDataForParts();
						this.getPrefixBaseSuffixForParts();
						this.ppapPartsArrayLength = this.ppapPartsArray.length;
						this.messageService.add({
							severity: 'success',
							summary: 'Successful',
							detail: 'Saved',
							life: 3000
						});
					})
				}
			});
		} else {
			this.ppapPartsArrayLength = this.ppapPartsArray.length;
			this.displayDialog = false;
			this.getAllDataForParts();
			//Alert message - US3738472
			if (this.mode === 'edit') {
				this.epswHomepageService.getAllData(this.ppapPackageIdentifier).subscribe(partList => {
					this.ppapPackagePartsList = partList;
					if (this.ppapPackagePartsList.length >= 1) {
						this.message = 'One or more part numbers does not exist in cmms anymore.' +
							'Please review the part list and delete the hightlighted parts.';
					} else {
						this.message = '';
					}
				});
			}
		}
	}

	downloadSampleTier2Excel() {
		console.log('Download sample tier 2 Excel');
		this.digitalPpapPackageService.downloadSampleTier2Excel();
	}

	downloadTier2Excel() {
		console.log('Download tier 2 Excel');
		this.digitalPpapPackageService.downloadTier2Excel(this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID.toString());
	}

	private retrieveTier2SubSupplierDetails() {
		console.log('Retrieve Tier 2 Details');
		this.digitalPpapPackageService.retrieveTier2SubSupplierDetails(this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID.toString())
			.subscribe(arrayResp => {
				console.log('Tier 2 Details retrieved');
				const resp = arrayResp.tier2SubSuppliers;
				this.loadTier2StratificationHighChart(resp);
				if (resp != null) {
					this.isTier2DetailsPresent = true;
					this.tier2SavedFlag=true;
					resp.forEach(tier2SubSupplier => {
						console.log('tier2SubSupplier');
						console.log(tier2SubSupplier);
						if (tier2SubSupplier.criticalNonCriticalSupplier === 'Critical') {
							this.criticalSubSuppliers.push(tier2SubSupplier);
							console.log(this.criticalSubSuppliers);
						}
					});
				}
			}, error => {
				console.log('stratification retrieved Error')
				console.log(error);
				this.messageService.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Unable to Retrieve stratification, Please try to save after some time.'
				});
			});
	}

	private loadTier2StratificationHighChart(tier2SubSuppliers: Tier2SubSupplier[]) {
		console.log('high chart display');
		console.log(tier2SubSuppliers);
		if (tier2SubSuppliers != null) {
			let tier2 = 0;
			let dT2 = 0;
			let dT2MPA = 0;
			let bailment = 0;
			tier2SubSuppliers.forEach(tier2SubSupplier => {
				if (tier2SubSupplier.tier2SupplierType === 'Tier 2') {
					tier2 += 1;
				} else if (tier2SubSupplier.tier2SupplierType === 'Ford DT2') {
					dT2 += 1;
				} else if (tier2SubSupplier.tier2SupplierType === 'Ford DT2 (MPA)') {
					dT2MPA += 1;
				} else if (tier2SubSupplier.tier2SupplierType === 'Bailment') {
					bailment += 1;
				}
			});
			this.treeMap = [
				{
					name: 'Tier 2',
					value: 9,
					realValue: tier2,
					color: 'rgb(65,105,225,0.5)',
				}, {
					name: 'Ford DT2',
					value: 6,
					realValue: dT2,
					color: 'rgb(255,165,0,0.5)'
				}, {
					name: 'Bailment',
					value: 2,
					realValue: bailment,
					color: 'rgb(102,205,170,0.5)'
				}, {
					name: 'Ford DT2 (MPA)',
					value: 2,
					realValue: dT2MPA,
					color: 'rgb(189,183,107, 0.7)'
				}
			];
		} else {
			this.treeMap = [
				{
					name: 'Tier 2',
					value: 9,
					realValue: 'No Data',
					color: 'rgb(65,105,225,0.5)',
				}, {
					name: 'Ford DT2',
					value: 6,
					realValue: 'No Data',
					color: 'rgb(255,165,0,0.5)'
				}, {
					name: 'Bailment',
					value: 2,
					realValue: 'No Data',
					color: 'rgb(102,205,170,0.5)'
				}, {
					name: 'Ford DT2 (MPA)',
					value: 2,
					realValue: 'No Data',
					color: 'rgb(189,183,107, 0.7)'
				}
			];
		}
		Highcharts.chart(this.container?.nativeElement, {
			// @ts-ignore
			navigation: {
				buttonOptions: {
					enabled: false
				}
			},
			chart: {
				backgroundColor: null
			},
			tooltip: {
				pointFormatter() {
					// @ts-ignore
					const value = this.realValue;
					return '<b>'
						+ this.name
						+ ':</b>  '
						+ value;
				}
			},
			series: [{
				type: 'treemap',
				data: this.treeMap,
				events: {
					click(e) {
						console.log(
							e.point.realValue
						);
					}
				},
				dataLabels: {
					enabled: true,
					align: 'center',
					style: {
						textOutline: false,
						color: 'black',
						fontSize: '14px'
					},
					formatter() {
						const name = this.point.name;
						// point = this.value,
						//value = this.value;
						console.log(name);

						// return value && point.parent ? key + ': ' + value : key;
						// @ts-ignore
						return '<div style="color: black; font-size: 30px">' + this.point.realValue
							+ ' </div></br><div style="color: black">' + this.point.name + '</div>';
					}
				}

			}]
			,
			title: {
				text: ''
			},
			credits: {
				enabled: false
			},
		})
	}

	deleteTier2Excel() {
		this.confirmationService.confirm({
			message: 'Are you sure do you want to delete the uploaded Tier 2 Detials?',
			header: 'Close',
			icon: 'pi pi-exclamation-triangle',
			accept: () => {
				this.digitalPpapPackageService.deleteUploadedTier2Detail(this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID.toString())
					.subscribe(resp => {
						console.log('Tier 2 details deleted Successfully');
						this.isTier2DetailsPresent = false;
						this.tier2SavedFlag = false;
						this.loadTier2StratificationHighChart(null);
						this.criticalSubSuppliers = [];
						this.messageService.add({
							severity: 'success',
							summary: 'success',
							detail: 'Deleted Tier 2 Details Successfully.'
						});
					}, error => {
						console.log('Delete Tier 2 Error')
						console.log(error);
						this.messageService.add({
							severity: 'error',
							summary: 'Error',
							detail: 'Unable to Delete Tier 2 Details, Please try to delete after some time.'
						});
					});
			},
			reject: () => {
			}
		});
	}

	Tire2FileAttachment(event, tier2SubSupplierID: number) {
		const reader = new FileReader();
		const file=event.target.files[0];
		console.log(event.target.files[0].name);
		const date = new Date();
		const currentDate = this.datePipe.transform(date, 'MM-dd-yyyy h:mm:ss a');
		const attachmentName = this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID + '_' +
			tier2SubSupplierID + '_' + currentDate + '__' + event.target.files[0].name;
		const formdata: FormData = new FormData();
		formdata.append('file', event.target.files[0]);
		formdata.append('fileName', event.target.files[0]);
		// event.target.files[0].name = attachmentName;
		console.log(event.target.files[0].size / 1024 / 1024 + ' MB');
		console.log('File type :', event.target.files[0].type);
		//Added for US3809059 (Different language check while upload)
		const regexPattern = new RegExp('^[a-zA-Z0-9!@#$%^&*(). -_]+$');
		if (event.target.files[0].size / 1024 / 1024 < 20 && event.target.files[0].type === 'application/pdf') {
			if (regexPattern.test(event.target.files[0].name)) {
				console.log('file is less than 5MB');
				//using same ppapElement file attachment method
				// this.digitalPpapPackageService.putPpapElementResponseFile(formdata, attachmentName).subscribe(res => {
				this.digitalPpapPackageService.getSignedUploadUrl(attachmentName)
					.subscribe(data => {
						console.log(data);
						const formData = new FormData();
						formData.append('file', file);
						this.selectedFile = file;
						this.digitalPpapPackageService.uploadAdhocFile(data, this.selectedFile)
							.subscribe(msg => {
								console.log('Response for tier2Supplier : ' + attachmentName);
								console.log(this.criticalSubSuppliers);
								for (const data of this.criticalSubSuppliers) {
									if (data.tier2SubSupplierID === tier2SubSupplierID) {
										data.document = attachmentName;
									}
								}
								//save critical tire2 details
								let tier2SubSupplier: Tier2SubSupplier;
								for (tier2SubSupplier of this.criticalSubSuppliers) {
									this.criticalSubSuppliersSave.push({
										tier2SubSupplierID: tier2SubSupplier.tier2SubSupplierID,
										ppapPackageIdentifierID: tier2SubSupplier.ppapPackageIdentifierID,
										criticalNonCriticalSupplier: tier2SubSupplier.criticalNonCriticalSupplier,
										tier2SupplierType: tier2SubSupplier.tier2SupplierType,
										subSupplierName: tier2SubSupplier.subSupplierName,
										partDescription: tier2SubSupplier.partDescription,
										subSupplierCountry: tier2SubSupplier.subSupplierCountry,
										subSupplierCity: tier2SubSupplier.subSupplierCity,
										subSupplierZipCode: tier2SubSupplier.subSupplierZipCode,
										contactMailID: tier2SubSupplier.contactMailID,
										contactNumber: tier2SubSupplier.contactNumber,
										document: tier2SubSupplier.document,
										createdBy: tier2SubSupplier.createdBy,
										createdOn: tier2SubSupplier.createdOn,
										lastUpdatedBy: tier2SubSupplier.lastUpdatedBy,
										lastUpdatedOn: tier2SubSupplier.lastUpdatedOn
									});
								}

								this.digitalPpapPackageService.saveTier2Details(this.criticalSubSuppliersSave).subscribe(resp => {
										console.log(' saveTier2Details Save Data: ' + resp);
										this.criticalSubSuppliers = resp;
										this.criticalSubSuppliersSave = [];
										// this.ppapElementRecords = []
										this.messageService.add({
											severity: 'success',
											detail: 'File Uploaded Successfully Completed',
											life: 3000
										});
									}, error => {
										console.log('Error Log ' + error);
									}
								);
								//save critical tire2 details
							}, error => {
								console.log('Error Log for saveTier2Details ' + error);
							});
					});
			} else {
				this.messageService.add({
					severity: 'error',
					summary: 'error',
					detail: 'File Name should be in English.\n' +
						'Please rename and upload again.',
					life: 5000
				});
			}
		} else {
			if (event.target.files[0].type !== 'application/pdf') {
				this.messageService.add({
					severity: 'error',
					detail: 'File Type Should be PDF.',
					life: 5000
				});
			} else {
				this.messageService.add({
					severity: 'error',
					detail: 'File Size Should be less than 20MB.',
					life: 5000
				});
			}
		}

	}

	Tire2RemoveS3Storage(fileName: string, tier2SubSupplierID: any) {
		this.confirmationService.confirm({
			message: 'Are you sure you want to delete the uploaded file?',
			header: 'Delete Confirmation',
			icon: 'fa fa-info-circle',
			accept: () => {
				this.digitalPpapPackageService.deleteFile(fileName).subscribe(res => {
					console.log('Response for Tire2RemoveS3Storage : ' + res);
					if (res.genericString === 'Success') {
						console.log(this.criticalSubSuppliers);
						for (const data of this.criticalSubSuppliers) {
							if (data.tier2SubSupplierID === tier2SubSupplierID) {
								data.document = null;
							}
						}
						// save critical tire2 details
						let tier2SubSupplier: Tier2SubSupplier;
						for (tier2SubSupplier of this.criticalSubSuppliers) {
							this.criticalSubSuppliersSave.push({
								tier2SubSupplierID: tier2SubSupplier.tier2SubSupplierID,
								ppapPackageIdentifierID: tier2SubSupplier.ppapPackageIdentifierID,
								criticalNonCriticalSupplier: tier2SubSupplier.criticalNonCriticalSupplier,
								tier2SupplierType: tier2SubSupplier.tier2SupplierType,
								subSupplierName: tier2SubSupplier.subSupplierName,
								partDescription: tier2SubSupplier.partDescription,
								subSupplierCountry: tier2SubSupplier.subSupplierCountry,
								subSupplierCity: tier2SubSupplier.subSupplierCity,
								subSupplierZipCode: tier2SubSupplier.subSupplierZipCode,
								contactMailID: tier2SubSupplier.contactMailID,
								contactNumber: tier2SubSupplier.contactNumber,
								document: tier2SubSupplier.document,
								createdBy: tier2SubSupplier.createdBy,
								createdOn: tier2SubSupplier.createdOn,
								lastUpdatedBy: tier2SubSupplier.lastUpdatedBy,
								lastUpdatedOn: tier2SubSupplier.lastUpdatedOn
							});
						}

						this.digitalPpapPackageService.saveTier2Details(this.criticalSubSuppliersSave).subscribe(resp => {
								console.log(' saveTier2Details Save Data: ' + resp);
								this.criticalSubSuppliers = resp;
								this.criticalSubSuppliersSave = [];
								// this.ppapElementRecords = []
								this.messageService.add({
									severity: 'success',
									detail: 'File Deleted Successfully Completed',
									life: 3000
								});
							}, error => {
								console.log('Error Log ' + error);
							}
						);
						// save critical tire2 details
					} else if (res === 'Failed') {
						this.messageService.add({severity: 'Error', detail: 'Unable to Delete the File', life: 3000});
					}
				}, error => {
					console.log('Error Response for Tire2RemoveS3Storage : ' + error);
				});
			},
			reject: () => {
			}
		});
	}

	viewPackage() {
		this.digitalPpapPackageService.getViewPackage(this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID).subscribe(res => {
				console.log('Response for ViewPackage: ' + res);
				const byteArray = new Uint8Array(res);
				if (byteArray.length !== 0) {
					const blob = new Blob([byteArray], {type: 'application/pdf'});
					const link = window.document.createElement('a')
					link.href = window.URL.createObjectURL(blob);
					// link.download = document.split('__').pop().trim();
					link.download = 'PPAP Package';
					link.click();
				} else {
					this.messageService.add({
						severity: 'info',
						summary: 'Please attach the documents in required elements',
						life: 3000
					});
				}
			},
			error => {
				console.log('Error Response for ViewPackage : ' + error);
			});
	}

	navigateToSupplierView() {
			if (!this.approverCdsidsta || this.approverCdsidsta.userId === undefined ||
				this.approverCdsidsta.userId === null || this.approverCdsidsta.userId.length === 0) {
				this.messageService.add({
					severity: 'info',
					summary: 'Please fill the Parallel Approver STA CDSID',
					life: 8000
				});
				return;
			}
		if (this.pswApproverAccessControl.get('approverConditionEnabled')) {
			if (this.pswApproverAccessControl.get('staApprovalConditionsEnabled')) {
				for (const data of this.ppapPartsArray) {
					data.ppapStaComments = this.ppapStaComments;
					//data.recallComments = this.recallComments;
					data.recallFlow = this.recallSubmission ? 'Yes' : 'No';
					data.ppapStaStatusBy = sessionStorage.displayLeadName;
					data.ppapStaStatusOn = new Date();
					data.recallDate = new Date();
					/*data.ppapStaStatus = 'APPROVED';
					if (this.pdApprovalRequiredOption === 'true') {
						if (data.ppapPdStatus === 'APPROVED') {
							data.overallPpapStatus = 'APPROVED';
						} else if (data.ppapPdStatus === 'REJECTED') {
							data.overallPpapStatus = 'REJECTED';
						} else if (data.ppapPdStatus === 'NEEDS CLARIFICATION') {
							data.overallPpapStatus = 'NEEDS CLARIFICATION';
						} else if (data.ppapPdStatus === '') {
							if (this.ppapLevelParts !== '1') {
								data.overallPpapStatus = 'AWAITING APPROVAL';
							} else {
								data.overallPpapStatus = 'LEVEL 1 SELF APPROVED';
							}
						}
					}
					this.setOverAllPartStatus();*/
				}
				if ((this.ppapStaComments !== null && this.ppapStaComments.length !== 0) ||
					(this.recallComments !== null && this.recallComments.length !== 0)) {
					this.saveStaPdCommentsOnNext();
				} else {
					this.router.navigate(['/supplierView/' + this.ppapPackageIdentifierId + '/' + this.mode]);
				}
			} else if (this.pswApproverAccessControl.get('pdApprovalConditionsEnabled')) {
				for (const data of this.ppapPartsArray) {
					data.ppapPdComments = this.ppapPdComments;
					//data.ppapPdStatusBy = this.userId;
					data.ppapPdStatusBy = sessionStorage.displayLeadName;
					data.ppapPdStatusOn = new Date();
					/*data.ppapPdStatus = 'APPROVED';
					if (data.ppapStaStatus === 'APPROVED') {
						data.overallPpapStatus = 'APPROVED';
					} else if (data.ppapStaStatus === 'REJECTED') {
						data.overallPpapStatus = 'REJECTED';
					} else if (data.ppapStaStatus === 'NEEDS CLARIFICATION') {
						data.overallPpapStatus = 'NEEDS CLARIFICATION';
					} else if (data.ppapStaStatus === '') {
						if(this.ppapLevelParts !== '1') {
							data.overallPpapStatus = 'AWAITING APPROVAL';
						} else {
							data.overallPpapStatus = 'LEVEL 1 SELF APPROVED';
						}
					}
					this.setOverAllPartStatus();*/
				}
				if (this.ppapPdComments !== null && this.ppapPdComments.length !== 0) {
					this.saveStaPdCommentsOnNext();
				} else {
					this.router.navigate(['/supplierView/' + this.ppapPackageIdentifierId + '/' + this.mode]);
				}
			}
		} else {
			this.router.navigate(['/supplierView/' + this.ppapPackageIdentifierId + '/' + this.mode]);
		}
	}

	recallPage() {
		this.recallSubmission = true;
		if(this.recallSubmission && (this.recallComments === null || this.recallComments === undefined || !(this.recallComments.length > 0))){
			this.messageService.add({
				severity: 'info',
				summary: 'Please enter recall comments',
				life: 4000
			});
		} else {
			this.submitPage();
		}
	}

	submitPage() {
		const messageExists = false;
		// this.imageNotUploaded = false;
		this.cdsidNotSaved = false;
		this.tier2Saved = false;
		console.log('Submitting Digital Ppap for Phase 0');
		// if (this.ppapLevelParts !== '1') {
		this.digitalPpapPackageService.checkIfTier2PPAPPackageSaved(this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID)
			.subscribe(tier2Response => {
				if (tier2Response === 0) {
					this.tier2Saved = true;
				}
			});
		// }
		this.epswHomepageService.retrieveCommodityImage(this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID).subscribe(retrieveResp => {
			if (retrieveResp !== null && retrieveResp['commodityImg'] !== null || undefined) {
				this.commodityImgSaved =false;
			}else{
				this.commodityImgSaved =true;
			}
		});
		this.digitalPpapPackageService.checkIfPdStaCdsidSaved(this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID)
			.subscribe(response => {
				// if (commodityResponse['PpapPackageCommodityImage'].length === 0) {
				// 	this.imageNotUploaded = true;
				// }
				const commodityResponse = response.mapOfStringList;
				if (this.pdApprovalRequiredOption === 'Yes') {
					if (commodityResponse['PpapPackageIdentifierEntity'].length === 0 || commodityResponse['PpapPackageIdentifierEntity'].length === 1) {
						this.cdsidNotSaved = true;
					}
				}else if(this.pdApprovalRequiredOption === ''){
						this.cdsidNotSaved = true;
				}else {
					if (commodityResponse['PpapPackageIdentifierEntity'].length === 0) {
						this.cdsidNotSaved = true;
					}
				}
				console.log('PPAP Level  ' + this.ppapLevelParts);
				// if (this.ppapLevelParts !== '1' && this.chartRequiredElementsFlag !== true) {
				if (this.chartRequiredElementsFlag !== true) {
					this.checkPpapRequiredElement(messageExists);
				} else if (!messageExists) {
					this.savePartsStatus();
				}
			});
	}

	checkPpapRequiredElement(messageExists: boolean) {
		this.requiredElementsNotSaved = false;
		this.digitalPpapPackageService.getPpapElementRequired(this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID).subscribe(repo => {
			console.log('Ppap Required Check Data for Sumbit: ' + repo);
			if (repo.length > 0) {
				this.digitalPpapPackageService.getPackagePpapElementSubmitCheck(this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID)
					.subscribe(repos => {
							console.log('Ppap Required Response Check Data for Submit: ' + repos);
							if (repos.length === 1) {
								messageExists = true;
								const msg = 'Please fill the following PPAP Required Element ' + repos.toString().split(',').join('\r\n');
								this.messageService.add({severity: 'info', summary: msg, life: 5000});
							}
							if (repos.length > 1) {
								messageExists = true;
								const msg = 'Please fill the following PPAP Required Elements ' + repos.toString().split(',').join('\r\n');
								this.messageService.add({severity: 'info', summary: msg, life: 5000});
							}
							if (!messageExists) {
								this.savePartsStatus();
							}
						}, error => {
							console.log('Error Log ' + error);
						}
					);
			} else if (repo.length <= 0) {
				this.requiredElementsNotSaved = true;
				if (!messageExists) {
					this.savePartsStatus();
				}
			}
		}, error => {
			console.log('Error Log ' + error);
		});
	}

	savePartsStatus() {
		let messageExists = false;
		if(this.pdApprovalRequiredOption === undefined || this.pdApprovalRequiredOption === '') {
			messageExists = true;
			this.messageService.add({
				severity: 'info',
				summary: 'Please Select the Priority Supplier as Yes or No',
				life: 8000
			});
		} else {
			if (this.cdsidNotSaved && this.requiredElementsNotSaved) {
				messageExists = true;
				if (this.ppapLevelParts !== '1') {
					this.messageService.add({
						severity: 'info',
						summary: 'Please save the Parallel Approver CDSID and Required Elements in PPAP Package',
						life: 8000
					});
					this.cdsidSavedFlag = false;
					/*this.requiredElementsSavedFlag=false;*/
				} else {
					this.messageService.add({
						severity: 'info',
						summary: 'Please save the Parallel Approver CDSID in PPAP Package',
						life: 8000
					});
					this.cdsidSavedFlag = false;
				}
			} else if (this.cdsidNotSaved && !this.requiredElementsNotSaved) {
				messageExists = true;
				this.messageService.add({
					severity: 'info',
					summary: 'Please save the Parallel Approver CDSID in PPAP Package',
					life: 6000
				});
				this.cdsidSavedFlag = false;
			} else if (!this.cdsidNotSaved && this.requiredElementsNotSaved) {
				messageExists = true;
				// if (this.ppapLevelParts !== '1') {
				this.messageService.add({
					severity: 'info',
					summary: 'Please save the Required Elements in PPAP Package',
					life: 6000
				});
				/*this.requiredElementsSavedFlag=false;*/
				// }

			} else if (this.cdsidNotSaved && this.requiredElementsNotSaved) {
				messageExists = true;
				if (this.ppapLevelParts !== '1') {
					this.messageService.add({
						severity: 'info',
						summary: 'Please save the Parallel Approver CDSID and Required Elements in PPAP Package',
						life: 6000
					});
					this.cdsidSavedFlag = false;
					/*	this.requiredElementsSavedFlag=false;*/
				} else {
					this.messageService.add({
						severity: 'info',
						summary: 'Please save the Parallel Approver CDSID in PPAP Package',
						life: 6000
					});
					this.cdsidSavedFlag = false;
				}

			} else if (this.cdsidNotSaved && !this.requiredElementsNotSaved) {
				messageExists = true;
				this.messageService.add({
					severity: 'info',
					summary: 'Please save the Parallel Approver CDSID in PPAP Package',
					life: 4000
				});
				this.cdsidSavedFlag = false;
			} else if (!this.cdsidNotSaved && this.requiredElementsNotSaved) {
				messageExists = true;
				// if (this.ppapLevelParts !== '1') {
				this.messageService.add({
					severity: 'info',
					summary: 'Please save the Required Elements in PPAP Package',
					life: 4000
				});
				/*this.requiredElementsSavedFlag=false;*/
				// }
			}
			if (!messageExists) {
				if (this.tier2Saved) {
					this.messageService.add({
						severity: 'info',
						summary: 'Please Upload Excel Tier 2 Details Of Parts',
						life: 4000
					});
					this.tier2SavedFlag = false;
				} else {
					if(this.commodityImgSaved){
						this.messageService.add({
							severity: 'info',
							summary: 'Please Upload Commodity Image',
							life: 4000
						});
						this.commodityImgSavedFlag = false;
					} else {
						this.digitalPpapPackageService.findAllPpapAssessmentResponse(this.ppapPackageIdentifierId).subscribe(ppapAssessmentResponse => {
							this.ppapAssessmentResponse = ppapAssessmentResponse;
							if (this.pswAccessControl.get('displayPpapPackageSubmitBtn')) {
								this.confirmationService.confirm({
									message: this.recallSubmission ? 'Once recalled, your package status moves to In-Progress. Are you sure you want to proceed with the recall?' : 'Ensure all required PPAP evidences are uploaded as per Ford Phased PPAP Requirements. Are you sure to submit now?',
									header: this.recallSubmission ? 'Recall Confirmation' : 'Submit Confirmation',
									icon: 'fa fa-info-circle',
									accept: () => {
										for (const data of this.ppapPartsArray) {
											data.ppapStaStatus = '';
											// data.ppapStaComments = '';
											data.ppapStaStatusOn = null;
											data.ppapStaStatusBy = '';
											data.ppapPdStatus = '';
											// data.ppapPdComments = '';
											data.ppapPdStatusOn = null;
											data.ppapPdStatusBy = '';
											if (this.pdStaApprovalRequiredL1) {
												data.staApprovalRequired = 'true';
											} else {
												data.staApprovalRequired = 'false';
											}
											data.overallPpapStatus = '';
											data.submittedBy = sessionStorage.userName;
											data.submittedOn = new Date();
											data.submittedCdsid = this.userId;
											data.submittedEmailId = sessionStorage.userMailId;
											console.log('Recall Submission boolean : ' + this.recallSubmission);
											if ((this.recallSubmission) && (data.status === 'AWAITING APPROVAL' || data.status === 'LEVEL 1 SELF APPROVED')) {
												data.recallComments = this.recallComments;
												data.recallDate = new Date();
												data.recallFlow = 'Yes';
												data.recallBy = sessionStorage.getItem('userId');
												console.log('Status: ' + data.status);
												console.log('Recall Comments: ' + data.recallComments);
												console.log('Recall Date: ' + data.recallDate);
												data.overallPpapStatus = 'IN PROGRESS';
												data.status = 'IN PROGRESS';
											} else {
												if (this.pdApprovalRequiredOption === 'No') {
													if (this.ppapLevelParts === '1') {
														console.log('test1' + this.pdStaApprovalRequiredL1);
														if (this.pdStaApprovalRequiredL1) {
															data.overallPpapStatus = 'AWAITING APPROVAL';
															data.status = 'AWAITING APPROVAL';
														} else {
															data.overallPpapStatus = 'LEVEL 1 SELF APPROVED';
															data.status = 'LEVEL 1 SELF APPROVED';
														}
													} else {
														data.overallPpapStatus = 'AWAITING APPROVAL';
														data.status = 'AWAITING APPROVAL';
													}
												} else {
													data.overallPpapStatus = 'AWAITING APPROVAL';
													data.status = 'AWAITING APPROVAL';
												}
												data.recallFlow = 'No';
											}
										}
										// for (let data of this.ppapAssessmentResponse) {
										// 	data.staPdStatus = null;
										// data.staPdStatusOn = null;
										// data.staPdStatusBy = null;
										// data.staPdRemark = null;
										// }
										if (this.status === 'AWAITING APPROVAL' || this.status === 'IN PROGRESS' || this.status === 'REJECTED' || this.status === 'NEEDS CLARIFICATION') {
											this.isSubmitted = true;
										}
										this.digitalPpapPackageService.savePartsStatus(this.ppapPartsArray, this.isSubmitted, this.pdStaApprovalRequiredL1)
											.subscribe(response => {
												console.log(response);
												this.digitalPpapPackageService.savePpapElementRecords(this.ppapAssessmentResponse).subscribe(resp => {
													this.router.navigate(['/home-page']);
													this.messageService.add({
														severity: 'success',
														summary: this.recallSubmission ? 'Recalled Successfully' : 'Submitted Successfully',
														life: 3000
													});
												});
											});
									},
									reject: () => {
									}
								});
							}
						});
					}
				}
			}
		}
	}

	approvePpapPackage() {
		if (this.pswApproverAccessControl.get('approverConditionEnabled')) {
			if (this.pswApproverAccessControl.get('staApprovalConditionsEnabled')) {
				for (const data of this.ppapPartsArray) {
					data.ppapStaStatus = 'APPROVED';
					//data.ppapStaStatus = this.ppapStaStatus;
					data.ppapStaComments = this.ppapStaComments;
					//data.ppapStaStatusBy = this.userId;
					data.ppapStaStatusBy = sessionStorage.displayLeadName;
					data.ppapStaStatusOn = new Date();
					if (this.pdApprovalRequiredOption === 'Yes') {
						if (data.ppapPdStatus === 'APPROVED') {
							data.overallPpapStatus = 'APPROVED';
						} else if (data.ppapPdStatus === 'REJECTED') {
							data.overallPpapStatus = 'REJECTED';
						} else if (data.ppapPdStatus === 'NEEDS CLARIFICATION') {
							data.overallPpapStatus = 'NEEDS CLARIFICATION';
						} else if (data.ppapPdStatus === '') {
							data.overallPpapStatus = 'AWAITING APPROVAL';
							// if (this.ppapLevelParts !== '1') {
							// 	data.overallPpapStatus = 'AWAITING APPROVAL';
							// } else {
							// 	data.overallPpapStatus = 'LEVEL 1 SELF APPROVED';
							// }
						}
					} else {
						data.overallPpapStatus = data.ppapStaStatus;
					}
					this.setOverAllPartStatus();
				}
				this.saveApprovedStatus();
			} else if (this.pswApproverAccessControl.get('pdApprovalConditionsEnabled')) {
				for (const data of this.ppapPartsArray) {
					data.ppapPdStatus = 'APPROVED';
					data.ppapPdComments = this.ppapPdComments;
					//data.ppapPdStatusBy = this.userId;
					data.ppapPdStatusBy = sessionStorage.displayLeadName;
					data.ppapPdStatusOn = new Date();
					if (data.ppapStaStatus === 'APPROVED') {
						data.overallPpapStatus = 'APPROVED';
					} else if (data.ppapStaStatus === 'REJECTED') {
						data.overallPpapStatus = 'REJECTED';
					} else if (data.ppapStaStatus === 'NEEDS CLARIFICATION') {
						data.overallPpapStatus = 'NEEDS CLARIFICATION';
					} else if (data.ppapStaStatus === '') {
						data.overallPpapStatus = 'AWAITING APPROVAL';
						// if (this.ppapLevelParts !== '1') {
						// 	data.overallPpapStatus = 'AWAITING APPROVAL';
						// } else {
						// 	data.overallPpapStatus = 'LEVEL 1 SELF APPROVED';
						// }
					}
					this.setOverAllPartStatus();
				}
				this.saveApprovedStatus();
			}
		}
	}

	rejectPpapPackage() {
		if (this.pswApproverAccessControl.get('approverConditionEnabled')) {
			if (this.pswApproverAccessControl.get('staApprovalConditionsEnabled')) {
				if (this.ppapStaComments !== undefined && this.ppapStaComments.length !== 0) {
					for (const data of this.ppapPartsArray) {
						data.ppapStaStatus = 'REJECTED';
						data.ppapStaComments = this.ppapStaComments;
						//data.ppapStaStatusBy = this.userId;
						data.ppapStaStatusBy = sessionStorage.displayLeadName;
						data.ppapStaStatusOn = new Date();
						if (this.pdApprovalRequiredOption === 'Yes') {
							if (data.ppapPdStatus === 'APPROVED') {
								data.overallPpapStatus = 'REJECTED';
							} else if (data.ppapPdStatus === 'REJECTED') {
								data.overallPpapStatus = 'REJECTED';
							} else if (data.ppapPdStatus === 'NEEDS CLARIFICATION') {
								data.overallPpapStatus = 'REJECTED';
							} else if (data.ppapPdStatus === '') {
								data.overallPpapStatus = 'REJECTED';
							}
						} else {
							data.overallPpapStatus = data.ppapStaStatus;
						}
						this.setOverAllPartStatus();
					}
					this.saveCommentsForRejection();
				} else {
					this.messageService.add({severity: 'error', summary: 'Please provide STA Comments'});
				}
			} else {
				if (this.ppapPdComments !== undefined && this.ppapPdComments.length !== 0) {
					for (const data of this.ppapPartsArray) {
						data.ppapPdStatus = 'REJECTED';
						data.ppapPdComments = this.ppapPdComments;
						//data.ppapPdStatusBy = this.userId;
						data.ppapPdStatusBy = sessionStorage.displayLeadName;
						data.ppapPdStatusOn = new Date();
						if (data.ppapStaStatus === 'APPROVED') {
							data.overallPpapStatus = 'REJECTED';
						} else if (data.ppapStaStatus === 'REJECTED') {
							data.overallPpapStatus = 'REJECTED';
						} else if (data.ppapStaStatus === 'NEEDS CLARIFICATION') {
							data.overallPpapStatus = 'REJECTED';
						} else if (data.ppapStaStatus === '') {
							data.overallPpapStatus = 'REJECTED';
						}
						this.setOverAllPartStatus();
					}
					this.saveCommentsForRejection();
				} else {
					this.messageService.add({severity: 'error', summary: 'Please provide PD Comments'});
				}
			}
		}
	}

	needsClarification() {
		if (this.pswApproverAccessControl.get('approverConditionEnabled')) {
			if (this.pswApproverAccessControl.get('staApprovalConditionsEnabled')) {
				if (this.ppapStaComments !== undefined && this.ppapStaComments.length !== 0) {
					for (const data of this.ppapPartsArray) {
						data.ppapStaStatus = 'NEEDS CLARIFICATION';
						data.ppapStaComments = this.ppapStaComments;
						//data.ppapStaStatusBy = this.userId;
						data.ppapStaStatusBy = sessionStorage.displayLeadName;
						data.ppapStaStatusOn = new Date();
						if (this.pdApprovalRequiredOption === 'Yes') {
							if (data.ppapPdStatus === 'APPROVED') {
								data.overallPpapStatus = 'NEEDS CLARIFICATION';
							} else if (data.ppapPdStatus === 'REJECTED') {
								data.overallPpapStatus = 'REJECTED';
							} else if (data.ppapPdStatus === 'NEEDS CLARIFICATION') {
								data.overallPpapStatus = 'NEEDS CLARIFICATION';
							} else if (data.ppapPdStatus === '') {
								data.overallPpapStatus = 'NEEDS CLARIFICATION';
							}
						} else {
							data.overallPpapStatus = data.ppapStaStatus;
						}
						this.setOverAllPartStatus();
					}
					this.SaveCommentsForClarification();
				} else {
					this.messageService.add({severity: 'error', summary: 'Please provide STA Comments'});
				}
			} else {
				if (this.ppapPdComments !== undefined && this.ppapPdComments.length !== 0) {
					for (const data of this.ppapPartsArray) {
						data.ppapPdStatus = 'NEEDS CLARIFICATION';
						data.ppapPdComments = this.ppapPdComments;
						//data.ppapPdStatusBy = this.userId;
						data.ppapPdStatusBy = sessionStorage.displayLeadName;
						data.ppapPdStatusOn = new Date();
						if (data.ppapStaStatus === 'APPROVED') {
							data.overallPpapStatus = 'NEEDS CLARIFICATION';
						} else if (data.ppapStaStatus === 'REJECTED') {
							data.overallPpapStatus = 'REJECTED';
						} else if (data.ppapStaStatus === 'NEEDS CLARIFICATION') {
							data.overallPpapStatus = 'NEEDS CLARIFICATION';
						} else if (data.ppapStaStatus === '') {
							data.overallPpapStatus = 'NEEDS CLARIFICATION';
						}
						this.setOverAllPartStatus();
					}
					this.SaveCommentsForClarification();
				} else {
					this.messageService.add({severity: 'error', summary: 'Please provide PD Comments'});
				}
			}
		}
	}

	setOverAllPartStatus() {
		if (!this.restrictApproverAccess) {
			for (const data of this.ppapPartsArray) {
				if (data.overallPpapStatus === 'AWAITING APPROVAL' || data.overallPpapStatus === 'LEVEL 1 SELF APPROVED') {
					data.status = 'AWAITING APPROVAL';
					// if (this.ppapLevelParts !== '1') {
					// 	data.status = 'AWAITING APPROVAL';
					// } else {
					// 	data.status = 'LEVEL 1 SELF APPROVED';
					// }
				} else if (data.overallPpapStatus === 'APPROVED') {
					data.status = 'APPROVED';
				} else if (data.overallPpapStatus === 'REJECTED') {
					data.status = 'REJECTED';
				} else if (data.overallPpapStatus === 'NEEDS CLARIFICATION') {
					data.status = 'NEEDS CLARIFICATION';
				}
			}
		}
	}

	private saveCommentsForRejection() {
		this.digitalPpapPackageService.rejectPpapPackage(this.ppapPartsArray).subscribe(response => {
			console.log(response);
			this.messageService.add({severity: 'success', summary: 'PPAP Package Rejected Successfully'});
			this.router.navigate(['/home-page']);
		});
	}

	private saveApprovedStatus() {
		// this.digitalPpapPackageService.approvePpapPackage(this.ppapPartsArray).subscribe(response => {
		// 	console.log(response);
			// this.messageService.add({severity: 'success', summary: 'PPAP Package Approved Successfully'});
			if (!this.restrictApproverAccess) {
				//Added for US3652011
				this.confirmationService.confirm({
					message: 'Ensure all required PPAP evidences uploaded by Supplier are reviewed and met Ford Phased PPAP Requirements. Are you sure to approve now?',
					header: 'Approve Confirmation',
					icon: 'pi pi-info-circle',
					accept: () => {
						// sessionStorage.setItem('approveMessage', JSON.stringify({
						// 	status: 'success',
						// 	message: 'PPAP Package Approved Successfully'
						// }));
						/*for (const data of this.ppapPartsArray) {
							data.ppapStaStatus = 'APPROVED';
							data.status = 'APPROVED';
							data.overallPpapStatus = 'APPROVED';
							console.log('enter for loop',data.ppapStaStatus);
						}*/
						this.digitalPpapPackageService.approvePpapPackage(this.ppapPartsArray).subscribe(response => {
							console.log(response);
						});
						this.messageService.add({severity: 'success', summary: 'PPAP Package Approved Successfully'});
						//this.messageService.add({severity: 'success', summary: 'PPAP Package Approved Successfully'});
						this.router.navigate(['/home-page']);
					},
					reject: () => {
						this.enableOnViewMode = true;
						// for (const data of this.ppapPartsArray) {
						// 	data.ppapStaStatus = 'LEVEL 1 SELF APPROVED';
						// 	data.status = 'LEVEL 1 SELF APPROVED';
						// 	data.overallPpapStatus = 'LEVEL 1 SELF APPROVED';
						// 	console.log('enter for loop',data.ppapStaStatus);
						// }
						/*this.digitalPpapPackageService.approvePpapPackage(this.ppapPartsArray).subscribe(response => {
							console.log('Reject scenario: ',response);
						});*/
						this.router.navigate(['/home-page']);

					}
				});
				//Ended for US3652011
			} else {
				this.router.navigate(['/supplierView/' + this.ppapPackageIdentifierId + '/' + this.mode]);
			}
	}

	private SaveCommentsForClarification() {
		this.digitalPpapPackageService.needsClarification(this.ppapPartsArray).subscribe(response => {
			console.log(response);
			this.router.navigate(['/home-page']);
		});
	}

	getSupplierName() {
		this.epswHomepageService.getSupplierName(this.ppapPackageIdentifierDisplay.siteCode).subscribe(async respOne => {
			await this.getCdsId();
			this.getSupplierNameData(respOne);
		});
	}

	onRowEditCancel() {
		this.isLoading = true;
		this.partNumbers = undefined;
		this.partPappLevel = undefined;
		this.partDisableEnable = true;
		this.getAllDataForParts();
		this.getPrefixBaseSuffixForParts();
		this.ppapPartsArrayLength = this.ppapPartsArray.length;
		(document.getElementById('addRow') as HTMLInputElement).disabled = false;
		this.isLoading = false;
	}

	newRowCheck() {
		if (this.partNumbers !== undefined && this.partPappLevel !== undefined) {
			this.partDisableEnable = false;
		}
		if (this.partNumbersOption.length < 1) {
			this.messageService.add({
				severity: 'info',
				summary: 'Info',
				detail: 'No Parts available to add for the selected PPAP phase.'
			});
			this.getAllDataForParts();
			this.getPrefixBaseSuffixForParts();
		}
	}

	sectionNCValidation() {
		this.digitalPpapPackageService.sectionNCValidation(this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID).subscribe(response => {
			console.log(response);
			this.sectionNC = response;
		});
	}

	adhocDocument(event) {
		if (event.target.files && event.target.files[0]) {
			const file=event.target.files[0];
			const reader = new FileReader();
			console.log(event.target.files[0].name);
			const date = new Date();
			const currentDate = this.datePipe.transform(date, 'MM-dd-yyyy h:mm:ss a');
			const attachmentName = this.ppapPackageIdentifierDisplay.ppapPackageIdentifierID + '_'
				+ currentDate + '__' + event.target.files[0].name;
			const formdata: FormData = new FormData();
			formdata.append('file', event.target.files[0]);
			formdata.append('fileName', event.target.files[0]);
			// event.target.files[0].name = attachmentName;
			console.log(event.target.files[0].size / 1024 / 1024 + ' MB');
			console.log('File type :', event.target.files[0].type);
			//Added for US3809059 (Different language check while upload)
			const regexPattern = new RegExp('^[a-zA-Z0-9!@#$%^&*(). -_]+$');
			// if (event.target.files[0].size / 1024 / 1024 < 40 && event.target.files[0].type === 'application/pdf') {
			if (event.target.files[0].size / 1024 / 1024 < 40) {
				if (regexPattern.test(event.target.files[0].name)) {
					console.log('file is less than 20MB');
					this.digitalPpapPackageService.getSignedUploadUrl(attachmentName)
						.subscribe(data => {
							// this.signedUrl=data.signedUrl;
							console.log(data);
							const formData = new FormData();
							formData.append('file', file);
							this.selectedFile=file;
							this.digitalPpapPackageService.uploadAdhocFile(data,this.selectedFile)
								.subscribe(msg=>{
									this.ppapPackageIdentifierDisplay.adhocDocument = attachmentName;
									this.ppapPackageIdentifierStore.addOrUpdateParts=false;
									this.digitalPpapPackageService.saveData(this.ppapPackageIdentifierDisplay).subscribe(resp => {
										this.ppapPackageIdentifierDisplay = resp;
										this.messageService.add({
											severity: 'success',
											detail: 'Adhoc Attachment Saved Successfully'
										});
									});

								}, error => {
									console.log('Error Log for Adhoc Document ' + error);
								});
						});
						} else {
						this.messageService.add({
							severity: 'error',
							summary: 'error',
							detail: 'File Name should be in English.\n' +
								'Please rename and upload again.',
							life: 5000
						});
					}
				} else {
					this.messageService.add({
						severity: 'error',
						summary: 'error',
						detail: 'File Size Should be less than 40MB.',
						life: 5000
					});
				}
			}
		}

		removeAdhocDocument(adhocDocument: string) {
			this.confirmationService.confirm({
				message: 'Are you sure you want to delete the uploaded file?',
				header: 'Delete Confirmation',
				icon: 'fa fa-info-circle',
				accept: () => {
					this.digitalPpapPackageService.deleteFile(adhocDocument).subscribe(res1 => {
						const res =res1.genericString;
						console.log('Response for fileDelete : ' + res);
						if (res === 'Success') {
							this.ppapPackageIdentifierDisplay.adhocDocument = null;
							this.ppapPackageIdentifierStore.addOrUpdateParts=false;
							this.digitalPpapPackageService.saveData(this.ppapPackageIdentifierDisplay).subscribe(resp => {
								this.ppapPackageIdentifierDisplay = resp;
								this.messageService.add({
									severity: 'success',
									detail: 'Adhoc Attachment Deleted Successfully Completed'
								});
							});
						} else if (res === 'Failed') {
							this.messageService.add({severity: 'Error', detail: 'Unable to Delete the File', life: 3000});
						}
					}, error => {
						console.log('Error Response for fileDelete : ' + error);
					});
				},
				reject: () => {
				}
			});
		}

		saveStaPdCommentsOnNext() {
			this.digitalPpapPackageService.saveStaPdCommentsOnNext(this.ppapPartsArray).subscribe(response => {
				console.log(response);
				this.router.navigate(['/supplierView/' + this.ppapPackageIdentifierId + '/' + this.mode]);
			});
		}

		rowEnableDisable() {
			// if (this.partNumbers !== undefined && this.partPappLevel !== undefined) {
			// 	this.partDisableEnable = false;
			// }
			if (this.partNumbers !== undefined) {
				this.partDisableEnable = false;
			}
		}

	rowEnableDisablePpapLevel(noPartPrefix: any) {
		if ((this.partNumbers !== undefined && this.partPappLevel !== undefined) ||
			(noPartPrefix !== undefined && noPartPrefix !== '' && noPartPrefix !== null && this.partPappLevel !== undefined)) {
			this.partDisableEnable = false;
		}
	}

	addRowDisable() {
		(document.getElementById('addRow') as HTMLInputElement).disabled = true;
	}

	showSubmittedCopyData() {
		this.display = true;
		this.isCopyButtonVisible = false;
		this.programCodeOption = [];
		this.partNumbersOption = [];
		this.copyDataList.digitalPpapValues = '';
		this.copyDataList.elementCheckboxValues = '';
		this.selectedCheckboxValues = [];
		this.digitalPpapPackageService.getPartsIdentifierData(this.ppapPackageIdentifierDisplay.siteCode,
			this.ppapPackageIdentifierDisplay.ppapPhase).subscribe(resp => {
			this.packageIdentifierId = resp;
			const programCodeValue = [];
			for (const data of this.packageIdentifierId) {
				this.digitalPpapPackageService.getProgramCodeCopyData(data).subscribe(
					value => {
						this.dataValue = value.mapOfStringList['programCodeList'];
						for (const dataOne of this.dataValue) {
							if (this.dataValue !== undefined && this.dataValue.length !== 0
								&& (!programCodeValue.includes(dataOne))) {
								this.programCodeOption.push({
									label: dataOne,
									value: dataOne
								});
								programCodeValue.push(dataOne);
							}
						}
					});
			}
		});
	}

	getSectionNameCopyData(copyFromProgramCode, copyFromPartNumbers) {
		this.selectedCheckboxValues = [];
		this.copyDataList.elementCheckboxValues = '';
		this.digitalPpapPackageService.getPartsIdentifierData(this.ppapPackageIdentifierDisplay.siteCode,
			this.ppapPackageIdentifierDisplay.ppapPhase).subscribe(resp => {
			this.packageIdentifierId = resp;
			for (const dataOne of this.packageIdentifierId) {
				this.digitalPpapPackageService.getRequiredElementsCopyData(dataOne, copyFromProgramCode, copyFromPartNumbers).subscribe(
					value => {
						this.sectionValues = value.mapOfStringList['sectionNameValues']
						if (this.sectionValues !== undefined && this.sectionValues.length !== 0) {
							let i;
							for (i = 0; i < this.sectionValues.length; i++) {
								this.selectedCheckboxValues.push(this.sectionValues[i]);
							}
						}
					});
			}
		});
	}

	copyData(elementCheckboxValues, digitalPpapValues, copyFromProgramCode, copyFromPartNumbers) {
		this.dataCheckList = [];
		if (digitalPpapValues !== null && digitalPpapValues !== '' &&
			digitalPpapValues !== undefined) {
			this.dataCheckList.push(digitalPpapValues);
		} else {
			this.digitalPpapValueEmpty = [];
			this.dataCheckList.push(this.digitalPpapValueEmpty);
		}
		if (elementCheckboxValues !== null && elementCheckboxValues !== '' &&
			elementCheckboxValues !== undefined) {
			this.dataCheckList.push(elementCheckboxValues);
		} else {
			this.elementValues = [];
			this.dataCheckList.push(this.elementValues);
		}
		console.log('Elements checkbox', this.dataCheckList);
		const bucketName = '51942-pcf-ns-bd7fa697-24ca03-bucket01';
		this.digitalPpapPackageService.getPartsIdentifierData(this.ppapPackageIdentifierDisplay.siteCode,
			this.ppapPackageIdentifierDisplay.ppapPhase).subscribe(respOne => {
			this.packageIdentifierId = respOne;
			for (const dataOne of this.packageIdentifierId) {
				this.digitalPpapPackageService.copyData(dataOne, this.ppapPackageIdentifier, this.dataCheckList,
					copyFromPartNumbers, copyFromProgramCode, bucketName).subscribe(
					resp => {
						//this.ppapPackageIdentifierCopyData = resp;
						//this.ppapPackageIdentifierDisplay.pdApproverCdsid = this.ppapPackageIdentifierCopyData
						this.display = false;
						this.router.routeReuseStrategy.shouldReuseRoute = () => false;
						this.router.onSameUrlNavigation = 'reload';
						this.router.navigate(['./'], {relativeTo: this.route, queryParams: {mode: 'create'}});
					});
			}
			this.messageService.add({severity: 'success', summary: 'Copied Successfully', life: 3000});
		});
	}

	enableIncludeAttachment(elementCheckboxValues, digitalPpapValues) {
		if (elementCheckboxValues !== null || elementCheckboxValues !== undefined ||
			digitalPpapValues !== null || digitalPpapValues !== undefined) {
			if (elementCheckboxValues.length !== 0 || digitalPpapValues.length !== 0) {
				this.isCopyButtonVisible = true;
			} else {
				this.isCopyButtonVisible = false;
			}
			if (elementCheckboxValues.length !== 0) {
				this.isAttachVisible = true;
			} else {
				this.isAttachVisible = false;
			}
		}
	}

	enableCopyButton(digitalPpapValues: string) {
		if (digitalPpapValues.length !== 0) {
			this.isCopyButtonVisible = true;
		} else {
			this.isCopyButtonVisible = false;
		}
	}

	getPartNumberListData(programCode) {
		this.isCopyButtonVisible = false;
		this.isAttachVisible = false;
		this.getPartNumbersList(programCode);
	}

	private getPartNumbersList(programCode) {
		this.partNumbersOption = [];
		this.digitalPpapPackageService.getPartsIdentifierData(this.ppapPackageIdentifierDisplay.siteCode,
			this.ppapPackageIdentifierDisplay.ppapPhase).subscribe(resp => {
			this.packageIdentifierId = resp;
			for (const dataOne of this.packageIdentifierId) {
				this.digitalPpapPackageService.getPartNumbersList(dataOne, programCode).subscribe(
					value => {
						this.dataValue = value.mapOfStringList['prefixBaseSuffix'];
						for (const dataTwo of this.dataValue) {
							if (this.dataValue !== undefined && this.dataValue.length !== 0) {
								this.partNumbersOption.push({
									label: dataTwo[0] + '-' + dataTwo[1] + '-' +
										dataTwo[2],
									value: dataTwo[0] + '-' + dataTwo[1] + '-' +
										dataTwo[2]
								});
							}
						}
						//this.partNumbers = [];
					});
			}
		});
	}

	/**
	 * @param respOne
	 * @private
	 * If the overall status is in 'PENDING' / 'IN PROGRESS' / 'REJECTED' / 'NEEDS CLARIFICATION' / 'INTERIM ACCEPTED'
	 * only we should fetch user details from the LDAP.
	 */
	private getSupplierNameData(respOne) {
		this.supplier = respOne.simsahSelectedSiteName;
		this.approverId = respOne.simsahProdStaEngr;
		if ((['PENDING', 'IN PROGRESS', 'REJECTED', 'NEEDS CLARIFICATION', 'INTERIM ACCEPTED', 'AWAITING APPROVAL'].indexOf(this.overallStatus) === -1)&&
			(['PENDING', 'IN PROGRESS', 'REJECTED', 'NEEDS CLARIFICATION', 'INTERIM ACCEPTED', 'AWAITING APPROVAL'].indexOf(this.status) === -1)) {
			return;
		}
		if (this.approverCdsidsta.userId === undefined || this.approverCdsidsta.userId === null || this.approverCdsidsta.userId.length === 0) {
			if (this.approverId !== null && this.approverId !== undefined) {
				this.approverCdsidsta = {userId: this.approverId};
				this.fetchAndUpdateCDSIDInfo(this.approverCdsidsta.userId);
			}
		} else {
			this.fetchAndUpdateCDSIDInfo(this.approverCdsidsta.userId);
		}
	}

	private async getCdsId() {
		if (this.pdApprovalRequiredOption === 'Yes') {
			if (this.ppapPackageIdentifierDisplay.pdApproverCdsid !== null) {
				if (this.ppapPackageIdentifierDisplay.pdApproverCdsid !== null &&
					this.ppapPackageIdentifierDisplay.pdApproverCdsid.length > 0) {
					this.approverCdsid = {userId: this.ppapPackageIdentifierDisplay.pdApproverCdsid};
					this.approverCdsid.mail = this.ppapPackageIdentifierDisplay.pdApproverEmailId;
					this.approverCdsid.fordDisplayName = this.ppapPackageIdentifierDisplay.pdApproverName;
				}
				if (this.ppapPackageIdentifierDisplay.staApproverCdsid !== null &&
					this.ppapPackageIdentifierDisplay.staApproverCdsid.length > 0) {
					this.approverCdsidsta = {userId: this.ppapPackageIdentifierDisplay.staApproverCdsid};
					this.approverCdsidsta.mail = this.ppapPackageIdentifierDisplay.staApproverEmailId;
					this.approverCdsidsta.fordDisplayName = this.ppapPackageIdentifierDisplay.staApproverName;
				}
			}
		} else {
			if (this.ppapPackageIdentifierDisplay.staApproverCdsid !== null &&
				this.ppapPackageIdentifierDisplay.staApproverCdsid.length > 0) {
				this.approverCdsidsta = {userId: this.ppapPackageIdentifierDisplay.staApproverCdsid};
				this.approverCdsidsta.mail = this.ppapPackageIdentifierDisplay.staApproverEmailId;
				this.approverCdsidsta.fordDisplayName = this.ppapPackageIdentifierDisplay.staApproverName;
			}
		}
	}

	numberFormat(questionSortSequence: any) {
		if (questionSortSequence.toString().split('.')[1] !== undefined) {
			if (questionSortSequence.toString().split('.')[1] === '1') {
				return questionSortSequence.toString().split('.')[0] + '.10';
			} else {
				return questionSortSequence.toString().split('.')[0] + '.' + questionSortSequence.toString().split('.')[1].replace(/^0+/, '');
			}
		} else {
			return questionSortSequence;
		}
	}

	//Alert message - US3738472
	isRecentChange(data): boolean {
		const b = data.noPartPrefix + '-' + data.noPartBase + '-' + data.noPartSuffix;
		if (this.newPartList.includes(b)) {
			return true;
		} else {
			return false;
		}
	}

	getFileName(documentName) {
		this.fileName = '';
		documentName = documentName.split(',');
		for (let data = 0; data < documentName.length; data++) {
			if (documentName.length > 1 && data > 0) {
				this.fileName = this.fileName.concat(',');
			}
			this.fileName = this.fileName.concat(documentName[data].split('__').pop());
		}
		let rowHeights: any[] = [], rowIteration, maxHeight;
		for (rowIteration = 0; rowIteration < this.ppapElementRecords.length; rowIteration++) {
			//Since height vary due to question size, getting maximum row height from frozen rows onorder to set it equally to all rows
			if (document.getElementsByClassName('ui-table-scrollable-view ui-table-unfrozen-view')[0].getElementsByClassName('ui-table-scrollable-body')[0].getElementsByTagName('tr')[rowIteration] !== undefined) {
				rowHeights.push(document.getElementsByClassName('ui-table-scrollable-view ui-table-unfrozen-view')[0].getElementsByClassName('ui-table-scrollable-body')[0].getElementsByTagName('tr')[rowIteration].clientHeight);
			}
		}
		maxHeight = Math.max(...rowHeights);
		for (rowIteration = 0; rowIteration < this.ppapElementRecords.length; rowIteration++) {
			if (document.getElementsByClassName('ui-table-scrollable-view ui-table-unfrozen-view')[0].getElementsByClassName('ui-table-scrollable-body')[0].getElementsByTagName('tr')[rowIteration] !== undefined) {
				//setting row height equally for both frozen and unfrozen div's
				if (document.getElementsByClassName('ui-table')[0].getElementsByClassName('ui-table-unfrozen-view')[0].getElementsByClassName('ui-table-tbody')[1].getElementsByTagName('tr')[rowIteration].clientHeight !==
					document.getElementsByClassName('ui-table')[0].getElementsByClassName('ui-table-frozen-view')[0].getElementsByClassName('ui-table-tbody')[1].getElementsByTagName('tr')[rowIteration].clientHeight) {
					document.getElementsByClassName('ui-table')[0].getElementsByClassName('ui-table-unfrozen-view')[0].getElementsByClassName('ui-table-tbody')[1].getElementsByTagName('tr')[rowIteration].setAttribute('height', maxHeight);
					document.getElementsByClassName('ui-table')[0].getElementsByClassName('ui-table-frozen-view')[0].getElementsByClassName('ui-table-tbody')[1].getElementsByTagName('tr')[rowIteration].setAttribute('height', maxHeight);
				}
			}
		}
		return this.fileName;
	}

	countDocu(documentName) {
		if (documentName !== null) {
			return documentName.split(',').length;
		} else {
			return 0;
		}
	}

	enablePaperClip(documentName) {
		if (documentName !== null) {
			if (documentName.split(',').length < 5) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}

	setRowHeightAfterUpload() {
		let rowHeights: any[] = [], rowIteration, maxHeight;
		for (rowIteration = 0; rowIteration < this.ppapElementRecords.length; rowIteration++) {
			//Since height vary due to question size, getting maximum row height from frozen rows onorder to set it equally to all rows
			if (document.getElementsByClassName('ui-table-scrollable-view ui-table-unfrozen-view')[0].getElementsByClassName('ui-table-scrollable-body')[0].getElementsByTagName('tr')[rowIteration] !== undefined) {
				rowHeights.push(document.getElementsByClassName('ui-table-scrollable-view ui-table-unfrozen-view')[0].getElementsByClassName('ui-table-scrollable-body')[0].getElementsByTagName('tr')[rowIteration].clientHeight);
			}
		}
		maxHeight = Math.max(...rowHeights);
		for (rowIteration = 0; rowIteration < this.ppapElementRecords.length; rowIteration++) {
			if (document.getElementsByClassName('ui-table-scrollable-view ui-table-unfrozen-view')[0].getElementsByClassName('ui-table-scrollable-body')[0].getElementsByTagName('tr')[rowIteration] !== undefined) {
				//setting row height equally for both frozen and unfrozen div's
				if (document.getElementsByClassName('ui-table')[0].getElementsByClassName('ui-table-unfrozen-view')[0].getElementsByClassName('ui-table-tbody')[1].getElementsByTagName('tr')[rowIteration].clientHeight !==
					document.getElementsByClassName('ui-table')[0].getElementsByClassName('ui-table-frozen-view')[0].getElementsByClassName('ui-table-tbody')[1].getElementsByTagName('tr')[rowIteration].clientHeight) {
					document.getElementsByClassName('ui-table')[0].getElementsByClassName('ui-table-unfrozen-view')[0].getElementsByClassName('ui-table-tbody')[1].getElementsByTagName('tr')[rowIteration].setAttribute('height', maxHeight);
					document.getElementsByClassName('ui-table')[0].getElementsByClassName('ui-table-frozen-view')[0].getElementsByClassName('ui-table-tbody')[1].getElementsByTagName('tr')[rowIteration].setAttribute('height', maxHeight);
				}
			}
		}
	}

	downloadZippedPackageFile() {
		this.digitalPpapPackageService.getSignedDownloadUrlsForZip(this.ppapPackageIdentifier).subscribe(res => {
			const zip = new jszip();
			const filePromises = [];
			const response: any[] = res.downloadList;
			for (let i = 0; i < response.length; i++) {
				filePromises.push(
					this.http.get(response[i].signedUrl, {responseType: 'blob'}).toPromise()
						.then((fileArrayBuffer) => {
							const fileBlob = new Blob([fileArrayBuffer], {type: fileArrayBuffer.type});
							zip.file(response[i].fileNama, fileBlob);
							if (i === response.length - 1) {
								Promise.all(filePromises).then(() => {
									zip.generateAsync({type: 'blob'})
										.then((zipBlob) => {
											saveAs(zipBlob, 'PPAP Package.zip');
										});
								});
							}
						})
				);

			}
		}, error => {
			console.log('Error Response for fileDownload : ' + error);
		});
	}

	getCofirmationMsg(value: string): string {
		const confirmationMsg: string = (value === 'Yes') ? 'Selecting \'Yes\' confirms the part/parts are designated as Non-Priority in eAPQP schedule A and you have not followed the same in ePSW?' :
			'Selecting \'No\' confirms the part/parts are designated as priority in eAPQP schedule A and you have not followed the same in ePSW?';
		return confirmationMsg;
	}

	getRunningConfirmationMsg(value: string): string {
		const runningConfirmationMsg: string = (value === 'Yes') ? 'Selecting \'Yes\' confirms the part/parts are designated as Priority based on your discussion/alignment with Ford PD and STA?' :
			'Selecting \'No\' confirms the part/parts are designated as Non-Priority based on your discussion/alignment with Ford PD and STA?';
		return runningConfirmationMsg;
	}

	showPopUp(popUpMessage) {
		this.confirmationService.confirm({
			message: popUpMessage,
			header: 'Confirmation',
			icon: 'pi pi-info-circle',
			acceptLabel: 'OK',
			rejectVisible: false,
			accept: () => {
				this.msgs = [];
				this.onSelectedPriorityValue();
			},
			reject: () => {
				this.pdApprovalRequiredOption = (this.selectedPriorityValue === false) ? 'No' : (this.selectedPriorityValue === true) ? 'Yes' : 'No';
				this.onSelectedPriorityValue();
			}
		});
	}

	showConfirmationDialogPriority(value: string) {
		// if (this.enableOnViewMode) {
		if(this.mode !== 'view' || !this.pswSupplier.get('isSupplier')) {
			if (this.runningChangePartsStatus) {
				this.showPopUp(this.getRunningConfirmationMsg(value));
			} else if (!this.runningChangePartsStatus && this.apqpGlobalPriority !== 'null' && value !== this.apqpGlobalPriority) {
				this.showPopUp(this.getCofirmationMsg(value));
			} else if (!this.runningChangePartsStatus && this.apqpGlobalPriority === 'null') {
				this.showPopUp(this.getRunningConfirmationMsg(value));
			}
		}
		// } else {
		// 	this.pdApprovalRequiredOption = (this.selectedPriorityValue===false)?'No':(this.selectedPriorityValue===true)?'Yes':'No';
		// 	this.onSelectedPriorityValue();
		// }
	}

	onSelectedPriorityValue(){
		console.log(this.pdApprovalRequiredOption);
		console.log(this.approverCdsid);
		if(this.pdApprovalRequiredOption === 'Yes'){
			this.selectedPriorityValue = true;
		}else if(this.pdApprovalRequiredOption === 'No'){
			this.selectedPriorityValue = false;
		}
	}

	private showSelectedPriorityInvalidErrorMsg() {
		this.messageService.add({
			key: 'ppapPackageToast', severity: 'error', summary: 'Error',
			detail: 'Please Select Priority Supplier as Yes or No', life: 5000
		});
		return false;
	}

	disableOk() {
		this.displayChangedPriorityParts = false;
	}

	test() {
		//this.approverCdsidsta = {...this.approverCdsidsta,userId:this.approverCdsidsta.fordDisplayName}
		//this.approverCdsidsta = {...this.approverCdsidsta,userId:this.approverCdsidsta.mail?.split('@')[0].trim().toLocaleUpperCase()}
		this.approverCdsidsta = {...this.approverCdsidsta,userId:this.approverCdsidsta.userId?.split('-')[0].trim().toLocaleUpperCase()}
	}

	testForPd() {
		//this.approverCdsidsta = {...this.approverCdsidsta,userId:this.approverCdsidsta.fordDisplayName}

		//this.approverCdsid = {...this.approverCdsid,userId:this.approverCdsid.mail?.split('@')[0].trim().toLocaleUpperCase()}
		this.approverCdsid = {...this.approverCdsid,userId:this.approverCdsid.userId?.split('-')[0].trim().toLocaleUpperCase()}
	}
}
